import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getProductsGlobales } from "../../actions/products";

const ProductSearchBar = ({
  getProductsGlobales,
  clearSignal,
  shouldRedirect,
}) => {
  const [product, setProduct] = useState("");

  const onChange = (e) => {
    setProduct(e.target.value);
  };

  const onSubmit = () => {
    clearSignal(true);
    clearSignal(false);
    getProductsGlobales(product);
    shouldRedirect(true);
  };

  return (
    <div>
      <form
        onSubmit={(e) => e.preventDefault()}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <input
          type="text"
          name="product"
          value={product}
          placeholder="¿Qué estas buscando?"
          onChange={(e) => onChange(e)}
          style={{ backgroundColor: "white" }}
        />
        <span
          onClick={() => onSubmit()}
          style={{
            marginLeft: "10px",
            backgroundColor: "#17d070",
            borderRadius: "5px",
          }}
        >
          <i
            className="fas fa-search fa-lg"
            style={{ padding: "1rem", color: "white" }}
          />
        </span>
      </form>
    </div>
  );
};

ProductSearchBar.propTypes = {
  getProductsGlobales: PropTypes.func.isRequired,
};

export default connect(null, { getProductsGlobales })(ProductSearchBar);

import axios from "axios";
import { setAlert } from "./alert";

export const get_informe_rider =
  ({ riderId, startDate, endDate }) =>
  async (dispatch) => {
    try {
      const body = {
        startDate: startDate,
        endDate: endDate,
      };
      const res = await axios.post(
        `/api/admin/get_informe_rider/${riderId}`,
        body
      );

      return res.data;
    } catch (err) {
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      }
    }
  };

export const getDeliveryAvailability = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/pedidos/delivery_availability");
    return res.data;
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

import React, { useState, useEffect } from "react";
import shopPlaceholder from "../../assets/shop.png";
import { estaAbierto } from "../../utils/auxFunctions";
import { getPromedioAceptacion } from "../../actions/locales";

import barPortada from "../../assets/barPortada.png";
import bar2 from "../../assets/bar2.jpg";
import bar3 from "../../assets/bar3.jpg";
import superPortada from "../../assets/superPortada.jpg";
import super2 from "../../assets/super2.png";
import super3 from "../../assets/super3.png";
import kioskoPortada from "../../assets/kioskoPortada.jpg";
import kiosko2 from "../../assets/kiosko2.jpg";
import kiosko3 from "../../assets/kiosko3.jpg";
import heladeriaPortada from "../../assets/heladeriaPortada.jpg";
import heladeria2 from "../../assets/heladeria2.jpg";
import heladeria3 from "../../assets/heladeria3.jpg";
import panaderiaPortada from "../../assets/panaderiaPortada.jpg";
import panaderia2 from "../../assets/panaderia2.jpg";
import panaderia3 from "../../assets/panaderia3.webp";
import carniceriaPortada from "../../assets/carniceriaPortada.jpg";
import verduleriaPortada from "../../assets/verduleriaPortada.jpg";
import farmaciaPortada from "../../assets/farmaciaPortada.jpg";

const NegocioCard = ({ local, deliveryAvailable, i, history }) => {
  const [minutes, setMinutes] = useState(0);
  useEffect(() => {
    const fetch = async () => {
      const a = await getPromedioAceptacion(local._id);
      if (a.ok) {
        setMinutes(a.minutes);
      }
    };
    fetch();
  }, []);
  console.log(minutes);

  const [superPortadas, setSuperPortadas] = useState([
    superPortada,
    super2,
    super3,
  ]);
  const [barPortadas, setBarPortadas] = useState([barPortada, bar2, bar3]);
  const [kioskoPortadas, setKioskoProtadas] = useState([
    kioskoPortada,
    kiosko2,
    kiosko3,
  ]);
  const [heladeriaPortadas, setHeladeriaPortada] = useState([
    heladeriaPortada,
    heladeria2,
    heladeria3,
  ]);
  const [panaderiaPortadas, setPanaderiasPortadas] = useState([
    panaderiaPortada,
    panaderia2,
    panaderia3,
  ]);

  const mostarPortada = (portada, category, i) => {
    if (portada === undefined) {
      switch (category) {
        case "bar": {
          return barPortadas[i % barPortadas.length];
        }
        case "supermercado": {
          return superPortadas[i % superPortadas.length];
        }
        case "kiosko": {
          return kioskoPortadas[i % kioskoPortadas.length];
        }
        case "heladeria": {
          return heladeriaPortadas[i % heladeriaPortadas.length];
        }
        case "panaderia": {
          return panaderiaPortadas[i % panaderiaPortadas.length];
        }
        case "carniceria": {
          return carniceriaPortada;
        }
        case "verduleria": {
          return verduleriaPortada;
        }
        case "farmacia": {
          return farmaciaPortada;
        }
        default: {
          return superPortada;
        }
      }
    } else {
      return portada.filename;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        marginRight: "20px",
        flexDirection: "column",
        position: "relative",
        borderRadius: "5px",
        backgroundColor: "white",
        padding: "0",
        boxShadow:
          "3px 3px 6px 0 rgba(0, 0, 0, 0.2), -3px 3px 6px 0 rgba(0, 0, 0, 0.2)",
      }}
      onClick={() =>
        history.push({
          pathname: `/lista-productos/${local._id}`,
          state: {
            estaAbierto: true,
          },
        })
      }
    >
      {!estaAbierto(local) && (
        <span
          style={{
            width: "100%",
            height: "20px",
            position: "absolute",
            top: "0",
            left: "0",
            right: "20px",
            backgroundColor: "rgb(220, 53, 69, 0.8)",
            color: "white",
            textAlign: "center",
            borderRadius: "5px",
          }}
        >
          Cerrado
        </span>
      )}
      {deliveryAvailable === false && !local.envio && estaAbierto(local) && (
        <span
          style={{
            width: "100%",
            height: "20px",
            position: "absolute",
            top: "0",
            left: "0",
            right: "20px",
            backgroundColor: "#d6cfc7",
            color: "black",
            textAlign: "center",
            borderRadius: "5px",
          }}
        >
          Sin delivery momentaneamente
        </span>
      )}

      {deliveryAvailable !== false &&
        !local.envio &&
        estaAbierto(local) &&
        minutes > 5 && (
          <span
            style={{
              width: "100%",
              height: "20px",
              position: "absolute",
              top: "0",
              left: "0",
              right: "20px",
              backgroundColor: "#f8cf61",
              color: "black",
              textAlign: "center",
              borderRadius: "5px",
            }}
          >
            {`Aprox. ${Math.trunc(minutes)} min. para aceptar los pedidos`}
          </span>
        )}

      <img
        src={mostarPortada(local.portada, local.category, i)}
        style={{
          display: "block",
          width: "100%",
          height: "150px",
          objectFit: "cover",
          padding: "0",
          borderRadius: "5px",
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "0 1rem",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={
              local.avatar.filename !== ""
                ? local.avatar.filename
                : shopPlaceholder
            }
            className="my"
            style={{
              borderRadius: "50%",
              objectFit: "cover",
              width: "40px",
              height: "40px",
            }}
          />
          <div
            style={{
              marginLeft: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h3>{local.name}</h3>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span>
                <i
                  className="fas fa-star fa-sm"
                  style={{ marginRight: "5px", color: "#ffff00" }}
                />
                <strong>{local.rating}</strong>
              </span>
              {local.exibition && (
                <div style={{ color: "#ccc" }}>
                  <span style={{ marginLeft: "6px" }}>&#8226;</span>
                  <small style={{ marginLeft: "6px" }}>Solo exhibición</small>
                </div>
              )}
              {local.envio && (
                <div style={{ color: "#ccc" }}>
                  <span style={{ marginLeft: "6px" }}>&#8226;</span>
                  <small style={{ marginLeft: "6px" }}>
                    Delivery del negocio
                  </small>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NegocioCard;

export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const OPEN_SIDEPANEL = "OPEN_SIDEPANEL";
export const CLOSE_SIDEPANEL = "CLOSE_SIDEPANEL";
export const GET_LOCALES = "GET_LOCALES";
export const GET_LOCAL = "GET_LOCAL";
export const CLEAR_LOCALES = "CLEAR_LOCALES";
export const CLEAR_LOCAL = "CLEAR_LOCAL";
export const LOAD_CLIENTE = "LOAD_CLIENTE";
export const CLEAR_CLIENTE = "CLEAR_CLIENTE";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const CLEAR_PRODUCTS = "CLEAR_PRODUCTS";
export const SET_LISTA_PRODUCTS = "SET_LISTA_PRODUCTS";
export const CLEAR_LISTA_PRODUCTS = "CLEAR_LISTA_PRODUCTS";
export const SET_PEDIDOS = "SET_PEDIDOS";
export const CLEAR_PEDIDOS = "CLEAR_PEDIDOS";
export const GET_TARIFA_CADETERIA = "GET_TARIFA_CADETERIA";
export const GET_SECTIONS = "GET_SECTIONS";
export const CLEAR_SECTIONS = "CLEAR_SECTIONS";
export const GET_SERVICIOS = "GET_SERVICIOS";
export const CLEAR_SERVICIOS = "CLEAR_SERVICIOS";
export const GET_OFERTAS = "GET_OFERTAS";
export const CLEAR_OFERTAS = "CLEAR_OFERTAS";
export const GET_NEWS = "GET_NEWS";
export const GET_NEWS_ACTIVE = "GET_NEWS_ACTIVE";
export const CLEAR_NEWS = "CLEAR_NEWS";
export const SET_SOCKET = "SET_SOCKET";
export const CLEAR_SOCKET = "CLEAR_SOCKET";
export const SET_MAS_PEDIDOS = "SET_MAS_PEDIDOS";
export const SET_INSTALL_EVENT = "SET_INSTALL_EVENT";

import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// Modal
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

import logo from "../../assets/icono512.png";

const InstallAppModal = ({
  auth: { isAuthenticated },
  installReducer: { installEvent },
}) => {
  const [installAppModal, openInstallAppModal] = useState(false);
  useEffect(() => {
    if (isAuthenticated) {
      window.addEventListener("beforeinstallprompt", (e) => {
        e.preventDefault();

        openInstallAppModal(true);
      });
    }
  }, [isAuthenticated]);

  return (
    <Fragment>
      <Modal
        open={installAppModal}
        onClose={() => openInstallAppModal(false)}
        blockScroll={false}
        showCloseIcon={false}
        center
      >
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <img
              src={logo}
              alt="Logo"
              style={{
                display: "block",
                width: "40px",
                height: "auto",
                marginLeft: "auto",
                marginRight: "1rem",
              }}
            />

            <div style={{ display: "flex", flexDirection: "column" }}>
              <h2>Instalá la app de Andiamo</h2>
              <p style={{ paddingTop: "0.5rem" }}>
                Mejora tu experiencia instalando la app. Se abrirá desde su
                propia ventana y podras acceder desde el ícono como cualquier
                aplicación.
              </p>
            </div>
          </div>
          <div
            className="my-1"
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <button
              className="btn-secondary"
              onClick={() => openInstallAppModal(false)}
            >
              Ahora no
            </button>
            <button
              className="btn"
              onClick={() => {
                installEvent.prompt();
                openInstallAppModal(false);
              }}
            >
              Instalar
            </button>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

InstallAppModal.propTypes = {
  auth: PropTypes.object.isRequired,
  installReducer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  installReducer: state.installReducer,
});

export default connect(mapStateToProps, {})(InstallAppModal);

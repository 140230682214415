import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { redirectToLocal } from "../../actions/locales";
import { clearProductsGlobales } from "../../actions/products";
import ProductSearchBar from "../layout/ProductSearchBar";
import Spinner from "../layout/Spinner";
import Masonry from "react-masonry-css";
import notFound from "../../assets/404.png";
import CarouselProducts from "../layout/CarouselProducts";
import { estaAbierto } from "../../utils/auxFunctions";
import ReactGA from "react-ga";

export const GlobalProductsList = ({
  products: { loading, listaProducts },
  clearProductsGlobales,
  redirectToLocal,
  history,
}) => {
  // Google Analytics
  ReactGA.pageview("/productos-global-list");

  const [prods, setProds] = useState(null);

  /*
  useEffect(() => {
    return () => {
      clearProductsGlobales();
    };
  }, []);
  */

  useEffect(() => {
    if (prods === null && listaProducts !== null) setProds(listaProducts);
    if (prods !== null && listaProducts !== null)
      setProds([...prods, ...listaProducts]);
  }, [listaProducts]);

  const clearSignal = (v) => {
    if (v) setProds(null);
  };

  const breakpointColumnsObj = {
    default: 1,
  };

  const content =
    prods === null || loading ? (
      <Spinner />
    ) : prods.length === 0 ? (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <img
          src={notFound}
          alt="Not found"
          style={{ height: "15rem", width: "auto" }}
        />
        <strong>
          Uy... No encontramos lo que estas buscando. Probá buscar por marca del
          producto.
        </strong>
      </div>
    ) : (
      <Masonry breakpointCols={breakpointColumnsObj}>
        {prods
          .sort((a, b) => {
            if (
              estaAbierto(a.localHorario, a.localName) &&
              !estaAbierto(b.localHorario, b.localName)
            ) {
              return -1;
            } else if (
              !estaAbierto(a.localHorario, a.localName) &&
              estaAbierto(b.localHorario, b.localName)
            ) {
              return 1;
            } else {
              if (a.localRating > b.localRating) return -1;
              if (a.localRating < b.localRating) return 1;
              if (a.localRating === b.localRating) return 0;
            }
          })
          .map((product) => (
            <div
              key={product.productId}
              onClick={() =>
                redirectToLocal(product.localId, product.name, history)
              }
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderRadius: "5px",
                backgroundColor: "white",
              }}
            >
              <img
                src={product.image.filename}
                alt={product.name}
                style={{
                  height: "7.3rem",
                  width: "7.3rem",
                  borderRadius: "5px",
                  objectFit: "cover",
                }}
              />
              <div style={{ margin: "0.5rem" }}>
                <h3>{product.name}</h3>
                <strong>{`$${product.precio}`}</strong>
                <span style={{ display: "flex" }}>
                  <p>{product.localName}</p>
                  <span style={{ marginLeft: "0.5rem" }}>
                    <i
                      className="fas fa-star"
                      style={{ marginRight: "5px", color: "#ffff00" }}
                    />
                    <strong>{product.localRating}/5</strong>
                  </span>
                </span>
                <div className="my" style={{ display: "flex" }}>
                  {!estaAbierto(product.localHorario) && (
                    <span
                      style={{
                        padding: "0.2rem",
                        borderRadius: "5px",
                        backgroundColor: "rgb(220, 53, 69, 0.4)",
                        marginRight: "10px",
                      }}
                    >
                      Cerrado
                    </span>
                  )}
                  {!product.localEnvio && (
                    <span
                      style={{
                        padding: "0.2rem",
                        borderRadius: "5px",
                        backgroundColor: "rgb(23, 208, 112, 0.4)",
                      }}
                    >
                      <small>Entrega Andiamo</small>
                    </span>
                  )}
                </div>
              </div>
            </div>
          ))}
      </Masonry>
    );
  return (
    <div className="container">
      <ProductSearchBar clearSignal={clearSignal} shouldRedirect={() => {}} />
      <div className="my-1">
        <CarouselProducts clearSignal={clearSignal} />
      </div>
      <div className="my-3">{content}</div>
    </div>
  );
};

GlobalProductsList.propTypes = {
  products: PropTypes.object.isRequired,
  redirectToLocal: PropTypes.func.isRequired,
  clearProductsGlobales: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  products: state.products,
});

export default connect(mapStateToProps, {
  redirectToLocal,
  clearProductsGlobales,
})(GlobalProductsList);

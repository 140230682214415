import React, { useEffect } from "react";
import LazyLoad from "react-lazyload";
import { toast } from "react-toastify";

const ProductCard = ({
	i,
	product,
	local,
	minusProduct,
	plusProduct,
	getIndex,
	setProductoConExtras,
	setExtrasDelProducto,
	setOpenModal,
	ofertas,
	prodOfertaRef,
	productosSeleccionados,
	setProductosSeleccionados,
}) => {
	/**
	 * TO DO: Trasladar las funciones del componente ListaProductos que solo son utilizadas aquí
	 *        para evitar el prop drilling.
	 */

	const addUnit = () => {
		if (product.extras.length === 0) {
			plusProduct(product.producto, product.precio, []);
		} else {
			setProductoConExtras(product);
			setExtrasDelProducto(product);
			setOpenModal(true);
		}
	};

	useEffect(() => {
		if (
			product.stock &&
			productosSeleccionados[getIndex(product.producto._id)]?.cantidad ===
				product.stock
		) {
			toast.warn("No tenemos más unidades de este producto");
		}
	}, [minusProduct, addUnit]);

	const minusPlusButtons = (
		<div
			className="my"
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<button
				className="btn-rounded btn-rounded-small"
				style={{ marginRight: "10px" }}
				onClick={() => minusProduct(product.producto, product.precio)}
			>
				<i className="fa fa-minus fa-xs"></i>
			</button>
			{productosSeleccionados[getIndex(product.producto._id)] !== undefined
				? productosSeleccionados[getIndex(product.producto._id)].cantidad
				: 0}
			<button
				className="btn-rounded btn-rounded-small"
				style={{ marginLeft: "10px" }}
				disabled={
					productosSeleccionados[getIndex(product.producto._id)] !==
						undefined &&
					productosSeleccionados[getIndex(product.producto._id)].cantidad ===
						product.stock
				}
				onClick={() => addUnit()}
			>
				<i className="fa fa-plus fa-xs"></i>
			</button>
		</div>
	);

	const notAvailable = (
		<div className="my" style={{ textAlign: "center" }}>
			<strong>SIN STOCK</strong>
		</div>
	);

	const displayActionButtons = () => {
		if (local.exibition) {
			return notAvailable;
		}
		if (!product.disponible) {
			return notAvailable;
		}
		if (product.stock === undefined) {
			return minusPlusButtons;
		} else if (product.stock === 0) {
			return notAvailable;
		}
		// TO DO: si el stock local llega a 0 bloquear el producto

		return minusPlusButtons;
	};

	return (
		<>
			<div key={i} style={{ opacity: product.disponible ? undefined : "0.5" }}>
				<LazyLoad height={"auto"} once>
					<img
						src={product.producto.image.filename}
						style={{
							width: "100%",
							height: "auto",
							borderRadius: "5px",
						}}
					/>
				</LazyLoad>
				<div
					style={{
						padding: "0.5rem",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<h4>{product.producto.name}</h4>
					<p>{`$ ${product.precio}`}</p>
					<small style={{ marginTop: "10px" }}>{product.descripcion}</small>
					{ofertas !== null &&
						ofertas
							.map((item) => item.product._id)
							.indexOf(product.producto._id) !== -1 && (
							<small
								ref={(el) => (prodOfertaRef.current[product.producto._id] = el)}
								style={{
									backgroundColor: "rgb(23, 208, 112)",
									color: "white",
									padding: "0.2rem",
									borderRadius: "25px",
									margin: "0",
									marginTop: "5px",
									width: "fit-content",
								}}
							>
								Oferta
							</small>
						)}
				</div>
				{displayActionButtons()}
			</div>
		</>
	);
};

export default ProductCard;

import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Redirect, useHistory } from "react-router-dom";
import { login } from "../../actions/auth";

const LoginClient = ({
  login,
  auth: { isAuthenticated, user },
  profile: { profile },
}) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();

    await login(email, password);
  };

  useEffect(() => {
    if (isAuthenticated && user !== null) {
      if (user.isCliente === true && profile && profile !== null) {
        return <Redirect to="/" />;
      } else if (user.isCliente && profile === null) {
        console.log("redireccion a crear perfil");
        return (
          <Redirect
            to={{ pathname: "/create-profile", state: { from: "/login" } }}
          />
        );
      }
      if (user.isLocal || user.isDelivery) {
        return <Redirect to="/cambiar-cuenta" />;
      }
    }
  }, [isAuthenticated, profile]);

  return (
    <Fragment>
      <form onSubmit={(e) => onSubmit(e)}>
        <div className="input-icons">
          <i className="fas fa-user icon"></i>
          <input
            type="email"
            name="email"
            value={email}
            onChange={(e) => onChange(e)}
            placeholder="*Email"
            required
            className="input-field"
            style={{ borderBottom: "1px solid #0c120a", paddingLeft: "3rem" }}
          />
          <i className="fas fa-lock icon"></i>
          <input
            type="password"
            name="password"
            value={password}
            onChange={(e) => onChange(e)}
            placeholder="*Contraseña"
            required
            className="input-field"
            style={{ borderBottom: "1px solid #0c120a", paddingLeft: "3rem" }}
          />
        </div>
        <div style={{ float: "right" }}>
          <Link to="/recover-password">
            <p style={{ fontWeight: "bold" }}>¿Olvidaste tu contraseña?</p>
          </Link>
        </div>

        <div style={{ marginTop: "4rem", marginBottom: "5rem" }}>
          <input
            className="btn"
            type="submit"
            value="Iniciar Sesión"
            style={{ width: "100%" }}
          />
        </div>
        <div style={{ textAlign: "center" }}>
          <p>¿No tenes una cuenta?</p>
          <Link
            to="/register"
            style={{ fontWeight: "bold", cursor: "pointer" }}
          >
            Registrarme
          </Link>
        </div>
      </form>
    </Fragment>
  );
};

LoginClient.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, { login })(LoginClient);

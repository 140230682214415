import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import pedidoRealizado from "../../assets/pale-order-completed.png";
import dingdong from "../../assets/dingdong.mp3";

const PedidoExitoso = ({ location }) => {
  useEffect(() => {
    window.scrollTo(0, 0);

    const audio = new Audio(dingdong);
    audio.play();
  }, []);

  if (
    location.shouldRedirect !== false ||
    location.shouldRedirect === undefined
  ) {
    return <Redirect to="/" />;
  }

  return (
    <div
      className="landing"
      style={{
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
      }}
    >
      <img src={pedidoRealizado} style={{ width: "250px", height: "auto" }} />
      <div style={{ width: "90%", textAlign: "center" }}>
        <h1>Tu pedido se realizó correctamente &#x2764;</h1>
        <p>En un momento recibiras la confirmación del local</p>
        <div
          className="my-1"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <p>
            ¿Tenes alguna duda o sugerencia para darnos? Escribinos por Whatsapp
            al 3498435096 de Lunes a Sabados de 18:00 a 20:30 hs.
          </p>
          <a href="https://wa.me/3498435096" target="_blank" className="my">
            <span style={{ color: "#17d070" }}>Ir a Whatsapp</span>
          </a>
        </div>
      </div>
      <Link to="/pedidos" className="btn my-2">
        Seguir mi pedido
      </Link>
    </div>
  );
};

export default PedidoExitoso;

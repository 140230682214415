import {
  GET_LOCALES,
  GET_LOCAL,
  CLEAR_LOCALES,
  CLEAR_LOCAL,
  CLEAR_PRODUCTS,
} from "./types";
import axios from "axios";
import { setAlert } from "./alert";
import { loadUser } from "./auth";
import ReactGA from "react-ga";

// Registrar local
export const addLocal = (formData, history) => async (dispatch) => {
  try {
    // build form data
    const form = Object.keys(formData).reduce((f, k) => {
      f.append(k, formData[k]);
      return f;
    }, new FormData());

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios.post("/api/local/", form, config);

    window.confirm("Local añadido con exito");

    history.goBack();

    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

// Obtener MI Local
export const getMiLocal = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/local/me");

    dispatch({
      type: GET_LOCAL,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

//Obtener lista de locales
export const getLocales = (category, offset) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/local/locales/${category}/${offset}`);

    dispatch({
      type: GET_LOCALES,
      payload: { locales: res.data, category: category },
    });
  } catch (error) {
    console.log(error);
  }
};

export const getPublicKey = (id) => async (dispatch) => {
  try {
    const key = await axios.get(`/api/local/getPublicKey/${id}`);

    return key.data;
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

export const pagarPedido = (id, pedidoObj, history) => async (dispatch) => {
  try {
    var pago;
    console.log("cuantas veces se llama");

    if (pedidoObj.payment_method_id) {
      pago = await axios.post(`/api/local/recibirPago/${id}`, pedidoObj);
    } else {
      pago = await axios.post(
        `/api/local/recibirPagoTarjetaGuardada/${id}`,
        pedidoObj
      );
    }

    ReactGA.event({
      category: "Pedido",
      action: "pago con tarjeta",
    });

    const status = pago.data.status;

    if (status === "rejected" || status === "cancelled") {
      history.push({
        pathname: "/pago-rechazado",
        local: id,
        shouldRedirect: false,
      });

      return 400;
    }

    return pago.data;
  } catch (error) {
    window.alert(error.message);
  }
};

// Cambiar imagen de perfil del local
export const changeLocalPic = (formData) => async (dispatch) => {
  try {
    // build form data
    const form = Object.keys(formData).reduce((f, k) => {
      f.append(k, formData[k]);
      return f;
    }, new FormData());

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios.post("/api/local/avatar", form, config);

    dispatch(getMiLocal());
  } catch (error) {
    console.log(error);
  }
};

// Editar perfil del local
export const editLocal = (formData, history) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios.post("/api/local/edit-local", formData, config);

    dispatch(getMiLocal());

    window.confirm("Cambios guardados con exito");

    history.push("/admin-local");
  } catch (error) {
    throw error;
  }
};

export const getLocal = (id) => async (dispatch) => {
  try {
    dispatch({
      type: CLEAR_LOCALES,
    });

    const res = await axios.get(`/api/local/getLocal/${id}`);

    dispatch({
      type: GET_LOCAL,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

export const clearLocales = () => async (dispatch) => {
  try {
    dispatch({
      type: CLEAR_LOCALES,
    });
  } catch (error) {
    console.log(error);
  }
};

export const clearLocal = () => async (dispatch) => {
  try {
    dispatch({
      type: CLEAR_LOCAL,
    });
  } catch (error) {
    console.log(error);
  }
};

export const get_informe_local =
  ({ localId, startDate, endDate }) =>
  async (dispatch) => {
    try {
      const body = {
        startDate: startDate,
        endDate: endDate,
      };
      const res = await axios.post(
        `/api/admin/get_informe_local/${localId}`,
        body
      );

      return res.data;
    } catch (err) {
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      }
    }
  };

export const redirectToLocal = (id, product, history) => async (dispatch) => {
  try {
    dispatch({
      type: CLEAR_PRODUCTS,
    });

    history.push({
      pathname: `/lista-productos/${id}`,
      state: { product: product },
    });
  } catch (error) {
    console.error(error);
  }
};

export const getPromedioAceptacion = async (id) => {
  try {
    const res = await axios.get(`api/local/promedio-aceptacion/${id}`);
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

import React from "react";

const DeleteAccountRequest = () => {
	return (
		<div className="container">
			Para solicitar la baja de tu cuenta, por favor envianos un email a{" "}
			<a href="mailto:andiamoempresa@gmail.com" target="_blank">
				<strong>andiamoempresa@gmail.com</strong>
			</a>{" "}
			con el asunto "Baja de cuenta" y tu email de registro.
		</div>
	);
};

export default DeleteAccountRequest;

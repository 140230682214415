import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { createClient } from "../../actions/users";
import ReactGA from "react-ga";

export const CreateProfile = ({
  createClient,
  auth: { isAuthenticated, user },
  profile: { profile },
  history,
  location,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    dni: "",
    tel: "",
    calle: "",
    numero: "",
    piso: "",
    depto: "",
    fechaNac: "",
  });

  const { name, dni, tel, calle, numero, piso, depto, fechaNac } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    const formData = {
      name: name,
      dni: dni,
      tel: tel,
      //calle: calle,
      //numero: numero,
      //piso: piso,
      //depto: depto,
      fechaNac: fechaNac,
    };

    window.fbq("track", "CompleteRegistration");
    history.push({ pathname: "/add-address-from-map", state: { formData } });

    //createClient(formData);
  };

  if (isAuthenticated && user !== null) {
    if (user.isCliente === true && profile !== null) {
      return <Redirect to="/" />;
    }
    if (user.isLocal || user.isDelivery) {
      return <Redirect to="/cambiar-cuenta" />;
    }
  }

  ReactGA.pageview("/create-profile");

  return (
    <div className="container">
      <div className="my-2">
        <h1 className="my">Registrarme</h1>
        <h4 className="my">
          Ya casi terminamos. Completá los campos con tu información para
          terminar el proceso de crear tu perfil
        </h4>
      </div>
      <small>* = Campos obligatorios</small>
      <form onSubmit={(e) => onSubmit(e)}>
        <input
          type="text"
          name="name"
          value={name}
          onChange={(e) => onChange(e)}
          placeholder="*Nombre"
          required
        />
        <input
          type="text"
          name="dni"
          value={dni}
          onChange={(e) => onChange(e)}
          placeholder="*DNI"
          required
        />
        <input
          type="text"
          name="tel"
          value={tel}
          onChange={(e) => onChange(e)}
          placeholder="Tel."
          required
        />
        {/*<input
          type="text"
          name="calle"
          value={calle}
          onChange={(e) => onChange(e)}
          placeholder="*Calle"
          required
        />
        <input
          type="text"
          name="numero"
          value={numero}
          onChange={(e) => onChange(e)}
          placeholder="*Número"
          required
        />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <input
            type="text"
            name="piso"
            value={piso}
            onChange={(e) => onChange(e)}
            placeholder="Piso"
          />
          <input
            type="text"
            name="depto"
            value={depto}
            onChange={(e) => onChange(e)}
            placeholder="Depto"
  />
        </div>*/}
        <div>
          <input
            type="date"
            name="fechaNac"
            value={fechaNac}
            onChange={(e) => onChange(e)}
            placeholder="Fecha de nacimiento"
            required
          />
          <small>*Fecha de nacimiento</small>
        </div>
        <input
          type="submit"
          value="Siguiente"
          className="btn"
          style={{ float: "right", marginBottom: "3rem" }}
        />
      </form>
    </div>
  );
};

CreateProfile.propTypes = {
  createClient: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, { createClient })(CreateProfile);

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getPedidos, votar, cancelarPedido } from "../../actions/pedidos";
import { setSocketState } from "../../actions/socket";

import PedidosEnCursoList from "./PedidosEnCursoList";
import PedidosEntregadosList from "./PedidosEntregadosList";

const Pedidos = ({
  getPedidos,
  votar,
  cancelarPedido,
  pedidos: { pedidos, loading },
  socketReducer: { socket },
  setSocketState,
  history,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    getPedidos();
    /*
    if (socket === null) {
      setSocketState();
    }
    */
  }, []);

  const [displayPedidosEnCurso, setDisplayPedidosEnCurso] = useState(true);

  /*
  if (socket !== null) {
    socket.on("actualizar_pedido", () => {
      getPedidos();
    });
  }
  */

  const cancelar = async (id) => {
    await cancelarPedido(id);
    await getPedidos();
  };

  return (
    <div className="container">
      <div
        className="btn-secondary"
        style={{ width: "3rem", marginBottom: "1rem" }}
        onClick={() =>
          history.goBack({
            prev: "/pedidos",
          })
        }
      >
        <i className="fas fa-arrow-left"></i>
      </div>
      <h1>Lista de Pedidos</h1>
      <div
        className="my-1"
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          padding: "1rem",
        }}
      >
        <span
          className={displayPedidosEnCurso ? "btn" : "btn-secondary"}
          style={{ padding: "1rem" }}
          onClick={() => setDisplayPedidosEnCurso(true)}
        >
          En Curso
        </span>
        <span
          className={!displayPedidosEnCurso ? "btn" : "btn-secondary"}
          style={{ padding: "1rem" }}
          onClick={() => setDisplayPedidosEnCurso(false)}
        >
          Historial
        </span>
      </div>
      {displayPedidosEnCurso && (
        <PedidosEnCursoList
          pedidos={pedidos}
          cancelarPedido={cancelar}
          socket={socket}
          loading={loading}
        />
      )}
      {!displayPedidosEnCurso && (
        <PedidosEntregadosList
          pedidos={pedidos}
          loading={loading}
          votar={votar}
        />
      )}
    </div>
  );
};

Pedidos.propTypes = {
  pedidos: PropTypes.object.isRequired,
  getPedidos: PropTypes.func.isRequired,
  cancelarPedido: PropTypes.func.isRequired,
  votar: PropTypes.func.isRequired,
  socketReducer: PropTypes.object.isRequired,
  setSocketState: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  pedidos: state.pedidos,
  socketReducer: state.socketReducer,
});

export default connect(mapStateToProps, {
  getPedidos,
  cancelarPedido,
  votar,
  setSocketState,
})(Pedidos);

import { SET_SOCKET, CLEAR_SOCKET } from "../actions/types";

const initialState = {
  socket: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_SOCKET:
      return {
        ...state,
        socket: payload,
      };
    case CLEAR_SOCKET:
      return {
        ...state,
        socket: null,
      };
    default:
      return state;
  }
}

import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { createPedido, getEnvio } from "../../actions/pedidos";
import { setActualAddress, addAddressInModal } from "../../actions/users";
import { getDeliveryAvailability } from "../../actions/riders";
import ReactGA from "react-ga";
import Spinner from "../layout/Spinner";
import plusPagosIcon from "../../assets/plusPagos-icon.png";
import Note from "../layout/Note";

// Modal
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const Resumen = ({
  location,
  createPedido,
  getDeliveryAvailability,
  addAddressInModal,
  setActualAddress,
  getEnvio,
  history,
  profile: { profile },
}) => {
  // Google Analytics
  ReactGA.pageview("/resumen-pedido");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    async function fetchPrecioEnvio() {
      const precioEnvio = await getEnvio();
      setEnvio(precioEnvio);
    }
    fetchPrecioEnvio();
  }, []);

  const [envio, setEnvio] = useState(null);

  /********* Estados para el cambio de direcciones *********/

  const [openModal, setOpenModal] = useState(false);
  const [openModalAltProd, setOpenModalAltProd] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [displayAddAddress, setDisplayAddAddress] = useState(false);

  const saveAddress = () => {
    setActualAddress(selectedAddress);
    setSelectedAddress(null);
    setOpenModal(false);
  };

  const [formData, setFormData] = useState({
    calle: "",
    numero: "",
    piso: "",
    depto: "",
  });

  const { calle, numero, piso, depto } = formData;

  const onChangeAddress = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    addAddressInModal({ calle, numero, piso, depto });

    setFormData({
      calle: "",
      numero: "",
      piso: "",
      depto: "",
    });
  };

  /*******************************************************/
  const [notas, setNotas] = useState("");
  const [metodoPago, setMetodoPago] = useState("Efectivo");
  const [entrega, setEntrega] = useState("inmediata");
  const [horario, setHorario] = useState("11:00hs a 12:00hs");
  const [procesando, setProcesando] = useState(false);
  const [altProdAgotado, setAltProdAgotado] = useState(
    "Contactarme, Si no contesto elegir el mejor sustituto por mí"
  );
  const [deliveryAvailable, setDeliveryAvailable] = useState(null);

  useEffect(() => {
    const fetchDeliveryAvailability = async () => {
      const available = await getDeliveryAvailability();
      setDeliveryAvailable(available.available);
    };
    if (location.local.local.envio) {
      setEntrega("custom");
    } else {
      fetchDeliveryAvailability();
    }
  }, [location.local]);

  useEffect(() => {
    if (deliveryAvailable === false) {
      setEntrega("takeAway");
    }
  }, [deliveryAvailable]);

  if (location.local === undefined) return <Redirect to="/" />;
  if (location.products === undefined) return <Redirect to="/" />;

  const productos = location.products.productosSeleccionados.filter(
    (producto) => {
      return producto.cantidad !== 0;
    }
  );
  const local = location.local.local;

  const onChange = (e) => {
    setNotas(e.target.value);
  };

  const getSubtotal = () => {
    var sumatoria = 0;
    productos.forEach((producto) => {
      sumatoria += producto.cantidad * producto.price;
      producto.extras.forEach((extraArr) => {
        extraArr.forEach((ex) => {
          sumatoria += ex.precio;
        });
      });
    });

    return sumatoria;
  };

  const getTotal = () => {
    var delivery = 0;
    if (entrega !== "takeAway") {
      if (local.envio) {
        delivery = local.costoEnvio;
      } else {
        if (envio !== null) delivery = envio;
      }
    }
    return getSubtotal() + delivery;
  };

  const pedir = () => {
    setProcesando(true);
    const pedidoFormatted = [];

    productos.map((producto) => {
      pedidoFormatted.push({
        producto: producto.producto._id,
        precio: producto.price,
        cantidad: producto.cantidad,
        extras: producto.extras,
      });
    });

    const configObject = {
      metodoPago: metodoPago,
      notas:
        "Si alguno de los productos no se encuentra disponible " +
        altProdAgotado +
        ". " +
        notas,
      entrega: entrega,
      address: profile.actualAddress._id,
    };

    if (entrega === "horario") {
      configObject.horario = horario;
    }

    if (metodoPago !== "Tarjeta") {
      createPedido(local._id, pedidoFormatted, configObject, "", history);
    } else {
      history.push({
        pathname: `/tarjetas/${local._id}`,
        rawData: {
          rawProducts: location.products,
          local: location.local.local,
        },
        pedido: {
          local: local._id,
          productos: pedidoFormatted,
          total: getTotal(),
          configObject: configObject,
        },
      });
    }
  };

  const mostrarExtras = (extras) => {
    for (var i = 0; i < extras.length; i++) {
      if (extras[i].length > 0) return true;
    }

    return false;
  };

  const content =
    envio === null ? (
      <Spinner />
    ) : (
      <Fragment>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            className="btn-secondary"
            style={{ width: "3rem", marginBottom: "1rem" }}
            onClick={() => history.push(`/lista-productos/${local._id}`)}
          >
            <i className="fas fa-arrow-left"></i>
          </div>
          <h1>Resumen del Pedido</h1>
        </div>
        <div
          className="my-1"
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            borderRadius: "5px",
            padding: "0.5rem",
          }}
        >
          <div style={{ display: "flex" }}>
            <strong style={{ float: "left", width: "55%", padding: "8px" }}>
              Producto
            </strong>
            <strong style={{ float: "left", width: "23%", padding: "8px" }}>
              Cantidad
            </strong>
            <strong style={{ float: "left", width: "22%", padding: "8px" }}>
              Precio
            </strong>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {productos.map((producto, i) => (
              <div
                key={i}
                className="my"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div>
                  <strong
                    style={{ float: "left", width: "60%", padding: "8px" }}
                  >
                    {producto.producto.name}
                  </strong>
                  <p style={{ float: "left", width: "20%", padding: "8px" }}>
                    {producto.cantidad}
                  </p>
                  <p style={{ float: "left", width: "20%", padding: "8px" }}>
                    ${producto.price}
                  </p>
                </div>
                {mostrarExtras(producto.extras) &&
                  producto.extras.map((extra, j) => (
                    <div
                      key={j}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        paddingBottom: "10px",
                      }}
                    >
                      {extra.map((ex) => (
                        <div>
                          <p
                            style={{
                              float: "left",
                              width: "80%",
                              paddingLeft: "10px",
                            }}
                          >
                            {ex.name}
                          </p>
                          <p
                            style={{
                              float: "left",
                              width: "20%",
                              paddingLeft: "12px",
                            }}
                          >
                            ${ex.precio}
                          </p>
                        </div>
                      ))}
                    </div>
                  ))}
              </div>
            ))}
          </div>

          <div className="my">
            <hr style={{ borderBottom: "1px solid #0c120a" }} />
            <strong style={{ float: "left", width: "80%", padding: "8px" }}>
              Subtotal
            </strong>
            <p style={{ float: "left", width: "20%", padding: "8px" }}>
              ${getSubtotal()}
            </p>
          </div>
          {entrega !== "takeAway" && (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  float: "left",
                  width: "82%",
                  padding: "8px",
                }}
              >
                <strong>Envío</strong>
                {local.envio && <small>Delivery del negocio</small>}
              </div>
              <p style={{ float: "left", width: "18%", padding: "8px" }}>
                $
                {local.envio
                  ? local.costoEnvio
                  : envio === null
                  ? "..."
                  : envio}
              </p>
            </div>
          )}
          <div className="my">
            <hr style={{ borderBottom: "1px solid #0c120a" }} />
            <strong style={{ float: "left", width: "80%", padding: "8px" }}>
              Total
            </strong>
            <p style={{ float: "left", width: "20%", padding: "8px" }}>
              ${getTotal()}
            </p>
          </div>
        </div>
        <div
          style={{
            padding: "0.5rem",
            backgroundColor: "white",
            borderRadius: "5px",
          }}
        >
          <strong>Si alguno de los productos se encuentra agotado...</strong>
          <div
            className="my-1"
            style={{ display: "flex", alignItems: "center" }}
          >
            <p style={{ flexGrow: "2" }}>{altProdAgotado}</p>
            <strong
              style={{ flexGrow: "1", color: "#17d070" }}
              onClick={() => setOpenModalAltProd(true)}
            >
              Elegir
            </strong>
          </div>
        </div>
        <div
          className="my-1"
          style={{
            padding: "0.5rem",
            backgroundColor: "white",
            borderRadius: "5px",
          }}
        >
          <strong>Método de Pago</strong>
          <div className="my-1">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <span
                className="btn-secondary my"
                style={
                  metodoPago === "Efectivo"
                    ? {
                        backgroundColor: "#17d070",
                        color: "white",
                        border: "none",
                      }
                    : { backgroundColor: "white" }
                }
                onClick={() => setMetodoPago("Efectivo")}
              >
                <small style={{ marginLeft: "5px" }}>Efectivo</small>
              </span>
              {local.tarjeta && local.mpk && (
                <span
                  className="btn-secondary my"
                  style={
                    metodoPago === "Tarjeta"
                      ? {
                          backgroundColor: "#17d070",
                          color: "white",
                          border: "none",
                        }
                      : { backgroundColor: "white" }
                  }
                  onClick={() => setMetodoPago("Tarjeta")}
                >
                  <small style={{ marginLeft: "5px" }}>Tarjeta</small>
                </span>
              )}
              {local.plusPagos && (
                <span
                  className="btn-secondary my"
                  style={
                    metodoPago === "PlusPagos"
                      ? {
                          backgroundColor: "#17d070",
                          color: "white",
                          border: "none",
                        }
                      : { backgroundColor: "white" }
                  }
                  onClick={() => setMetodoPago("PlusPagos")}
                >
                  <small style={{ marginLeft: "5px" }}>Plus pagos</small>
                </span>
              )}
            </div>
          </div>
        </div>

        {
          <div
            className="my-1"
            style={{
              padding: "0.5rem",
              backgroundColor: "white",
              borderRadius: "5px",
            }}
          >
            <strong>Modalidad de Envío</strong>
            <div className="my-1">
              {entrega === "takeAway" && (
                <Note>
                  Cuando el pedido este preparado pasas a retirarlo por el
                  negocio
                </Note>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                }}
              >
                {local.envio && (
                  <div>
                    <span
                      className="btn-secondary my"
                      style={
                        entrega === "custom"
                          ? {
                              backgroundColor: "#17d070",
                              color: "white",
                              border: "none",
                            }
                          : { backgroundColor: "white" }
                      }
                      onClick={() => setEntrega("custom")}
                    >
                      <small>delivery del negocio</small>
                    </span>
                  </div>
                )}
                {!local.envio && deliveryAvailable && (
                  <span
                    className="btn-secondary my"
                    style={
                      entrega === "inmediata"
                        ? {
                            backgroundColor: "#17d070",
                            color: "white",
                            border: "none",
                          }
                        : { backgroundColor: "white" }
                    }
                    onClick={() => setEntrega("inmediata")}
                  >
                    <small>Inmediata</small>
                  </span>
                )}
                <span
                  className="btn-secondary"
                  style={
                    entrega === "takeAway"
                      ? {
                          backgroundColor: "#17d070",
                          color: "white",
                          border: "none",
                        }
                      : { backgroundColor: "white" }
                  }
                  onClick={() => setEntrega("takeAway")}
                >
                  <small>Retiro en el local</small>
                </span>
              </div>
              {entrega === "inmediata" && local.envio && (
                <small style={{ paddingTop: "0.5rem" }}>
                  {local.modalidadEnvio}
                </small>
              )}
            </div>
            {/*entrega === "horario" && (
            <div
              className="my"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <p>
                Sleccioná la franja horaria en la que queres recibir tu pedido
              </p>
              <div
                className="my"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <span
                  className="btn-secondary"
                  style={
                    horario === "11:00hs a 12:00hs"
                      ? {
                          backgroundColor: "#17d070",
                          color: "white",
                          border: "none",
                          margin: "0.5rem",
                        }
                      : { backgroundColor: "white", margin: "0.5rem" }
                  }
                  onClick={() => setHorario("11:00hs a 12:00hs")}
                >
                  11:00hs a 12:00hs
                </span>
                <span
                  className="btn-secondary"
                  style={
                    horario === "17:00hs a 18:00hs"
                      ? {
                          backgroundColor: "#17d070",
                          color: "white",
                          border: "none",
                          margin: "0.5rem",
                        }
                      : { backgroundColor: "white", margin: "0.5rem" }
                  }
                  onClick={() => setHorario("17:00hs a 18:00hs")}
                >
                  17:00hs a 18:00hs
                </span>
                <span
                  className="btn-secondary"
                  style={
                    horario === "20:00hs a 21:00hs"
                      ? {
                          backgroundColor: "#17d070",
                          color: "white",
                          border: "none",
                          margin: "0.5rem",
                        }
                      : { backgroundColor: "white", margin: "0.5rem" }
                  }
                  onClick={() => setHorario("20:00hs a 21:00hs")}
                >
                  20:00hs a 21:00hs
                </span>
              </div>
            </div>
          )*/}
          </div>
        }

        <div>
          <strong>Notas</strong>
          <form onSubmit={(e) => e.preventDefault()}>
            <textarea
              value={notas}
              onChange={(e) => onChange(e)}
              rows="4"
              placeholder="Una nota o aclaración para el local respecto a tu compra."
              style={{ resize: "none" }}
            />
          </form>
        </div>
        <div style={{ marginTop: "1rem" }}>
          <p>
            El pedidos será enviado a{" "}
            <strong>
              {profile.actualAddress.calle} {profile.actualAddress.numero}{" "}
              {profile.actualAddress.piso
                ? `Piso: ${profile.actualAddress.piso} Depto: ${profile.actualAddress.depto}`
                : null}
            </strong>
          </p>
          {profile.addresses.length > 1 && (
            <span
              className="my btn-secondary"
              onClick={() => setOpenModal(true)}
            >
              Enviar a otra dirección
            </span>
          )}
        </div>
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          blockScroll={false}
        >
          <div className="my-3">
            <h1>Elegir otra dirección</h1>
            <div className="my-1">
              {profile.addresses.map((address, i) => (
                <div
                  key={i}
                  className="my btn-secondary"
                  onClick={() => {
                    setSelectedAddress(address);
                    setDisplayAddAddress(false);
                  }}
                  style={
                    address === selectedAddress
                      ? { backgroundColor: "grey" }
                      : null
                  }
                >
                  <p>
                    {address.calle} {address.numero}{" "}
                    {address.piso
                      ? `Piso: ${address.piso} Depto: ${address.depto}`
                      : null}
                  </p>
                </div>
              ))}
            </div>

            <button
              className="btn"
              style={{ float: "left", backgroundColor: "#e76f51" }}
              onClick={() => {
                setOpenModal(false);
                setSelectedAddress(null);
              }}
            >
              Cancelar
            </button>
            {selectedAddress !== null && (
              <button
                className="btn"
                style={{ float: "right" }}
                onClick={() => saveAddress()}
              >
                Guardar
              </button>
            )}
          </div>
        </Modal>
        <Modal
          open={openModalAltProd}
          onClose={() => setOpenModalAltProd(false)}
        >
          <div className="my-3">
            <h1>Elegí una opción</h1>
            <div
              className="my"
              style={{ display: "flex", alignItems: "center" }}
            >
              <p>
                Contactarme. Si no contesto elegir el mejor sustituto por mí
              </p>
              <input
                type="radio"
                name="prodNoDisponible"
                value="Contactarme. Si no contesto elegir el mejor sustituto por mí"
                onChange={(e) => setAltProdAgotado(e.target.value)}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p>
                No incluir el producto en el pedido (si el pedido consta de este
                unico producto será rechazado)
              </p>
              <input
                type="radio"
                name="prodNoDisponible"
                value="No incluir el producto en el pedido (si el pedido consta de este unico producto será rechazado)"
                onChange={(e) => setAltProdAgotado(e.target.value)}
              />
            </div>
            <button
              className="btn my-1"
              style={{ float: "right" }}
              onClick={() => setOpenModalAltProd(false)}
            >
              Aceptar
            </button>
          </div>
        </Modal>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {procesando ? (
            <p className="my-2">Confirmando pedido...</p>
          ) : (
            <span
              className="my-2 btn"
              style={{ width: "100%", textAlign: "center" }}
              onClick={() => pedir()}
            >
              {metodoPago !== "Tarjeta" ? (
                <p>
                  Confirmar Pedido <i className="fas fa-shopping-cart" />
                </p>
              ) : (
                <p>Siguiente</p>
              )}
            </span>
          )}
        </div>
      </Fragment>
    );

  return <div className="container">{content}</div>;
};

Resumen.propTypes = {
  createPedido: PropTypes.func.isRequired,
  getDeliveryAvailability: PropTypes.func.isRequired,
  addAddressInModal: PropTypes.func.isRequired,
  setActualAddress: PropTypes.func.isRequired,
  getEnvio: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps, {
  createPedido,
  getDeliveryAvailability,
  addAddressInModal,
  setActualAddress,
  getEnvio,
})(Resumen);

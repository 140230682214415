import React from "react";

const Note = ({ children }) => {
  return (
    <div className="my warning-container">
      <i className="fa fa-info fa-sm"></i>
      {`  `}
      {children}
    </div>
  );
};

export default Note;

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getNews, changeState } from "../../actions/news";
import { Link } from "react-router-dom";

const News = ({ getNews, changeState, news: { news } }) => {
  useEffect(() => {
    getNews();
  });

  const content = news !== null && (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {news.map((newItem) => (
        <div
          className="my"
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            padding: "1rem",
            backgroundColor: "white",
          }}
        >
          <strong>{newItem.header}</strong>
          <div onClick={() => changeState(newItem._id)}>
            {newItem.active ? <span>Activa</span> : <span>Inactiva</span>}
          </div>
        </div>
      ))}
      <Link to="/create-news" className="btn my-1" style={{ width: "100%" }}>
        Crear Noticia
      </Link>
    </div>
  );

  return (
    <div className="container">
      <h1>Noticias</h1>
      <div className="my-1">{content}</div>
    </div>
  );
};

News.propTypes = {
  getNews: PropTypes.func.isRequired,
  changeState: PropTypes.func.isRequired,
  news: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  news: state.news,
});

export default connect(mapStateToProps, { getNews, changeState })(News);

import { GET_NEWS_ACTIVE, GET_NEWS, CLEAR_NEWS } from "./types";
import axios from "axios";
import { setAlert } from "./alert";

export const getNews = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/admin/news");

    dispatch({
      type: GET_NEWS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const createNews = (formData, history) => async (dispatch) => {
  try {
    // build form data
    const form = Object.keys(formData).reduce((f, k) => {
      f.append(k, formData[k]);
      return f;
    }, new FormData());

    await axios.post("/api/admin/news", form);

    history.push("/news");
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

export const changeState = (id) => async (dispatch) => {
  try {
    await axios.post(`/api/admin/news/change-active/${id}`);

    dispatch(getNews());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

export const getActive = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/admin/news/get-active");

    dispatch({
      type: GET_NEWS_ACTIVE,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

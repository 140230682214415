import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { getOfertas } from "../../../actions/sections";
// Carousel
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { CLEAR_OFERTAS } from "../../../actions/types";

import shopPlaceholder from "../../../assets/shop.png";

const OfertasCarousel = ({
  getOfertas,
  ofertas: { ofertas },
  local,
  ofertasRef,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    getOfertas(local);

    return () => {
      dispatch({ type: CLEAR_OFERTAS });
    };
  }, []);

  const [ofertasCards, setOfertasCards] = useState([
    "#259073",
    "#7fda89",
    "#c8e98e",
    "#e6f99d",
  ]);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      partialVisibilityGutter: 50,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 2,
      partialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 30,
    },
  };

  const handleScroll = (ref) => {
    window.scrollTo({
      top: ref.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  };

  const content = ofertas !== null && ofertas.length > 0 && (
    <div>
      <h3>Ofertas</h3>
      <Carousel
        responsive={responsive}
        removeArrowOnDeviceType={["mobile", "tablet"]}
        partialVisible
        infinite={false}
        minimumTouchDrag={0}
      >
        {ofertas.map((oferta, i) => (
          <div
            key={i}
            onClick={() => handleScroll(ofertasRef.current[oferta.product._id])}
            style={{
              height: "100px",
              marginRight: "20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: `${ofertasCards[i % ofertasCards.length]}`,
              borderRadius: "5px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "1rem",
              }}
            >
              <h3>{oferta.title}</h3>
              <p>{oferta.desc}</p>
            </div>
            <div style={{ float: "right" }}>
              <img
                src={
                  oferta.product.image.filename !== null
                    ? oferta.product.image.filename
                    : shopPlaceholder
                }
                alt="producto"
                style={{
                  height: "105px",
                  width: "150px",
                  margin: "auto",
                  marginRight: "0",
                  objectFit: "cover",
                  borderRadius: "5px",
                  clipPath: "polygon(40% 0, 100% 0%, 100% 100%, 0% 100%)",
                }}
              />
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );

  return content;
};

OfertasCarousel.propTypes = {
  getOfertas: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  ofertas: state.ofertas,
});

export default connect(mapStateToProps, { getOfertas })(OfertasCarousel);

import React, { useState, useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import credentials from "../../credentials";
import {
  createClient,
  addAddressFromMap,
  loadClient,
} from "../../actions/users";
import Spinner from "../layout/Spinner";
import { loadUser } from "../../actions/auth";

const AddAddressFromMap = ({ location, history }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  /************************** Datos de usuario *****************************************/
  const [userFormData, setUserFormData] = useState(null);
  useEffect(() => {
    if (location.state) {
      setUserFormData(location.state.formData);
    }
  }, []);
  /************************** Termina datos de usuario *****************************************/

  /************************** Datos para el Mapa *****************************************/
  const [mapRef, setMapRef] = useState(null);
  const [userCoords, setUserCoords] = useState(null);
  const [mapCenterCoord, setMapCenterCoord] = useState(null);
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: credentials.mapsKey,
  });

  useEffect(() => {
    const geo = navigator.geolocation;
    if (!geo) {
      console.log("Geolocation is not supported");
      return;
    }
    getCurrentPosition(geo);
  }, []);

  const getCurrentPosition = (geo) => {
    geo.getCurrentPosition((success) => {
      const coords = success.coords;
      setUserCoords({
        lat: coords.latitude,
        lng: coords.longitude,
      });
      setMapCenterCoord({
        lat: coords.latitude,
        lng: coords.longitude,
      });
    });
  };

  const handleCenterChanged = () => {
    if (mapRef) {
      const newCenter = mapRef.getCenter();
      if (
        mapCenterCoord.lat !== newCenter.lat() ||
        mapCenterCoord.lng !== newCenter.lng()
      ) {
        setMapCenterCoord({
          lat: newCenter.lat(),
          lng: newCenter.lng(),
        });
      }
    }
  };

  const renderMap = () => {
    if (isLoaded && userCoords !== null && mapCenterCoord) {
      const onLoad = (map) => {
        setMapRef(map);
      };

      return (
        <GoogleMap
          mapContainerStyle={{
            flex: "1",
            width: "100%",
          }}
          zoom={18}
          center={{ lat: mapCenterCoord.lat, lng: mapCenterCoord.lng }}
          options={{
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
          }}
          onLoad={onLoad}
          onCenterChanged={handleCenterChanged}
        >
          <Marker
            position={{ lat: mapCenterCoord.lat, lng: mapCenterCoord.lng }}
          />
        </GoogleMap>
      );
    } else {
      const geo = navigator.geolocation;
      if (geo) {
        getCurrentPosition(geo);
      }

      return (
        <div className="container">
          No se pudo obtener tu ubicación, por favor habilita el acceso de
          Andiamo a tu ubicación. Esto nos permite brindarte un mejor servicio
        </div>
      );
    }
  };
  /************************** Termina Datos para el Mapa *****************************************/

  /************************** Datos de la dirección *****************************************/
  const [addressFormData, setAddressFormData] = useState({
    calle: "",
    numero: "",
    piso: "",
    depto: "",
  });
  const { calle, numero, piso, depto } = addressFormData;

  const onChange = (e) => {
    setAddressFormData({
      ...addressFormData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const address = {
      ...addressFormData,
      coords: {
        ...mapCenterCoord,
      },
    };

    if (userFormData !== null) {
      await dispatch(createClient(userFormData));
    }
    await dispatch(addAddressFromMap(address));
    //if (userFormData !== null) {
    await dispatch(loadClient());
    await dispatch(loadUser);
    //}

    history.push("/");
  };
  /************************** Termina Datos de la dirección *****************************************/
  return isLoaded && !loading ? (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      {renderMap()}
      <div
        style={{
          padding: "0.5rem",
        }}
      >
        <div
          className="my"
          style={{
            padding: "0.5rem",
            borderRadius: "10px",
            border: "1px solid #f8cf61",
            backgroundColor: "rgba(255, 255, 255, 0.6)",
          }}
        >
          <small>
            Coloque el marcador sobre su casa y complete con los datos de su
            dirección.
          </small>
        </div>
        <div style={{ marginBottom: "0.5rem" }}>
          <small>* Campos obligatorios</small>
        </div>
        <form onSubmit={(e) => onSubmit(e)}>
          <small>* Campos obligatorios</small>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <p>*Calle</p>
              <input
                type="text"
                name="calle"
                value={calle}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <div style={{ marginLeft: "1rem" }}>
              <p>*Número</p>
              <input
                type="text"
                name="numero"
                value={numero}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <p>Piso</p>
              <input
                type="text"
                name="piso"
                value={piso}
                onChange={(e) => onChange(e)}
              />
            </div>
            <div style={{ marginLeft: "1rem" }}>
              <p>Dpto.</p>
              <input
                type="text"
                name="depto"
                value={depto}
                onChange={(e) => onChange(e)}
              />
            </div>
          </div>
          <input
            type="submit"
            value="Enviar"
            className="btn"
            style={{ width: "100%", marginTop: "1rem" }}
          />
        </form>
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

export default AddAddressFromMap;

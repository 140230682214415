import React, { useState } from "react";
import { resetPassword } from "../../actions/users";
import { useDispatch } from "react-redux";

const RecoverPassword = ({ history }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(resetPassword(email, history));
    setLoading(false);
  };

  return (
    <div className="container">
      <div
        className="btn-secondary"
        style={{ width: "3rem", marginBottom: "1rem" }}
        onClick={() => history.goBack()}
      >
        <i className="fas fa-arrow-left"></i>
      </div>
      <div>
        <h1>Recuperar Contraseña</h1>
        <h4 className="my">
          Ingresa la dirección de mail con la que te registrate y te enviaremos
          una nueva contraseña para que puedas acceder. Una vez lo hagas, no
          olvides actualizar tu contraseña.
        </h4>
      </div>
      <div className="my-2">
        <form
          onSubmit={(e) => {
            setLoading(true);
            onSubmit(e);
          }}
        >
          <input
            type="email"
            value={email}
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            disabled={loading ? true : false}
          />
          {loading ? (
            <p>Enviando...</p>
          ) : (
            <input
              type="submit"
              className="btn"
              value="Enviar"
              style={{ float: "right", marginTop: "0.5rem" }}
            />
          )}
        </form>
      </div>
    </div>
  );
};

export default RecoverPassword;

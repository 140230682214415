import React from "react";
import plusPagosIcon from "../../assets/plusPagos-icon.png";
import delivery from "../../assets/icono delivery.png";

const NegocioBanner = ({ local }) => {
  return (
    <>
      <div
        className="my-1"
        style={{
          flexDirection: "column",
          padding: "0.5rem",
          margin: "1rem",
          marginTop: "4rem",
          backgroundColor: "white",
          borderRadius: "5px",
        }}
      >
        <h1 style={{ fontSize: "2.5rem", wordBreak: "break-word" }}>
          {local.name}
        </h1>
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <span>
            <i
              className="fas fa-star"
              style={{ marginRight: "5px", color: "#ffff00" }}
            />
            <strong>{local.rating}</strong>
          </span>
          <p style={{ margin: "0 10px" }}>|</p>
          <p>{local.category}</p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          {local.tarjeta && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "white",
                marginRight: "1rem",
              }}
            >
              <i className="far fa-xs fa-credit-card"></i>
              <small style={{ marginLeft: "5px" }}>Tarjeta</small>
            </div>
          )}
          {local.plusPagos && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "white",
                marginRight: "1rem",
              }}
            >
              <img
                src={plusPagosIcon}
                alt="Plus Pagos"
                style={{
                  width: "0.7rem",
                  height: "auto",
                }}
              />
              <small style={{ marginLeft: "5px" }}>Plus pagos</small>
            </div>
          )}
          {!local.exibition && !local.envio && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "white",
                marginRight: "1rem",
              }}
            >
              <img
                src={delivery}
                alt="Andiamo"
                style={{
                  width: "1.2rem",
                  height: "auto",
                }}
              />
              <small style={{ marginLeft: "5px" }}>Andiamo entrega</small>
            </div>
          )}
          {!local.exibition && local.envio && (
            <div>
              <small>Delivery del negocio</small>
            </div>
          )}
        </div>
        <div style={{ color: "#ccc" }}>
          {local.exibition && (
            <small>
              Solo exhibición. No podes pedir directamente desde Andiamo
            </small>
          )}
        </div>
      </div>
    </>
  );
};

export default NegocioBanner;

import axios from "axios";
import { setAlert } from "./alert";
import ReactGA from "react-ga";

// Types
import { GET_TARIFA_CADETERIA } from "./types";

export const getTarifa = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/cadeteria/tarifa");

    dispatch({
      type: GET_TARIFA_CADETERIA,
      payload: res.data.precio,
    });
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

export const createTarifa = (tarifa) => async (dispatch) => {
  try {
    await axios.post("/api/cadeteria/tarifa", { precio: tarifa });

    dispatch(getTarifa());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

export const createViaje = (viajeData, history) => async (dispatch) => {
  try {
    if (
      window.confirm("Tocá en aceptar para completar el pedido de cadetería")
    ) {
      await axios.post("/api/cadeteria/viaje", viajeData);
      ReactGA.event({
        category: "Cadeteria",
        action: "pedido de cadeteria",
      });
      history.push("/cadeteria-exitoso");
    }
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

import React, { Component, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import pagoRechazado from "../../../assets/marginalia-payment-error.png";

export const PagoRechazado = ({ location }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  if (
    location.shouldRedirect !== false ||
    location.shouldRedirect === undefined
  ) {
    return <Redirect to="/" />;
  }
  return (
    <div
      className="landing"
      style={{
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        textAlign: "center",
      }}
    >
      <img src={pagoRechazado} style={{ width: "250px", height: "auto" }} />
      <h1>Ups...No pudimos procesar el pago</h1>
      <p>Revisá los datos de tu tarjeta y probá hacer el pedido nuevamente</p>

      <Link to={`/lista-productos/${location.local}`} className="btn my-2">
        Rehacer pedido
      </Link>
    </div>
  );
};

export default PagoRechazado;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { getTarifa, createViaje } from "../../actions/cadeteria";

const Cadeteria = ({
  getTarifa,
  createViaje,
  auth: { isAuthenticated },
  cadeteria: { tarifa },
  history,
}) => {
  useEffect(() => {
    getTarifa();
  }, []);

  const [data, setData] = useState({
    calleRecepcion: "",
    numeroRecepcion: "",
    pisoRecepcion: "",
    deptoRecepcion: "",
    refRecepcion: "",
    calleEntrega: "",
    numeroEntrega: "",
    pisoEntrega: "",
    deptoEntrega: "",
    refEntrega: "",
    notas: "",
  });

  const {
    calleRecepcion,
    numeroRecepcion,
    pisoRecepcion,
    deptoRecepcion,
    refRecepcion,
    calleEntrega,
    numeroEntrega,
    pisoEntrega,
    deptoEntrega,
    refEntrega,
    notas,
  } = data;

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const [procesando, setProcesando] = useState(false);
  const [renderRedirect, setRenderRedirect] = useState(false);
  const onSubmit = (e) => {
    e.preventDefault();
    setProcesando(true);
    createViaje(data, history);
    setProcesando(false);
  };

  return (
    <div className="my-2" style={{ display: "flex", flexDirection: "column" }}>
      <h1>Cadetería</h1>
      <form onSubmit={(e) => onSubmit(e)}>
        <div
          className="my-1"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <strong>Recepción del paquete</strong>
          <div>
            <p>Dirección de recepción</p>
            <input
              type="text"
              name="calleRecepcion"
              value={calleRecepcion}
              onChange={(e) => onChange(e)}
              placeholder="Calle"
            />
            <input
              type="text"
              name="numeroRecepcion"
              value={numeroRecepcion}
              onChange={(e) => onChange(e)}
              placeholder="Número"
            />
            <div style={{ display: "flex" }}>
              <input
                type="text"
                name="pisoRecepcion"
                value={pisoRecepcion}
                onChange={(e) => onChange(e)}
                placeholder="Piso"
              />
              <input
                type="text"
                name="deptoRecepcion"
                value={deptoRecepcion}
                onChange={(e) => onChange(e)}
                placeholder="Depto"
              />
            </div>
            <div className="my">
              <p>Referencia para ubicar la dirección</p>
              <input
                type="text"
                name="refRecepcion"
                value={refRecepcion}
                onChange={(e) => onChange(e)}
                placeholder="En frente de, pegado a, etc."
              />
            </div>
          </div>
        </div>
        <div
          className="my-1"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <strong>Entrega del paquete</strong>
          <p>Dirección de entrega</p>
          <input
            type="text"
            name="calleEntrega"
            value={calleEntrega}
            onChange={(e) => onChange(e)}
            placeholder="Calle"
          />
          <input
            type="text"
            name="numeroEntrega"
            value={numeroEntrega}
            onChange={(e) => onChange(e)}
            placeholder="Número"
          />
          <div style={{ display: "flex" }}>
            <input
              type="text"
              name="pisoEntrega"
              value={pisoEntrega}
              onChange={(e) => onChange(e)}
              placeholder="Piso"
            />
            <input
              type="text"
              name="deptoEntrega"
              value={deptoEntrega}
              onChange={(e) => onChange(e)}
              placeholder="Depto"
            />
          </div>
          <div className="my">
            <p>Referencia para ubicar la dirección</p>
            <input
              type="text"
              name="refEntrega"
              values={refEntrega}
              onChange={(e) => onChange(e)}
              placeholder="En frente de, pegado a, etc."
            />
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <strong>Notas</strong>
          <textarea
            name="notas"
            value={notas}
            onChange={(e) => onChange(e)}
            placeholder="Al llegar llamarme porque el timbre no funciona"
            rows="4"
            style={{ resize: "none" }}
          />
          <small>
            El servicio de cadetería no incluye realización de tramites, solo
            envio de paquetes.
          </small>
        </div>
        {!isAuthenticated ? (
          <Link to="/login" className="my btn-secondary">
            Iniciá sesion para pedir un rider
          </Link>
        ) : tarifa !== null && !procesando ? (
          <input
            type="submit"
            value={`Pedir un rider $${tarifa}`}
            className="my btn"
            style={{ width: "100%" }}
          />
        ) : (
          <p className="my">Procesando...</p>
        )}
      </form>
    </div>
  );
};

Cadeteria.propTypes = {
  getTarifa: PropTypes.func.isRequired,
  createViaje: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  cadeteria: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  cadeteria: state.cadeteria,
});

export default connect(mapStateToProps, { getTarifa, createViaje })(Cadeteria);

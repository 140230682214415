import { GET_SECTIONS, CLEAR_SECTIONS, GET_OFERTAS } from "./types";
import axios from "axios";
import { setAlert } from "./alert";

export const createSection = (title) => async (dispatch) => {
  const body = {
    title: title,
  };
  try {
    const res = await axios.post("/api/section/", body);
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

export const addLocalToSection = (secId, localId) => async (dispatch) => {
  const body = {
    local: localId,
  };
  try {
    await axios.post(`/api/section/addLocal/${secId}`, body);
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

export const deleteLocalFromSection = (secId, localId) => async (dispatch) => {
  const body = {
    local: localId,
  };
  try {
    await axios.post(`/api/section/deleteLocal/${secId}`, body);
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

export const changeVisibility = (id) => async (dispatch) => {
  try {
    await axios.post(`/api/section/changeVisibility/${id}`);
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

export const getSections = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/section/");
    dispatch({
      type: GET_SECTIONS,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

export const getOfertas = (local) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/section/ofertas/${local}`);
    dispatch({
      type: GET_OFERTAS,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createNews } from "../../actions/news";

const CreateNews = ({ createNews, history }) => {
  const [formData, setFormData] = useState({
    header: "",
    text: "",
    link: "",
    avatar: {
      url: "",
      avatar: "",
    },
  });

  const { header, text, avatar, link } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const url = URL.createObjectURL(e.target.files[0]);

      setFormData({
        ...formData,
        avatar: {
          url: url,
          avatar: e.target.files[0],
        },
      });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const form = {
      header: header,
      text: text,
      link: link,
      avatar: avatar.avatar,
    };

    createNews(form, history);
  };

  return (
    <div className="container">
      <h1>Crear Noticia</h1>
      <div className="my-1">
        <div
          style={{
            width: "100%",
            height: "auto",
            padding: "1rem",
            backgroundColor: "rgba(70, 255, 159)",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div style={{ marginRight: "0.5rem" }}>
            <h2>{header}</h2>
            <h5 style={{ marginTop: "0.5rem" }}>{text}</h5>
          </div>
          {avatar.url !== "" && (
            <img
              src={avatar.url}
              alt="avatar"
              style={{ width: "100px", height: "100px" }}
            />
          )}
        </div>
        <form onSubmit={(e) => onSubmit(e)}>
          <input
            type="text"
            name="header"
            value={header}
            onChange={(e) => onChange(e)}
            placeholder="*Encabezado"
            required
          />
          <input
            type="text"
            name="text"
            value={text}
            onChange={(e) => onChange(e)}
            placeholder="Texto"
            required
          />
          <input
            type="text"
            name="link"
            value={link}
            onChange={(e) => onChange(e)}
            placeholder="Link"
          />
          <div>
            <label
              htmlFor="upload-button"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {<span className="btn-secondary">Cargar imagen</span>}
            </label>
            <input
              type="file"
              id="upload-button"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => onImageChange(e)}
            />
          </div>
          <input type="submit" value="Crear" className="btn" />
        </form>
      </div>
    </div>
  );
};

CreateNews.propTypes = {
  createNews: PropTypes.func.isRequired,
};

export default connect(null, { createNews })(CreateNews);

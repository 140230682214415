// Saber si un local esta abierto en este momento
export const estaAbierto = (local, name) => {
  const current = new Date();
  const currentMin = current.getHours() * 60 + current.getMinutes();

  if (local.cerradoPorHoy) return false;

  var stringHorarioDe, stringHorarioHasta;
  var stringHorarioMañanaDe, stringHorarioMañanaHasta;
  var stringHorarioTardeDe, stringHorarioTardeHasta;
  var minDe, minHasta, minMañanaDe, minMañanaHasta, minTardeDe, minTardeHasta;

  if (current.getDay() >= 1 && current.getDay() <= 5) {
    if (local.horarios.semanal.corrido.de !== "") {
      stringHorarioDe = local.horarios.semanal.corrido.de;
      stringHorarioHasta = local.horarios.semanal.corrido.hasta;
      minDe =
        parseInt(stringHorarioDe) * 60 +
        parseInt(stringHorarioDe.substr(stringHorarioDe.indexOf(":") + 1));
      minHasta =
        parseInt(stringHorarioHasta) * 60 +
        parseInt(
          stringHorarioHasta.substr(stringHorarioHasta.indexOf(":") + 1)
        );
      return currentMin >= minDe && currentMin <= minHasta;
    } else if (
      local.horarios.semanal.mañana.de !== "" &&
      local.horarios.semanal.tarde.de !== ""
    ) {
      stringHorarioMañanaDe = local.horarios.semanal.mañana.de;
      stringHorarioMañanaHasta = local.horarios.semanal.mañana.hasta;
      stringHorarioTardeDe = local.horarios.semanal.tarde.de;
      stringHorarioTardeHasta = local.horarios.semanal.tarde.hasta;

      minMañanaDe =
        parseInt(stringHorarioMañanaDe) * 60 +
        parseInt(
          stringHorarioMañanaDe.substr(stringHorarioMañanaDe.indexOf(":") + 1)
        );
      minMañanaHasta =
        parseInt(stringHorarioMañanaHasta) * 60 +
        parseInt(
          stringHorarioMañanaHasta.substr(
            stringHorarioMañanaHasta.indexOf(":") + 1
          )
        );

      minTardeDe =
        parseInt(stringHorarioTardeDe) * 60 +
        parseInt(
          stringHorarioTardeDe.substr(stringHorarioTardeDe.indexOf(":") + 1)
        );
      minTardeHasta =
        parseInt(stringHorarioTardeHasta) * 60 +
        parseInt(
          stringHorarioTardeHasta.substr(
            stringHorarioTardeHasta.indexOf(":") + 1
          )
        );
      return (
        (currentMin >= minMañanaDe && currentMin <= minMañanaHasta) ||
        (currentMin >= minTardeDe && currentMin <= minTardeHasta)
      );
    }
  } else if (current.getDay() === 6) {
    if (local.horarios.sabado.corrido.de !== "") {
      stringHorarioDe = local.horarios.sabado.corrido.de;
      stringHorarioHasta = local.horarios.sabado.corrido.hasta;
      minDe =
        parseInt(stringHorarioDe) * 60 +
        parseInt(stringHorarioDe.substr(stringHorarioDe.indexOf(":") + 1));
      minHasta =
        parseInt(stringHorarioHasta) * 60 +
        parseInt(
          stringHorarioHasta.substr(stringHorarioHasta.indexOf(":") + 1)
        );
      return currentMin >= minDe && currentMin <= minHasta;
    } else if (
      local.horarios.sabado.mañana.de !== "" &&
      local.horarios.sabado.tarde.de !== ""
    ) {
      stringHorarioMañanaDe = local.horarios.sabado.mañana.de;
      stringHorarioMañanaHasta = local.horarios.sabado.mañana.hasta;
      stringHorarioTardeDe = local.horarios.sabado.tarde.de;
      stringHorarioTardeHasta = local.horarios.sabado.tarde.hasta;

      minMañanaDe =
        parseInt(stringHorarioMañanaDe) * 60 +
        parseInt(
          stringHorarioMañanaDe.substr(stringHorarioMañanaDe.indexOf(":") + 1)
        );
      minMañanaHasta =
        parseInt(stringHorarioMañanaHasta) * 60 +
        parseInt(
          stringHorarioMañanaHasta.substr(
            stringHorarioMañanaHasta.indexOf(":") + 1
          )
        );

      minTardeDe =
        parseInt(stringHorarioTardeDe) * 60 +
        parseInt(
          stringHorarioTardeDe.substr(stringHorarioTardeDe.indexOf(":") + 1)
        );
      minTardeHasta =
        parseInt(stringHorarioTardeHasta) * 60 +
        parseInt(
          stringHorarioTardeHasta.substr(
            stringHorarioTardeHasta.indexOf(":") + 1
          )
        );
      return (
        (currentMin >= minMañanaDe && currentMin <= minMañanaHasta) ||
        (currentMin >= minTardeDe && currentMin <= minTardeHasta)
      );
    }
  } else if (current.getDay() === 0) {
    if (local.horarios.domingo.corrido.de !== "") {
      stringHorarioDe = local.horarios.domingo.corrido.de;
      stringHorarioHasta = local.horarios.domingo.corrido.hasta;

      minDe =
        parseInt(stringHorarioDe) * 60 +
        parseInt(stringHorarioDe.substr(stringHorarioDe.indexOf(":") + 1));
      minHasta =
        parseInt(stringHorarioHasta) * 60 +
        parseInt(
          stringHorarioHasta.substr(stringHorarioHasta.indexOf(":") + 1)
        );
      return currentMin >= minDe && currentMin <= minHasta;
    } else if (
      local.horarios.domingo.mañana.de !== "" &&
      local.horarios.domingo.tarde.de !== ""
    ) {
      stringHorarioMañanaDe = local.horarios.domingo.mañana.de;
      stringHorarioMañanaHasta = local.horarios.domingo.mañana.hasta;
      stringHorarioTardeDe = local.horarios.domingo.tarde.de;
      stringHorarioTardeHasta = local.horarios.domingo.tarde.hasta;

      minMañanaDe =
        parseInt(stringHorarioMañanaDe) * 60 +
        parseInt(
          stringHorarioMañanaDe.substr(stringHorarioMañanaDe.indexOf(":") + 1)
        );
      minMañanaHasta =
        parseInt(stringHorarioMañanaHasta) * 60 +
        parseInt(
          stringHorarioMañanaHasta.substr(
            stringHorarioMañanaHasta.indexOf(":") + 1
          )
        );

      minTardeDe =
        parseInt(stringHorarioTardeDe) * 60 +
        parseInt(
          stringHorarioTardeDe.substr(stringHorarioTardeDe.indexOf(":") + 1)
        );
      minTardeHasta =
        parseInt(stringHorarioTardeHasta) * 60 +
        parseInt(
          stringHorarioTardeHasta.substr(
            stringHorarioTardeHasta.indexOf(":") + 1
          )
        );
      return (
        (currentMin >= minMañanaDe && currentMin <= minMañanaHasta) ||
        (currentMin >= minTardeDe && currentMin <= minTardeHasta)
      );
    } else if (
      local.horarios.domingo.mañana.de === "" &&
      local.horarios.domingo.tarde.de === "" &&
      local.horarios.domingo.corrido.de === ""
    ) {
      return false;
    }
  }
};

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import LoginClient from "../auth/LoginClient";
import ReactGA from "react-ga";
import logo from "../../assets/logo app andiamo.png";
import logoRedondo from "../../assets/icono512.png";

const Landing = ({ auth: { isAuthenticated, user }, profile: { profile } }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  if (isAuthenticated && user !== null) {
    if (user.isCliente === true && profile !== null) {
      return <Redirect to="/" />;
    } else if (user.isCliente && profile === null) {
      return <Redirect to="/create-profile" />;
    }
    if (user.isLocal || user.isDelivery) {
      return <Redirect to="/cambiar-cuenta" />;
    }
  }

  ReactGA.pageview("/landing");

  return (
    <div className="landing landing-bg">
      <div className="container">
        <div style={{ textAlign: "center", marginBottom: "2rem" }}>
          <img
            src={logoRedondo}
            alt="Logo"
            style={{ width: "125px", height: "auto" }}
          />
        </div>
        <h4>Iniciá Sesión</h4>
        <LoginClient />
      </div>
    </div>
  );
};

Landing.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, {})(Landing);

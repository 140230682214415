import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getTarjetas } from "../../../actions/users";
import { getPublicKey, pagarPedido } from "../../../actions/locales";
import { createPedido } from "../../../actions/pedidos";
import Spinner from "../../layout/Spinner";
import Shop from "../../../assets/shop.png";

const Tarjetas = ({
  auth: { user, loading },
  local,
  getTarjetas,
  getPublicKey,
  pagarPedido,
  createPedido,
  match,
  location,
  history,
}) => {
  const [cards, setCards] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getTarjetas(match.params.id, user.email);
      setCards(res);

      const key = await getPublicKey(match.params.id);
      window.Mercadopago.setPublishableKey(key);
    };
    if (user !== null && !loading) {
      fetchData();
    }
  }, [loading, user]);

  const [formData, setFormData] = useState({
    cardId: null,
    paymentMethodId: null,
    customer: null,
  });
  const { cardId, paymentMethodId, customer } = formData;

  const [cuotaSeleccionada, setCuotaSeleccionada] = useState(null);
  const [options, setOptions] = useState([]);

  const selectCard = (id, paymentId, customerId) => {
    setFormData({
      ...formData,
      cardId: id,
      paymentMethodId: paymentId,
      customer: customerId,
    });
  };
  const getCuotas = (id) => {
    window.Mercadopago.getInstallments(
      {
        payment_method_id: id,
        amount: location.pedido.total,
      },
      function (status, response) {
        if (status === 200) {
          const auxArray = [];
          response[0].payer_costs.forEach((installment) => {
            let opt = (
              <option
                key={installment.recommended_message}
                value={installment.installments}
              >
                {installment.recommended_message}
              </option>
            );
            auxArray.push(opt);
          });
          setOptions(auxArray);

          setCuotaSeleccionada(auxArray[0].props.value);
        } else {
          alert(response.message);
        }
      }
    );
  };

  const selectCuota = (e) => {
    const cuota = parseInt(e.target.value);
    setCuotaSeleccionada(cuota);
  };

  const pagar = (e) => {
    e.preventDefault();

    var $form = document.querySelector("#pay");
    window.Mercadopago.createToken($form, sdkResponseHandler);
  };

  const [procesandoPago, setProcesandoPago] = useState(false);
  const pagarYpedir = async (paymentObject) => {
    setProcesandoPago(true);

    const res = await pagarPedido(local._id, paymentObject, history);

    if (res.status === "approved") {
      await createPedido(
        local._id,
        location.pedido.productos,
        location.pedido.configObject,
        res.id,
        history
      );
    } else {
      setProcesandoPago(false);
    }
  };

  const sdkResponseHandler = (status, response) => {
    if (status != 200 && status != 201) {
      alert("Revisá los datos ingresados");
      console.log(response);
    } else {
      const paymentObject = {
        transaction_amount: location.pedido.total,
        token: response.id,
        installments: cuotaSeleccionada,
        customer_id: customer,
      };
      pagarYpedir(paymentObject);
      window.Mercadopago.clearSession();
    }
  };

  const [ccvHelp, setCvvHelp] = useState(false);

  const content =
    cards === null ? (
      <Spinner />
    ) : cards === undefined || local === null ? (
      local !== null ? (
        <div className="my-2">
          <strong>No tenes tarjetas guardadas</strong>
          <div
            className="my-1 btn-secondary"
            onClick={() => {
              history.push({
                pathname: "/pagar",
                rawData: location.rawData,
                pedido: location.pedido,
              });
            }}
          >
            <p>Pagar con otra tarjeta</p>
          </div>
        </div>
      ) : (
        <div className="my-2">
          <strong>
            Algo salió mal. Por favor no actualices la aplicación cuando estes
            completando un formulario
          </strong>
        </div>
      )
    ) : (
      <div
        className="my-2"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "white",
            borderRadius: "5px",
            padding: "0.5rem",
            textAlign: "center",
          }}
        >
          <img
            src={local.avatar.filename !== "" ? local.avatar.filename : Shop}
            alt={`${local.name}`}
            style={{ width: "80px", height: "auto" }}
          />
          <h2>{local.name}</h2>
          <p>{`Compra por $${location.pedido.total}`}</p>
        </div>
        <div
          className="my-1"
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            borderRadius: "5px",
            padding: "1rem",
          }}
        >
          <h2>Tus tarjetas</h2>
          <p>Seleccioná una</p>
          {cards !== null &&
            cards.map((card, i) => (
              <div
                key={i}
                className={i % 2 === 0 ? "my-1" : ""}
                style={{
                  backgroundColor: "#7bbb78",
                  borderRadius: "5px",
                  border: cardId === card.id ? "1px solid black" : "",
                  padding: "0.5rem",
                  color: "white",
                }}
                onClick={() => {
                  selectCard(card.id, card.payment_method.id, card.customer_id);
                  getCuotas(card.payment_method.id);
                }}
              >
                <strong>{card.issuer.name}</strong>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p>{card.cardholder.name}</p>
                  <p>{`**** ${card.last_four_digits}`}</p>
                </div>
              </div>
            ))}
          <div
            className="my-1 btn-secondary"
            onClick={() => {
              history.push({
                pathname: "/pagar",
                rawData: location.rawData,
                pedido: location.pedido,
              });
            }}
          >
            <p>Pagar con otra tarjeta</p>
          </div>
        </div>

        {cardId !== null && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "1rem",
              backgroundColor: "white",
              borderRadius: "5px",
            }}
          >
            <form method="post" id="pay" name="pay" onSubmit={(e) => pagar(e)}>
              <p style={{ display: "none" }}>
                <label htmlFor="cardId">Card id</label>
                <input
                  type="text"
                  id="cardId"
                  name="cardId"
                  data-checkout="cardId"
                  value={cardId}
                />
              </p>
              <p>
                <label htmlFor="installments">
                  <strong>Cuotas</strong>
                </label>
                <select
                  id="installments"
                  className="form-control"
                  name="installments"
                  options
                  placeholder="Seleccione las cuotas"
                  style={{ padding: "0.5rem", borderBottom: "1px solid black" }}
                  onChange={(e) => selectCuota(e)}
                >
                  {options}
                </select>
              </p>
              <p
                className="my-1"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label htmlFor="cvc">
                  <strong>Número de seguridad de la tarjeta</strong>
                </label>
                <input
                  type="text"
                  id="cvv"
                  data-checkout="securityCode"
                  placeholder="Ej. 123"
                  onPaste={() => false}
                  onCopy={() => false}
                  onCut={() => false}
                  onDrag={() => false}
                  onDrop={() => false}
                  autoComplete="off"
                  style={{
                    padding: "0.5rem",
                    borderBottom: "1px solid black",
                  }}
                />
                <small
                  style={{ color: "#3366BB" }}
                  onClick={() => setCvvHelp(!ccvHelp)}
                >
                  Qué es el número de seguridad?
                </small>
                {ccvHelp && (
                  <small>
                    Es un número de 3 dígitos que suele encontrarse en el
                    reverso de la tarjeta
                  </small>
                )}
              </p>
              {procesandoPago ? (
                <p>Procesando Pago...</p>
              ) : (
                <p style={{ float: "right" }}>
                  <input type="submit" value="Pagar" className="btn" />
                </p>
              )}
            </form>
          </div>
        )}
      </div>
    );

  return (
    <div className="container">
      <div
        className="btn-secondary"
        style={{ width: "3rem", marginBottom: "1rem" }}
        onClick={() =>
          history.push({
            pathname: "/resumen",
            products:
              location.rawData === undefined
                ? undefined
                : location.rawData.rawProducts,
            local:
              location.rawData === undefined
                ? undefined
                : { local: location.rawData.local },
          })
        }
      >
        <i className="fas fa-arrow-left"></i>
      </div>
      <h1>Pago con tarjeta</h1>
      <p>Ya casi terminamos...Elegí con que tarjeta vas a pagar</p>
      {content}
    </div>
  );
};

Tarjetas.propTypes = {
  auth: PropTypes.object.isRequired,
  local: PropTypes.object.isRequired,
  getTarjetas: PropTypes.func.isRequired,
  getPublicKey: PropTypes.func.isRequired,
  pagarPedido: PropTypes.func.isRequired,
  createPedido: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  local: state.locales.local,
});

export default connect(mapStateToProps, {
  getTarjetas,
  getPublicKey,
  pagarPedido,
  createPedido,
})(Tarjetas);

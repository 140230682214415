import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { getPublicKey, pagarPedido } from "../../../actions/locales";
import { createPedido } from "../../../actions/pedidos";
import Spinner from "../../layout/Spinner";

const Pagar = ({
  auth: { user },
  local,
  getPublicKey,
  pagarPedido,
  createPedido,
  location,
  history,
}) => {
  useEffect(() => {
    if (local !== null && local.tarjeta) {
      const inicializarData = async () => {
        const key = await getPublicKey(local._id);
        window.Mercadopago.setPublishableKey(key);
        window.Mercadopago.getIdentificationTypes();
      };
      inicializarData();
    }
  }, []);

  const onCardChange = (e) => {
    guessPaymentMethod(e);
  };

  const [cuotas, setCuotas] = useState(null);
  const [formData, setFormData] = useState({
    cardThumbnail: null,
    element: null,
    transactionAmount: null,
    options: [],
    doSubmit: false,
    trans_amount: location.pedido ? location.pedido.total : 0,
  });
  const { cardThumbnail, element, options, doSubmit, trans_amount } = formData;

  const onInstallmentsChange = (e) => {
    const cantCuotas = parseInt(e.target.value);
    setCuotas(cantCuotas);
  };
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const guessPaymentMethod = (e) => {
    let cardnumber = e.target.value;

    if (cardnumber.length >= 6) {
      let bin = cardnumber.substring(0, 6);
      window.Mercadopago.getPaymentMethod(
        {
          bin: bin,
        },
        setPaymentMethod
      );
      return;
    }

    setFormData({ ...formData, cardThumbnail: null });
  };

  const setPaymentMethod = (status, response) => {
    if (status == 200) {
      let paymentMethodId = response[0].id;
      getInstallments(paymentMethodId);
      setFormData({
        ...formData,
        cardThumbnail: response[0].secure_thumbnail,
      });
    } else {
      alert(`payment method info error: ${response}`);
    }
  };

  const getInstallments = (paymentMethodId) => {
    window.Mercadopago.getInstallments(
      {
        payment_method_id: paymentMethodId,
        amount: parseFloat(trans_amount),
      },
      function (status, response) {
        if (status == 200) {
          const auxArray = [];
          response[0].payer_costs.forEach((installment) => {
            let opt = (
              <option
                key={installment.recommended_message}
                value={installment.installments}
              >
                {installment.recommended_message}
              </option>
            );
            auxArray.push(opt);
          });
          setCuotas(auxArray[0].props.value);
          setFormData({
            ...formData,
            element: paymentMethodId,
            options: auxArray,
          });
        } else {
          window.alert(response);
        }
      }
    );
  };

  const doPay = (e) => {
    e.preventDefault();

    if (!doSubmit) {
      var $form = document.querySelector("#pay");

      window.Mercadopago.createToken($form, sdkResponseHandler);

      return false;
    }
  };

  const [procesandoPago, setProcesandoPago] = useState(false);
  const pagarYpedir = async (paymentObject) => {
    setProcesandoPago(true);
    // Primero pago. Si el pago es correcto hago el pedido
    const res = await pagarPedido(local._id, paymentObject, history);
    console.log(res);

    if (res.status === "approved")
      await createPedido(
        local._id,
        location.pedido.productos,
        location.pedido.configObject,
        res.id,
        history
      );
  };

  const sdkResponseHandler = (status, response) => {
    if (status != 200 && status != 201) {
      alert("Revisá los datos ingresados");
      console.log(response);
    } else {
      var form = document.querySelector("#pay");
      var card = document.createElement("input");
      card.setAttribute("name", "token");
      card.setAttribute("type", "hidden");
      card.setAttribute("value", response.id);
      form.appendChild(card);
      setFormData({ ...formData, doSubmit: true });

      const paymentObj = {
        description: `Compra en ${local.name}`,
        token: response.id,
        transaction_amount: trans_amount,
        payment_method_id: element,
        installments: cuotas,
        email: user.email,
      };
      pagarYpedir(paymentObj);
      // Este metodo limpia la sesion para que se pueda pagar de nuevo
      window.Mercadopago.clearSession();
    }
  };

  if (user === null || local === null || location.pedido === undefined) {
    return <Redirect to="/" />;
  }

  const content =
    user === null || local === null || location.pedido === undefined ? (
      <div className="my-2">
        <strong>
          Algo salió mal. Por favor no actualices la aplicación cuando estes
          completando un formulario
        </strong>
      </div>
    ) : (
      <div
        className="my-2"
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "1rem",
          backgroundColor: "white",
          borderRadius: "5px",
        }}
      >
        <h2>Nueva tarjeta</h2>
        <form
          className="my-1"
          onSubmit={(e) => doPay(e)}
          method="post"
          id="pay"
          name="pay"
        >
          <p>
            <input
              type="hidden"
              name="description"
              id="description"
              value={`Compra en ${local.name}`}
            />
          </p>
          <p>
            <input
              type="hidden"
              name="transaction_amount"
              id="transaction_amount"
              value={trans_amount}
            />
          </p>
          <p>
            <label htmlFor="cardHolderName">
              <strong>Nombre y apellido</strong>
            </label>
            <input
              type="text"
              id="cardholderName"
              data-checkout="cardholderName"
              placeholder="Ej. Juan Perez"
              style={{ padding: "0.5rem", borderBottom: "1px solid black" }}
            />
          </p>
          <p>
            <label htmlFor="docType">
              <strong>Tipo de documento</strong>
            </label>
            <select
              id="docType"
              data-checkout="docType"
              style={{ padding: "0.5rem", borderBottom: "1px solid black" }}
            ></select>
          </p>
          <p>
            <label htmlFor="docNumber">
              <strong>Número de documento</strong>
            </label>
            <input
              type="text"
              id="docNumber"
              data-checkout="docNumber"
              placeholder="Ej. 45202316"
              style={{ padding: "0.5rem", borderBottom: "1px solid black" }}
            />
          </p>
          <div>
            <label htmlFor="cardNumber">
              <strong>Número de tarjeta</strong>
            </label>
            <div
              className="input-icons"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={cardThumbnail !== null ? cardThumbnail : undefined}
                alt="Card"
                className="icon"
                style={
                  cardThumbnail !== null
                    ? { display: "block", width: "55px", height: "auto" }
                    : { display: "none" }
                }
              />
              <input
                type="text"
                id="cardNumber"
                data-checkout="cardNumber"
                onPaste={() => false}
                onCopy={() => false}
                onCut={() => false}
                onDrag={() => false}
                onDrop={() => false}
                autoComplete="off"
                placeholder="Ej. 5031 7557 3453 0604"
                className="input-field"
                style={{
                  padding: "0.5rem",
                  paddingLeft: "3rem",
                  borderBottom: "1px solid black",
                }}
                onChange={(e) => onCardChange(e)}
              />
            </div>
          </div>
          <strong>Fecha de vencimiento</strong>
          <div style={{ display: "flex" }}>
            <p>
              <input
                type="text"
                id="cardExpirationMonth"
                data-checkout="cardExpirationMonth"
                onPaste={() => false}
                onCopy={() => false}
                onCut={() => false}
                onDrag={() => false}
                onDrop={() => false}
                autoComplete="off"
                placeholder="Mes"
                style={{ padding: "0.5rem", borderBottom: "1px solid black" }}
              />
            </p>
            <p>
              <input
                type="text"
                id="cardExpirationYear"
                data-checkout="cardExpirationYear"
                onPaste={() => false}
                onCopy={() => false}
                onCut={() => false}
                onDrag={() => false}
                onDrop={() => false}
                autoComplete="off"
                placeholder="Año"
                style={{ padding: "0.5rem", borderBottom: "1px solid black" }}
              />
            </p>
          </div>
          <p>
            <label htmlFor="securityCode">
              <strong>Código de seguridad</strong>
            </label>
            <input
              type="text"
              id="securityCode"
              data-checkout="securityCode"
              onPaste={() => false}
              onCopy={() => false}
              onCut={() => false}
              onDrag={() => false}
              onDrop={() => false}
              autoComplete="off"
              placeholder="Ej. 123"
              style={{ padding: "0.5rem", borderBottom: "1px solid black" }}
            />
          </p>
          <p>
            <label htmlFor="installments">
              <strong>Cuotas</strong>
            </label>
            <select
              id="installments"
              className="form-control"
              name="installments"
              options
              onChange={(e) => onInstallmentsChange(e)}
              style={{ padding: "0.5rem", borderBottom: "1px solid black" }}
            >
              {options}
            </select>
          </p>
          <p>
            <input type="hidden" id="email" name="email" value={user.email} />
          </p>
          <input
            type="hidden"
            name="payment_method_id"
            id="payment_method_id"
            value={element}
          />
          {procesandoPago ? (
            <p className="my-2">Procesando pago...</p>
          ) : (
            <input
              type="submit"
              value="Pagar"
              className="btn my-2"
              style={{ width: "100%" }}
            />
          )}
        </form>
      </div>
    );

  return (
    <div className="container">
      <div
        className="btn-secondary"
        style={{ width: "3rem", marginBottom: "1rem" }}
        onClick={() =>
          history.push({
            pathname: `/tarjetas/${location.pedido.local}`,
            rawData:
              location.rawData === undefined ? undefined : location.rawData,
            pedido: location.pedido === undefined ? undefined : location.pedido,
          })
        }
      >
        <i className="fas fa-arrow-left"></i>
      </div>
      <div>
        <h1>Pago con tarjeta</h1>
        <p>Cargá los datos de la nueva tarjeta</p>
      </div>
      {content}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  local: state.locales.local,
});

Pagar.propTypes = {
  auth: PropTypes.object.isRequired,
  local: PropTypes.object.isRequired,
  getPublicKey: PropTypes.func.isRequired,
  pagarPedido: PropTypes.func.isRequired,
  createPedido: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  getPublicKey,
  pagarPedido,
  createPedido,
})(Pagar);

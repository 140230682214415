import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import setAuthToken from "./utils/setAuthToken";
import { loadUser } from "./actions/auth";
import PrivateRoute from "./components/routing/PrivateRoute";
import CreateProfileRoute from "./components/routing/CreateProfileRoute";
import ReactGA from "react-ga";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Alert from "./components/layout/Alert";
import Navbar from "./components/layout/Navbar";
import Landing from "./components/layout/Landing";
import SidePanel from "./components/dashboard/SidePanel";
import RegisterClient from "./components/auth/Register";
import CreateProfile from "./components/profile/CreateProfile";
import NegociosList from "./components/negocios/NegociosList";
import AddAddress from "./components/dashboard/AddAddress";
import AddressList from "./components/dashboard/AddressList";
import ListaProducto from "./components/negocios/productos/ListaProductos";
import Resumen from "./components/pedidos/Resumen";
import Pedidos from "./components/pedidos/Pedidos";
import Pagar from "./components/pedidos/pago/Pagar";
import Tarjetas from "./components/pedidos/pago/Tarjetas";
import PagoRechazado from "./components/pedidos/pago/PagoRechazado";
import PedidoExitoso from "./components/pedidos/PedidoExitoso";
import PedidoCadeteExitoso from "./components/cadeteria/PedidoCadeteExitoso";
import AdminPanel from "./components/dashboard/AdminPanel";
import CambiarCuenta from "./components/layout/CambiarCuenta";
import GlobalProductsList from "./components/productos/GlobalProductsList";
import SectionsList from "./components/negocios/SectionsList";
import AddServicio from "./components/dashboard/AddServicio";
import News from "./components/news/News";
import CreateNews from "./components/news/CreateNews";
import TrackPedido from "./components/pedidos/trackPedido/TrackPedido";
import InstallAppModal from "./components/layout/InstallAppModal";
import AddAddressFromMap from "./components/profile/AddAddressFromMap";
import EditProfile from "./components/profile/EditProfile";
import EditPassword from "./components/profile/password/EditPassword";
import EditPhone from "./components/profile/phone/EditPhone";
import RecoverPassword from "./components/auth/RecoverPassword";
import PasswordResetted from "./components/auth/PasswordResetted";
import DeleteAccountRequest from "./components/deleteAccountRequest/DeleteAccountRequest";
import PrivacyPolicy from "./components/privacyPolicy/PrivacyPolicy";

import "./App.css";

if (localStorage.token) {
	setAuthToken(localStorage.token);
}

ReactGA.initialize("UA-180231574-1");

const App = () => {
	useEffect(() => {
		store.dispatch(loadUser());
	}, []);

	return (
		<Provider store={store}>
			<Router>
				<Navbar />
				<Alert />
				<ToastContainer />
				<Route exact path="/" component={SectionsList} />
				<SidePanel />
				<InstallAppModal />
				<section>
					<Switch>
						<Route exact path="/register" component={RegisterClient} />
						<Route exact path="/negocios-list" component={NegociosList} />
						<Route exact path="/recover-password" component={RecoverPassword} />
						<Route
							exact
							path="/resetted-password"
							component={PasswordResetted}
						/>
						<PrivateRoute
							exact
							path="/create-profile"
							component={CreateProfile}
						/>
						<Route exact path="/login" component={Landing} />
						<PrivateRoute exact path="/add-address" component={AddAddress} />
						<PrivateRoute exact path="/addresses" component={AddressList} />
						<Route
							exact
							path="/lista-productos/:id"
							component={ListaProducto}
						/>
						<Route
							exact
							path="/lista-productos-globales"
							component={GlobalProductsList}
						/>
						<PrivateRoute exact path="/tarjetas/:id" component={Tarjetas} />
						<PrivateRoute exact path="/resumen" component={Resumen} />
						<PrivateRoute exact path="/pedidos" component={Pedidos} />
						<PrivateRoute exact path="/pagar" component={Pagar} />
						<PrivateRoute
							exact
							path="/pago-rechazado"
							component={PagoRechazado}
						/>
						<PrivateRoute
							exact
							path="/pedido-exitoso"
							component={PedidoExitoso}
						/>
						<PrivateRoute
							exact
							path="/cadeteria-exitoso"
							component={PedidoCadeteExitoso}
						/>
						<PrivateRoute exact path="/admin" component={AdminPanel} />
						<PrivateRoute
							exact
							path="/cambiar-cuenta"
							component={CambiarCuenta}
						/>
						<PrivateRoute exact path="/add-servicio" component={AddServicio} />
						<PrivateRoute exact path="/news" component={News} />
						<PrivateRoute exact path="/create-news" component={CreateNews} />
						<PrivateRoute
							exact
							path="/track-pedido/:id"
							component={TrackPedido}
						/>
						<PrivateRoute
							exact
							path="/add-address-from-map"
							component={AddAddressFromMap}
						/>
						<PrivateRoute exact path="/edit-profile" component={EditProfile} />
						<PrivateRoute
							exact
							path="/edit-password"
							component={EditPassword}
						/>
						<PrivateRoute exact path="/edit-phone" component={EditPhone} />
						<Route
							exact
							path="/delete-account"
							component={DeleteAccountRequest}
						/>
						<Route exact path="/privacy-policy" component={PrivacyPolicy} />
					</Switch>
				</section>
			</Router>
		</Provider>
	);
};

export default App;

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { editPassword } from "../../../actions/users";
import { setAlert } from "../../../actions/alert";
import Spinner from "../../layout/Spinner";

const EditPassword = ({ history }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword1: "",
    newPassword2: "",
  });

  const { oldPassword, newPassword1, newPassword2 } = formData;

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (newPassword1 !== newPassword2) {
      dispatch(
        setAlert("La nueva contraseña no coincide con la repetición", "danger")
      );
      return;
    }

    await dispatch(editPassword(oldPassword, newPassword1, history));
    setLoading(false);
  };

  return loading ? (
    <Spinner />
  ) : (
    <div
      className="container"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div
        className="btn-secondary"
        style={{ width: "3rem", marginBottom: "1rem" }}
        onClick={() => history.goBack()}
      >
        <i className="fas fa-arrow-left"></i>
      </div>
      <div>
        <h1>Cambiar Contraseña</h1>
      </div>
      <div className="my-2">
        <form
          onSubmit={(e) => {
            setLoading(true);
            onSubmit(e);
          }}
        >
          <input
            type="password"
            name="oldPassword"
            value={oldPassword}
            onChange={(e) => onChange(e)}
            placeholder="Antigua contraseña"
            required
          />
          <input
            type="password"
            name="newPassword1"
            value={newPassword1}
            onChange={(e) => onChange(e)}
            placeholder="Nueva contraseña"
            required
          />
          <input
            type="password"
            name="newPassword2"
            value={newPassword2}
            onChange={(e) => onChange(e)}
            placeholder="Repetir nueva contraseña"
            required
          />
          <small>
            La contraseña debe tener una longitud de al menos 6 caracteres
          </small>
          <input
            type="submit"
            value="Enviar"
            className="btn"
            style={{ float: "right", marginTop: "1rem" }}
          />
        </form>
      </div>
    </div>
  );
};

export default EditPassword;

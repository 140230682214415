import React from "react";
import { Link } from "react-router-dom";

const PasswordResetted = () => {
  return (
    <div
      className="container"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <h3>
        Te enviamos un mail con tu nueva contraseña. Si no lo ves en tu bandeja
        de entrada, no olvides revisar la carpeta de correo no deseado.
      </h3>
      <Link to="/login" className="btn my-1" style={{ width: "100%" }}>
        Volver al inicio
      </Link>
    </div>
  );
};

export default PasswordResetted;

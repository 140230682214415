import { LOAD_CLIENTE, CLEAR_CLIENTE } from "./types";
import axios from "axios";
import { setAlert } from "./alert";
import { loadUser } from "./auth";
import ReactGA from "react-ga";

// Cargar Cliente
export const loadClient = () => async (dispatch) => {
  const res = await axios.get("/api/clientes");

  dispatch({
    type: LOAD_CLIENTE,
    payload: res.data,
  });
};

// Limpiar el perfil del usuario
export const clearCliente = () => async (dispatch) => {
  dispatch({
    type: CLEAR_CLIENTE,
  });
};

// Crear perfil del cliente
export const createClient = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios.post("/api/clientes", formData, config);

    // Google Analytics
    ReactGA.event({
      category: "Usuario",
      action: "perfil creado",
    });

    //dispatch(loadClient());
    //dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

// Añadir una direccion
export const addAddress =
  ({ calle, numero, piso, depto }, history) =>
  async (dispatch) => {
    const addressObject = {
      calle: calle,
      numero: numero,
      piso: piso,
      depto: depto,
    };

    try {
      await axios.post("/api/clientes/address", addressObject);

      dispatch(setAlert("Dirección añadida con exito", "success"));

      history.goBack();
    } catch (err) {
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      }
    }
  };

export const addAddressFromMap = (addressFormData) => async (dispatch) => {
  try {
    await axios.post("/api/clientes/address-from-map", addressFormData);
    dispatch(setAlert("Dirección añadida con exito", "success"));
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

export const addAddressInModal =
  ({ calle, numero, piso, depto }) =>
  async (dispatch) => {
    const addressObject = {
      calle: calle,
      numero: numero,
      piso: piso,
      depto: depto,
    };

    try {
      await axios.post("/api/clientes/address", addressObject);
      dispatch(loadClient());
    } catch (err) {
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      }
    }
  };

// Borrar una direccion
export const deleteAddress = (address) => async (dispatch) => {
  try {
    const body = {
      address: address,
    };

    await axios.post("/api/clientes/deleteAddress", body);

    dispatch(setAlert("Direccion eliminada", "success"));
    dispatch(loadClient());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

// Setear nueva direccion actual
export const setActualAddress = (address, history) => async (dispatch) => {
  try {
    await axios.post("/api/clientes/actualAddress", address);
    dispatch(loadClient());
  } catch (error) {
    console.log(error);
  }
};

// Cambiar foto de perfil
export const changeUserPic = (formData) => async (dispatch) => {
  try {
    // build form data
    const form = Object.keys(formData).reduce((f, k) => {
      f.append(k, formData[k]);
      return f;
    }, new FormData());

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios.post("/api/clientes/avatar", form, config);

    dispatch(loadClient());
  } catch (error) {
    console.log(error);
  }
};

// Obtener lista de tarjetas del cliente
export const getTarjetas = (id, email) => async (dispatch) => {
  try {
    const tarjetas = await axios.get(`/api/local/getTarjetas/${id}`, {
      params: { email: email },
    });
    return tarjetas.data;
  } catch (error) {
    console.log(error);
  }
};

// Enviar notificaciones a los usuarios
export const sendNotification = (title, message) => async (dispatch) => {
  try {
    const body = {
      title: title,
      message: message,
    };
    await axios.post("/api/clientes/notifyUsers", body);
  } catch (error) {
    console.log(error);
  }
};

export const editPassword =
  (oldPassword, newPassword, history) => async (dispatch) => {
    try {
      const body = {
        oldPassword,
        newPassword,
      };

      await axios.post("/api/users/edit-password", body);
      history.push("/edit-profile");
      dispatch(setAlert("Contraseña actualizada exitosamente", "success"));
    } catch (err) {
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      }
    }
  };

export const resetPassword = (email, history) => async (dispatch) => {
  try {
    const body = {
      email,
    };

    await axios.post("/api/clientes/reset-password", body);
    history.push("/resetted-password");
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import SlidingPanel from "react-sliding-side-panel";
import { closeSidePanel, logout } from "../../actions/auth";
import { changeUserPic, loadClient } from "../../actions/users";
import profilePlaceholder from "../../assets/user.png";

const SidePanel = ({
  loadClient,
  closeSidePanel,
  changeUserPic,
  logout,
  auth: { user, displaySidePanel, isAuthenticated },
  profile: { profile, loading },
  history,
}) => {
  useEffect(() => {
    loadClient();
  }, []);

  const [userAvatar, setUserAvatar] = useState({
    raw: "",
  });
  const [shouldDisplayGuardarButton, setGuardarButton] = useState(false);

  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setUserAvatar({
        raw: e.target.files[0],
      });
    }

    setGuardarButton(true);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const formData = {
      userAvatar: userAvatar.raw ? userAvatar.raw : "",
    };

    changeUserPic(formData);
    setGuardarButton(false);
  };

  const redirect = (route) => {
    history.push(route);
    closeSidePanel();
  };

  const content = (
    <SlidingPanel type={"right"} isOpen={displaySidePanel} size={80}>
      <div style={{ height: "100%", backgroundColor: "rgb(249, 249, 249)" }}>
        <i
          className="fas fa-times fa-lg my-1"
          onClick={() => closeSidePanel()}
          style={{ float: "right", marginRight: "1.5rem" }}
        />
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          {loading || !isAuthenticated || user === null ? (
            <div
              style={{
                marginLeft: "1.5rem",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                className="my-1"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <small>Ya tenes una cuenta?</small>
                <Link
                  to="/login"
                  className="btn"
                  onClick={() => closeSidePanel()}
                >
                  Iniciá Sesion
                </Link>
              </div>
              <div
                className="my-1"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <small>Creá una cuenta</small>
                <Link
                  to="/register"
                  className="btn"
                  onClick={() => closeSidePanel()}
                >
                  Registrarme
                </Link>
              </div>
            </div>
          ) : user.isLocal || user.isDelivery ? (
            <div
              style={{
                marginLeft: "1.5rem",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p>Cambiá a una cuenta de cliente para poder usar esta app</p>
              <span
                className="btn"
                style={{
                  backgroundColor: "#e76f51",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => logout()}
              >
                <i
                  className="fas fa-sign-out-alt"
                  style={{ marginLeft: "5px" }}
                />
                <p style={{ marginLeft: "5px" }}>Cerrar Sesión</p>
              </span>
            </div>
          ) : profile === null && user.isCliente ? (
            <div
              style={{
                marginLeft: "1.5rem",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p>Primero debes crear tu perfil</p>
              <span
                className="btn my"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => redirect("/create-profile")}
              >
                <i className="fas fa-user" />
                <p style={{ marginLeft: "5px" }}>Crear Perfil</p>
              </span>
              <span
                className="btn"
                style={{
                  backgroundColor: "#e76f51",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => logout()}
              >
                <i
                  className="fas fa-sign-out-alt"
                  style={{ marginLeft: "5px" }}
                />
                <p style={{ marginLeft: "5px" }}>Cerrar Sesión</p>
              </span>
            </div>
          ) : (
            <Fragment>
              <div
                className="my-1"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={
                    profile.avatar.filename === ""
                      ? profilePlaceholder
                      : profile.avatar.filename
                  }
                  alt="Usuario"
                  className="profilePic rounded"
                />
                <div>
                  <label
                    htmlFor="upload-button"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    {
                      <i
                        className="fas fa-camera-retro fa-lg btn-rounded"
                        style={{
                          height: "50px",
                          width: "50px",
                          padding: "1rem",
                          backgroundColor: "#efeff1",
                          color: "#0c120a",
                        }}
                      ></i>
                    }
                  </label>
                  <input
                    type="file"
                    id="upload-button"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={(e) => onImageChange(e)}
                  />
                </div>
                <h2 className="my">
                  <strong>{profile.name}</strong>
                </h2>
              </div>
              <div
                style={{
                  marginLeft: "1.5rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <small>Email</small>
                <strong>{user.email}</strong>
              </div>
              <div
                style={{
                  marginLeft: "1.5rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <small>Telefono</small>
                <strong>{profile.tel}</strong>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    marginLeft: "1.5rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <small>Mis Direcciones</small>
                  {profile.actualAddress ? (
                    <div>
                      <div>
                        <strong>{`${profile.actualAddress.calle} ${profile.actualAddress.numero}`}</strong>
                        {profile.actualAddress.piso !== "" && (
                          <strong>{`Piso: ${profile.actualAddress.piso} Depto: ${profile.actualAddress.depto}`}</strong>
                        )}
                      </div>
                    </div>
                  ) : (
                    <strong>Todavía no tenes direcciones cargadas</strong>
                  )}
                </div>
                <div
                  className="btn-rounded"
                  onClick={() => redirect("/addresses")}
                  style={{
                    backgroundColor: "#028090",
                    height: "30px",
                    width: "30px",
                    padding: "0.5rem",
                  }}
                >
                  <i className="fas fa-angle-right fa-lg" />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    marginLeft: "1.5rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <small>Mis Pedidos</small>
                  <strong>Tocá para ver tus pedidos</strong>
                </div>
                <div
                  className="btn-rounded"
                  onClick={() => redirect("/pedidos")}
                  style={{
                    backgroundColor: "#028090",
                    height: "30px",
                    width: "30px",
                    padding: "0.5rem",
                  }}
                >
                  <i className="fas fa-angle-right fa-lg" />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    marginLeft: "1.5rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <small>Mi Perfil</small>
                  <strong>Editar mis datos</strong>
                </div>
                <div
                  className="btn-rounded"
                  onClick={() => redirect("/edit-profile")}
                  style={{
                    backgroundColor: "#028090",
                    height: "30px",
                    width: "30px",
                    padding: "0.5rem",
                  }}
                >
                  <i className="fas fa-angle-right fa-lg" />
                </div>
              </div>

              {profile.subscription === undefined &&
                window.Notification.permission !== "denied" && (
                  <div
                    style={{
                      marginLeft: "1.5rem",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <small
                      style={{ userSelect: "none", color: "#0000EE" }}
                      onClick={() => window.location.reload()}
                    >
                      Tocá para activar las notificaciones y seguir el estado de
                      tus pedidos
                    </small>
                  </div>
                )}
              <div>
                {shouldDisplayGuardarButton && (
                  <span
                    className="btn"
                    onClick={(e) => onSubmit(e)}
                    style={{ marginLeft: "1.5rem" }}
                  >
                    Guardar Cambios
                  </span>
                )}
              </div>
              <div
                className="my-1"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "1.5rem",
                }}
              >
                {profile.admin && (
                  <Link to="/admin" className="btn btn-primary">
                    Panel Admin
                  </Link>
                )}
                <span
                  className="btn"
                  style={{
                    backgroundColor: "#e76f51",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => logout()}
                >
                  <i
                    className="fas fa-sign-out-alt"
                    style={{ marginLeft: "5px" }}
                  />
                  <p style={{ marginLeft: "5px" }}>Cerrar Sesión</p>
                </span>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </SlidingPanel>
  );

  return <div>{content}</div>;
};

SidePanel.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  closeSidePanel: PropTypes.func.isRequired,
  changeUserPic: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  loadClient: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, {
  closeSidePanel,
  changeUserPic,
  logout,
  loadClient,
})(withRouter(SidePanel));

import React, { Fragment, useState } from "react";

const HorarioLlegada = ({ pedido, cantPedidos }) => {
  const [horarioLlegada, setHorarioLlegada] = useState(
    pedido.horarioLlegada ? new Date(pedido.horarioLlegada) : null
  );

  const displayOrderDetails = () => {
    if (pedido.local.envio) {
      if (pedido.entrega === "takeAway") {
        if (horarioLlegada && horarioLlegada !== null) {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "0.5rem",
              }}
            >
              <i className="fa fa-clock fa-sm"></i>
              <p style={{ marginTop: "0.5rem" }}>
                {`Podes retirar tu pedido a partir de las `}
                {String(horarioLlegada.getHours()).padStart(2, "0")}:
                {String(horarioLlegada.getMinutes()).padStart(2, "0")} hs.
              </p>
            </div>
          );
        }
      } else {
        return (
          <p style={{ marginTop: "0.5rem" }}>Entrega a cargo del negocio</p>
        );
      }
    } else {
      if (horarioLlegada && horarioLlegada !== null) {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "0.5rem",
            }}
          >
            <i className="fa fa-clock fa-sm"></i>
            <p style={{ marginLeft: "0.5rem" }}>
              {pedido.entrega === "takeAway"
                ? `Podes retirar tu pedido a partir de las `
                : `Horario de llegada aproximado: `}
              {String(horarioLlegada.getHours()).padStart(2, "0")}:
              {String(horarioLlegada.getMinutes()).padStart(2, "0")} hs.
            </p>
          </div>
        );
      }
    }
  };

  return horarioLlegada !== null ? displayOrderDetails() : <div />;
};

export default HorarioLlegada;

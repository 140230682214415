import React from "react";
// Modal
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const AddExtrasModal = ({
  openModal,
  setOpenModal,
  extrasSeleccionados,
  addExtraSeleccionado,
  productoConExtras,
  extrasDeProducto,
  plusProduct,
}) => {
  const modExtraSeleccionado = (extra) => {
    const i = extrasSeleccionados
      .map((ex) => {
        return ex.name;
      })
      .indexOf(extra.name);
    if (i === -1) {
      addExtraSeleccionado([
        ...extrasSeleccionados,
        { name: extra.name, precio: extra.precio },
      ]);
    } else {
      extrasSeleccionados.splice(i, 1);
      addExtraSeleccionado([...extrasSeleccionados]);
    }
  };

  const modExtraConOpcionSeleccionado = (extra, op) => {
    const i = extrasSeleccionados
      .map((ex) => {
        return ex.name;
      })
      .indexOf(extra.name);
    if (i === -1) {
      // Si el extra no está, lo agrego junto con la op seleccionada
      addExtraSeleccionado([
        ...extrasSeleccionados,
        { name: extra.name, precio: extra.precio, options: [op.name] },
      ]);
    } else {
      // Si el extra ya está en el array
      // Veo si la opcion seleccionada ya esta. En ese caso la saco
      const iOpcion = extrasSeleccionados[i].options.indexOf(op.name);
      if (iOpcion !== -1) {
        extrasSeleccionados[i].options.splice(iOpcion, 1);
        if (extrasSeleccionados[i].options.length === 0)
          extrasSeleccionados.splice(i, 1);
        addExtraSeleccionado([...extrasSeleccionados]);
      } else {
        // En caso de que la opcion no este, me fijo el limite de opciones permitidas
        if (extra.limite > extrasSeleccionados[i].options.length) {
          extrasSeleccionados[i].options.push(op.name);
          addExtraSeleccionado([...extrasSeleccionados]);
        }
      }
    }
  };

  const addCSSClass = (extra, op) => {
    const elem = extrasSeleccionados.find((ex) => ex.name === extra.name);

    if (elem === undefined) return "far fa-circle";

    const i = elem.options.indexOf(op.name);
    if (i === -1) return "far fa-circle";
    return "fas fa-circle";
  };

  const addCSSStyle = (extra, op) => {
    const elem = extrasSeleccionados.find((ex) => ex.name === extra.name);

    if (elem === undefined) return { color: "black" };

    const i = elem.options.indexOf(op.name);
    if (i === -1) return { color: "black" };
    return { color: "#17d070" };
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          addExtraSeleccionado([]);
        }}
        center
        blockScroll={false}
      >
        {productoConExtras !== null && (
          <div className="my-3">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src={productoConExtras.producto.image.filename}
                alt="Producto"
                style={{
                  width: "250px",
                  height: "auto",
                  borderRadius: "5px",
                }}
              />
              <h1 className="my-1">{productoConExtras.producto.name}</h1>
              <h3>{`$${productoConExtras.precio}`}</h3>
            </div>
            <div className="my-2">
              <p>Queres añadir un extra?</p>
              {extrasDeProducto.map((extra, i) => (
                <div key={i}>
                  {extra.options.length === 0 ? (
                    <div
                      className="my-1"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: "#efeff1",
                        borderRadius: "5px",
                        padding: "0.5rem",
                      }}
                      onClick={() => modExtraSeleccionado(extra)}
                    >
                      <strong>{`${extra.name} $${extra.precio}`}</strong>
                      <i
                        className={
                          extrasSeleccionados
                            .map((ex) => {
                              return ex.name;
                            })
                            .indexOf(extra.name) === -1
                            ? "far fa-circle"
                            : "fas fa-circle"
                        }
                        style={
                          extrasSeleccionados
                            .map((ex) => {
                              return ex.name;
                            })
                            .indexOf(extra.name) === -1
                            ? { color: "black" }
                            : { color: "#17d070" }
                        }
                      ></i>
                    </div>
                  ) : (
                    <div
                      className="my-1"
                      style={{
                        backgroundColor: "#efeff1",
                        borderRadius: "5px",
                        padding: "0.5rem",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <strong>{`${extra.name} $${extra.precio}`}</strong>
                        <p
                          style={{ marginLeft: "5px" }}
                        >{`elegí ${extra.limite}`}</p>
                      </div>
                      <div className="my-1">
                        {extra.options.map(
                          (op, j) =>
                            op.disponible && (
                              <div
                                key={j}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                                onClick={() =>
                                  modExtraConOpcionSeleccionado(extra, op)
                                }
                              >
                                <p>{op.name}</p>
                                <i
                                  className={addCSSClass(extra, op)}
                                  style={addCSSStyle(extra, op)}
                                ></i>
                              </div>
                            )
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div style={{ display: "flex" }}>
              <span
                className="btn"
                style={{ backgroundColor: "#e76f51" }}
                onClick={() => {
                  setOpenModal(false);
                  addExtraSeleccionado([]);
                }}
              >
                Cancelar
              </span>
              <span
                className="btn"
                style={{ marginLeft: "0.5rem" }}
                onClick={() => {
                  plusProduct(
                    productoConExtras.producto,
                    productoConExtras.precio,
                    extrasSeleccionados
                  );
                  setOpenModal(false);
                  addExtraSeleccionado([]);
                }}
              >
                Agregar al carrito
              </span>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default AddExtrasModal;

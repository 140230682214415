import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { getSections } from "../../actions/sections";
import { redirectToLocal } from "../../actions/locales";
import { clearProductsGlobales, clearProducts } from "../../actions/products";
import { clearLocales } from "../../actions/locales";
import { setInstallEvent } from "../../actions/installEvent";
import { getDeliveryAvailability } from "../../actions/riders";
import ProductSearchBar from "../layout/ProductSearchBar";
import Spinner from "../layout/Spinner";
import MasPedidos from "../productos/MasPedidos";
import NewsComoponent from "../layout/NewsComponent";
import ReactGA from "react-ga";
import PedidosActivosModal from "../pedidos/activos/PedidosActivosModal";
import NegocioCard from "./NegocioCard";
// Carousel
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import supermarket from "../../assets/negocios-categorias/carrito.png";
import bar from "../../assets/negocios-categorias/burger.png";
import heladeria from "../../assets/negocios-categorias/ice-cream.png";
import kiosko from "../../assets/negocios-categorias/candy.png";
import cadeteria from "../../assets/negocios-categorias/scooter.png";

export const SectionsList = ({
  auth: { isAuthenticated },
  profile: { profile },
  sections: { loading, sections },
  getSections,
  getDeliveryAvailability,
  redirectToLocal,
  setInstallEvent,
  clearProductsGlobales,
  clearProducts,
  clearLocales,
  history,
}) => {
  // Google Analytics
  ReactGA.pageview("/sections-list");

  window.addEventListener("beforeinstallprompt", (e) => {
    e.preventDefault();

    setInstallEvent(e);
  });

  useEffect(() => {
    getSections();
    clearProductsGlobales();
    clearProducts();
    clearLocales();
  }, []);

  useEffect(() => {
    const getData = async () => {
      const available = await getDeliveryAvailability();
      setDeliveryAvailable(available.available);
    };
    if (isAuthenticated) {
      getData();
    }
  }, [isAuthenticated]);

  const [redirect, shouldRedirect] = useState(false);

  const [deliveryAvailable, setDeliveryAvailable] = useState(null);
  const [servicioSeleccionado, selectServicio] = useState(null);

  const redirectToNegocios = (category) => {
    history.push({
      pathname: "/negocios-list",
      state: {
        category: category,
      },
    });
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      partialVisibilityGutter: 40,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 2,
      partialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 20,
    },
  };

  const imgContainerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    backgroundColor: "white",
    padding: "0.8rem",
  };

  const imgStyle = {
    objectFit: "cover",
    width: "3.5rem",
    height: "auto",
  };

  const strongFontSize = {
    marginTop: "0.3rem",
    fontSize: "0.875em",
    fontWeight: "bold",
  };

  // Si no tiene una dirección lo redirijo para que cargue una
  if (profile !== null && !profile.addressess && !profile.actualAddress) {
    return <Redirect to="/add-address-from-map" />;
  }

  const content =
    loading || sections === null ? (
      <Spinner />
    ) : (
      <div>
        <MasPedidos history={history} />
        {sections
          .filter((section) => section.display === true)
          .map((section, j) => (
            <div key={j} className="my-3">
              <h2 className="my">{section.title}</h2>
              <Carousel
                responsive={responsive}
                removeArrowOnDeviceType={["mobile", "tablet"]}
                centerMode={false}
                partialVisible
                infinite={false}
                minimumTouchDrag={0}
              >
                {section.locales.map((local, i) => (
                  <NegocioCard
                    local={local}
                    deliveryAvailable={deliveryAvailable}
                    i={i}
                    history={history}
                  />
                ))}
              </Carousel>
            </div>
          ))}
      </div>
    );

  if (redirect) {
    history.push("/lista-productos-globales");
  }

  return (
    <div className="container">
      <ProductSearchBar
        clearSignal={() => {}}
        shouldRedirect={shouldRedirect}
      />
      <section className="my-1">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            onClick={() => redirectToNegocios("bar")}
          >
            <div style={imgContainerStyle}>
              <img src={bar} style={imgStyle} />
            </div>
            <p style={strongFontSize}>Restaurantes</p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            onClick={() => redirectToNegocios("kiosko")}
          >
            <div style={imgContainerStyle}>
              <img src={kiosko} style={imgStyle} />
            </div>
            <p style={strongFontSize}>Kioskos</p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            onClick={() => redirectToNegocios("heladeria")}
          >
            <div style={imgContainerStyle}>
              <img src={heladeria} style={imgStyle} />
            </div>
            <p style={strongFontSize}>Heladerías</p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            marginTop: "2rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            onClick={() => redirectToNegocios("supermercado")}
          >
            <div style={imgContainerStyle}>
              <img src={supermarket} style={imgStyle} />
            </div>
            <p style={strongFontSize}>Mercados</p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            onClick={() => redirectToNegocios("cadeteria")}
          >
            <div style={imgContainerStyle}>
              <img src={cadeteria} style={imgStyle} />
            </div>
            <p style={strongFontSize}>Cadetería</p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                padding: "0.8rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
                background: "white",
              }}
              onClick={() => redirectToNegocios("default")}
            >
              <i
                className="fa fa-ellipsis-h fa-2x"
                aria-hidden="true"
                style={{
                  width: "3.5rem",
                  height: "3.5rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              ></i>
            </div>
            <p style={strongFontSize}>Ver todos</p>
          </div>
        </div>
      </section>
      <section>
        <NewsComoponent />
      </section>
      {isAuthenticated && (
        <section style={{ marginTop: "3rem" }}>
          <PedidosActivosModal />
        </section>
      )}
      {content}
    </div>
  );
};

SectionsList.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  sections: PropTypes.object.isRequired,
  getSections: PropTypes.func.isRequired,
  getDeliveryAvailability: PropTypes.func.isRequired,
  redirectToLocal: PropTypes.func.isRequired,
  setInstallEvent: PropTypes.func.isRequired,
  clearProductsGlobales: PropTypes.func.isRequired,
  clearProducts: PropTypes.func.isRequired,
  clearLocales: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  sections: state.sections,
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, {
  getSections,
  redirectToLocal,
  getDeliveryAvailability,
  clearProductsGlobales,
  clearProducts,
  clearLocales,
  setInstallEvent,
})(SectionsList);

import React, { useState, useEffect, Fragment } from "react";
import shopPlaceHolder from "../../../assets/shop.png";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getSinglePedido,
  getRidersPedidosActivos,
} from "../../../actions/pedidos";
import Spinner from "../../layout/Spinner";
import HorarioLlegada from "./HorarioLlegada";

const TrackPedido = ({
  getSinglePedido,
  getRidersPedidosActivos,
  match,
  history,
}) => {
  const [pedido, setPedido] = useState(null);
  const [pedidoDate, setPedidoDate] = useState(null);
  const [horarioAceptado, setHorarioAceptado] = useState(null);
  const [horarioPreparado, setHorarioPreparado] = useState(null);
  const [horarioTomado, setHorarioTomado] = useState(null);
  const [horarioEnViaje, setHorarioEnViaje] = useState(null);
  const [horarioCompletado, setHorarioCompletado] = useState(null);
  const [cantPedidos, setCantPedidos] = useState(0);

  useEffect(() => {
    const fetchPedido = async () => {
      const pedido = await getSinglePedido(match.params.id);
      setPedido(pedido);
      setPedidoDate(new Date(pedido.date));
      setHorarioAceptado(new Date(pedido.horarioAceptado));
      setHorarioPreparado(new Date(pedido.horarioPreparado));
      setHorarioTomado(new Date(pedido.horarioTomado));
      setHorarioEnViaje(new Date(pedido.horarioEnViaje));
      setHorarioCompletado(new Date(pedido.horarioCompletado));
      if (pedido.delivery) {
        const riderPedidos = await getRidersPedidosActivos(pedido.delivery._id);
        setCantPedidos(riderPedidos);
      }
    };
    fetchPedido();
  }, []);

  const divContainerStyle = {
    padding: "1rem",
    borderRadius: "5px",
    backgroundColor: "white",
    boxShadow:
      "3px 3px 6px 0 rgba(0, 0, 0, 0.2), -3px 3px 6px 0 rgba(0, 0, 0, 0.2)",
  };

  const checkEstado = (step) => {
    const estadoActual = pedido.estado;
    switch (step) {
      case 1:
        return estadoActual === "En Espera" ? "current" : "is-done";
      case 2:
        return estadoActual === "En Preparación"
          ? "current"
          : estadoActual === "Preparado" ||
            estadoActual === "En Viaje" ||
            estadoActual === "Completado"
          ? "is-done"
          : "";
      case 3:
        return (pedido.local.cocina && estadoActual === "Preparado") ||
          (!pedido.local.cocina && estadoActual === "En Preparación")
          ? "current"
          : estadoActual === "completado"
          ? "is-done"
          : "";
      case 4:
        return (pedido.local.cocina &&
          estadoActual === "Preparado" &&
          !pedido.delivery) ||
          (!pedido.local.cocina &&
            estadoActual === "En Preparación" &&
            !pedido.delivery)
          ? "current"
          : pedido.delivery ||
            estadoActual === "En Viaje" ||
            estadoActual === "completado"
          ? "is-done"
          : "";
      case 5:
        return (pedido.local.cocina &&
          estadoActual === "Preparado" &&
          pedido.delivery) ||
          (!pedido.local.cocina &&
            estadoActual === "En Preparación" &&
            pedido.delivery)
          ? "current"
          : pedido.delivery ||
            estadoActual === "En Viaje" ||
            estadoActual === "completado"
          ? "is-done"
          : "";
      case 6:
        return estadoActual === "En Viaje"
          ? "current"
          : estadoActual === "Completado"
          ? "is-done"
          : "";
      default:
        return "";
    }
  };

  const getSubtotal = (productos) => {
    var sumatoria = 0;
    productos.forEach((producto) => {
      sumatoria += producto.cantidad * producto.precio;
      producto.extras.forEach((extraArr) => {
        extraArr.forEach((ex) => {
          sumatoria += ex.precio;
        });
      });
    });

    return sumatoria;
  };

  const content =
    pedido === null ||
    pedidoDate === null ||
    horarioAceptado === null ||
    horarioPreparado === null ||
    horarioTomado === null ||
    horarioEnViaje === null ||
    horarioCompletado === null ? (
      <Spinner />
    ) : (
      <div style={{ marginBottom: "5rem" }}>
        {/* Datos del local */}
        <div style={divContainerStyle}>
          <div
            className="my"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={
                  pedido.local.avatar.filename === ""
                    ? shopPlaceHolder
                    : pedido.local.avatar.filename
                }
                alt="negocio"
                className="rounded"
                style={{ width: "40px", height: "40px" }}
              />
              <h2 style={{ marginLeft: "5px" }}>{pedido.local.name}</h2>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <a
                href={`https://wa.me/54${pedido.local.tel}`}
                target="_blank"
                className="rounded"
                style={{ padding: "0.5rem", backgroundColor: "#ccc" }}
              >
                <i className="fab fa-whatsapp fa-lg"></i>
              </a>
              <a
                href={`tel:${pedido.local.tel}`}
                target="_blank"
                className="rounded"
                style={{
                  padding: "0.5rem",
                  backgroundColor: "#ccc",
                  marginLeft: "10px",
                }}
              >
                <i className="fas fa-phone"></i>
              </a>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "1rem",
            }}
          >
            <div
              style={{ borderRight: "2px solid black", paddingRight: "0.5rem" }}
            >
              {pedido.entrega === "takeAway" ? (
                <Fragment>
                  <p>Retiras el pedido en el local</p>
                </Fragment>
              ) : (
                <Fragment>
                  <p>{`${pedido.address.calle} ${pedido.address.numero}`}</p>
                  {pedido.address.piso && (
                    <p>{`Piso: ${pedido.address.piso}, Dpto.: ${pedido.address.depto}`}</p>
                  )}
                </Fragment>
              )}
            </div>
            <div style={{ paddingLeft: "0.5rem" }}>
              <p>{pedido.metodoPago}</p>
            </div>
          </div>
          <div>
            <HorarioLlegada pedido={pedido} cantPedidos={cantPedidos} />
          </div>
        </div>
        {/* Estado del pedido */}
        <div className="my-1" style={divContainerStyle}>
          <div className="my warning-container">
            <i className="fa fa-info fa-sm"></i>
            {`  `}
            El local no acepta tu pedido? Podes llamar o dejarles un mensaje.
          </div>

          <h3>Estado del pedido</h3>
          <div>
            <ul className="StepProgress">
              <li className="StepProgress-item is-done">
                <strong>El negocio recibió tu pedido</strong>
                <strong>{`${pedidoDate.getHours()}:${pedidoDate.getMinutes()} hs.`}</strong>
              </li>
              <li className={`StepProgress-item ${checkEstado(1)}`}>
                <strong>El negocio aceptó tu pedido</strong>
                {pedido.horarioAceptado !== undefined && (
                  <strong>{`${horarioAceptado.getHours()}:${horarioAceptado.getMinutes()} hs.`}</strong>
                )}
              </li>
              {pedido.local.cocina && (
                <Fragment>
                  <li className={`StepProgress-item ${checkEstado(2)}`}>
                    <strong>El negocio está preparando tu pedido</strong>
                    {pedido.horarioPreparado !== undefined && (
                      <strong>{`${horarioPreparado.getHours()}:${horarioPreparado.getMinutes()} hs.`}</strong>
                    )}
                  </li>
                </Fragment>
              )}
              {pedido.entrega === "takeAway" && (
                <li className={`StepProgress-item ${checkEstado(3)}`}>
                  <strong>Tu pedido está listo para retirar</strong>
                </li>
              )}
              {pedido.entrega !== "takeAway" && (
                <Fragment>
                  {!pedido.local.envio && (
                    <Fragment>
                      <li className={`StepProgress-item ${checkEstado(4)}`}>
                        <strong>Estamos buscando rider para tu pedido</strong>
                        {pedido.horarioTomado !== undefined && (
                          <strong>{`${horarioTomado.getHours()}:${horarioTomado.getMinutes()} hs.`}</strong>
                        )}
                      </li>
                      <li className={`StepProgress-item ${checkEstado(5)}`}>
                        <strong>El rider está yendo a buscar tu pedido</strong>
                        {pedido.horarioEnViaje !== undefined && (
                          <strong>{`${horarioEnViaje.getHours()}:${horarioEnViaje.getMinutes()} hs.`}</strong>
                        )}
                      </li>
                    </Fragment>
                  )}
                  <li className={`StepProgress-item ${checkEstado(6)}`}>
                    <strong>Tu pedido está en viaje</strong>
                    {pedido.horarioCompletado !== undefined && (
                      <strong>{`${horarioCompletado.getHours()}:${horarioCompletado.getMinutes()} hs.`}</strong>
                    )}
                  </li>
                </Fragment>
              )}
            </ul>
          </div>
        </div>
        {/* Detalle del pedido */}
        <div style={divContainerStyle}>
          <h3>Detalle del pedido</h3>
          {pedido.products.map((producto, i) => (
            <div key={i} style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <strong
                  style={{
                    float: "left",
                    width: "15%",
                    padding: "8px",
                  }}
                >
                  {`${producto.cantidad} X`}
                </strong>
                <strong
                  style={{
                    float: "left",
                    width: "60%",
                    padding: "8px",
                  }}
                >
                  {producto.producto.name}
                </strong>
                <strong
                  style={{
                    float: "left",
                    width: "25%",
                    padding: "8px",
                  }}
                >
                  {`$ ${producto.precio}`}
                </strong>
              </div>
            </div>
          ))}
          <div className="my" style={{ borderTop: "1px solid #0c120a" }} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <strong
              style={{
                float: "left",
                width: "75%",
                padding: "8px",
              }}
            >
              Subtotal
            </strong>
            <strong
              style={{
                float: "left",
                width: "25%",
                padding: "8px",
              }}
            >
              {`$ ${getSubtotal(pedido.products)}`}
            </strong>
          </div>
          {pedido.entrega !== "takeAway" && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <strong
                style={{
                  float: "left",
                  width: "75%",
                  padding: "8px",
                }}
              >
                Tarifa delivery
              </strong>
              <strong
                style={{
                  float: "left",
                  width: "25%",
                  padding: "8px",
                }}
              >
                {`$ ${pedido.tarifaDelivery}`}
              </strong>
            </div>
          )}
          <div className="my" style={{ borderTop: "1px solid #0c120a" }} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <strong
              style={{
                float: "left",
                width: "75%",
                padding: "8px",
              }}
            >
              Total
            </strong>
            <strong
              style={{
                float: "left",
                width: "25%",
                padding: "8px",
              }}
            >
              {`$ ${
                getSubtotal(pedido.products) +
                (pedido.entrega !== "takeAway" ? pedido.tarifaDelivery : 0)
              }`}
            </strong>
          </div>
        </div>
        {/* Rider */}
        {pedido.delivery && (
          <section
            style={{
              width: "100%",
              position: "fixed",
              bottom: "0",
              margin: "0 -1rem",
              padding: "0.5rem",
              backgroundColor: "#17d071",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={pedido.delivery.avatar.filename}
                  alt="Rider"
                  className="rounded"
                  style={{ width: "40px", height: "40px" }}
                />
                <strong style={{ marginLeft: "10px" }}>
                  {pedido.delivery.name}
                </strong>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <a
                  href={`https://wa.me/54${pedido.delivery.tel}`}
                  target="_blank"
                  className="rounded"
                  style={{ padding: "0.5rem", backgroundColor: "#ccc" }}
                >
                  <i className="fab fa-whatsapp fa-lg"></i>
                </a>
                <a
                  href={`tel:${pedido.delivery.tel}`}
                  target="_blank"
                  className="rounded"
                  style={{
                    padding: "0.5rem",
                    backgroundColor: "#ccc",
                    marginLeft: "10px",
                  }}
                >
                  <i className="fas fa-phone"></i>
                </a>
              </div>
            </div>
            {cantPedidos > 1 && (
              <small>{`${pedido.delivery.name} está llevando más de un pedido. Tardará un poco más en llegar a tu domicilio.`}</small>
            )}
          </section>
        )}
      </div>
    );

  return (
    <div className="container">
      <div
        className="btn-secondary"
        style={{ width: "3rem", marginBottom: "1rem" }}
        onClick={() =>
          history.goBack({
            prev: "/pedidos",
          })
        }
      >
        <i className="fas fa-arrow-left"></i>
      </div>
      {content}
    </div>
  );
};

TrackPedido.propTypes = {
  getSinglePedido: PropTypes.func.isRequired,
  getRidersPedidosActivos: PropTypes.func.isRequired,
};

export default connect(null, { getSinglePedido, getRidersPedidosActivos })(
  TrackPedido
);

import {
  SET_PRODUCTS,
  SET_LISTA_PRODUCTS,
  CLEAR_PRODUCTS,
  CLEAR_LISTA_PRODUCTS,
} from "./types";
import axios from "axios";
import ReactGA from "react-ga";
import { setAlert } from "./alert";

export const getProducts = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/local/products/${id}`);

    dispatch({
      type: SET_PRODUCTS,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

export const getProductsGlobales = (product) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/local/getProduct/${product}`);

    // Google Analytics
    ReactGA.event({
      category: "Productos",
      action: `producto buscado: ${product}`,
    });

    dispatch({
      type: SET_LISTA_PRODUCTS,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

export const clearProductsGlobales = () => async (dispatch) => {
  dispatch({
    type: CLEAR_LISTA_PRODUCTS,
  });
};

export const clearProducts = () => (dispatch) => {
  dispatch({
    type: CLEAR_PRODUCTS,
  });
};

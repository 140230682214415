import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getEnvio, createPrecioEnvio } from "../../actions/pedidos";
import { getTarifa, createTarifa } from "../../actions/cadeteria";
import { sendNotification } from "../../actions/users";
import { get_informe_local } from "../../actions/locales";
import { get_informe_rider } from "../../actions/riders";
import {
  createSection,
  getSections,
  changeVisibility,
  addLocalToSection,
  deleteLocalFromSection,
} from "../../actions/sections";

export const AdminPanel = ({
  profile: { profile },
  cadeteria: { tarifa },
  sections: { sections },
  getEnvio,
  getTarifa,
  createPrecioEnvio,
  createTarifa,
  sendNotification,
  get_informe_local,
  get_informe_rider,
  createSection,
  getSections,
  changeVisibility,
  addLocalToSection,
  deleteLocalFromSection,
  history,
}) => {
  useEffect(() => {
    async function fetchPrecioEnvio() {
      const precioEnvio = await getEnvio();
      setEnvio(precioEnvio);
    }
    fetchPrecioEnvio();
  }, []);
  useEffect(() => {
    getTarifa();
  }, []);

  useEffect(() => {
    getSections();
  }, [sections]);

  const [envio, setEnvio] = useState(null);
  const [displayEnvio, setDisplayEnvio] = useState(false);

  const [formData, setFormData] = useState({
    envioInput: "",
    cadeteriaInput: "",
    title: "",
    message: "",
    localId: "",
    riderId: "",
    starDate: "",
    endDate: "",
    titleSection: "",
    nuevoLocal: "",
  });
  const {
    envioInput,
    cadeteriaInput,
    title,
    message,
    localId,
    riderId,
    startDate,
    endDate,
    titleSection,
    nuevoLocal,
  } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (envioInput !== "") createPrecioEnvio(parseFloat(envioInput));
    if (cadeteriaInput !== "") createTarifa(parseFloat(cadeteriaInput));
    setFormData({
      envioInput: "",
      cadeteriaInput: "",
    });
  };

  const sendNoti = (e) => {
    e.preventDefault();

    sendNotification(title, message);
  };

  const obtenerInforme = async (e) => {
    e.preventDefault();

    const informe = await get_informe_local({ localId, startDate, endDate });
    console.log(informe);

    setFormData({
      ...formData,
      localId: "",
      startDate: "",
      endDate: "",
    });
  };

  const obtenerInformeRider = async (e) => {
    e.preventDefault();

    const informe = await get_informe_rider({ riderId, startDate, endDate });
    console.log(informe);

    setFormData({
      ...formData,
      riderId: "",
      startDate: "",
      endDate: "",
    });
  };

  const crearSeccion = (e) => {
    e.preventDefault();
    createSection(titleSection);
    setFormData({
      ...formData,
      titleSection: "",
    });
  };

  return (
    <div className="container">
      <h1>Panel del Admin</h1>
      {profile.admin ? (
        <div className="my-1">
          <section>
            <p>{`Precio actual delivery $${envio}`}</p>
            <p>{`Precio actual cadeteria $${tarifa}`}</p>
            <strong onClick={() => setDisplayEnvio(!displayEnvio)}>
              Cargar nuevo precio envío <i className="fas fa-arrow-down" />
            </strong>
            {displayEnvio && (
              <section>
                <form onSubmit={(e) => onSubmit(e)}>
                  <input
                    type="text"
                    name="envioInput"
                    value={envioInput}
                    onChange={(e) => onChange(e)}
                    placeholder="Envio"
                  />
                  <input
                    type="text"
                    name="cadeteriaInput"
                    value={cadeteriaInput}
                    onChange={(e) => onChange(e)}
                    placeholder="Cadeteria"
                  />
                  <input
                    type="submit"
                    className="btn btn-primary"
                    value="Guardar"
                  />
                </form>
              </section>
            )}
          </section>
          <section className="my-1">
            <strong>Enviar Notificaciones</strong>
            <form onSubmit={(e) => sendNoti(e)}>
              <input
                type="text"
                name="title"
                value={title}
                placeholder="titulo"
                onChange={(e) => onChange(e)}
              />
              <input
                type="text"
                name="message"
                value={message}
                placeholder="message"
                onChange={(e) => onChange(e)}
              />
              <input
                type="submit"
                value="Enviar notificacion"
                className="btn"
              />
            </form>
          </section>
          <section>
            <strong>Obtener informe de local</strong>
            <form onSubmit={(e) => obtenerInforme(e)}>
              <input
                type="text"
                name="localId"
                value={localId}
                placaholder="ID del local"
                onChange={(e) => onChange(e)}
              />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <input
                  type="date"
                  name="startDate"
                  value={startDate}
                  onChange={(e) => onChange(e)}
                />
                <input
                  type="date"
                  name="endDate"
                  value={endDate}
                  onChange={(e) => onChange(e)}
                />
              </div>
              <input type="submit" value="Obtener informe" className="btn" />
            </form>
          </section>
          <section className="my-1">
            <strong>Obtener informe del rider</strong>
            <form onSubmit={(e) => obtenerInformeRider(e)}>
              <input
                type="text"
                name="riderId"
                value={riderId}
                placaholder="ID del rider"
                onChange={(e) => onChange(e)}
              />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <input
                  type="datetime-local"
                  name="startDate"
                  value={startDate}
                  onChange={(e) => onChange(e)}
                />
                <input
                  type="datetime-local"
                  name="endDate"
                  value={endDate}
                  onChange={(e) => onChange(e)}
                />
              </div>
              <input type="submit" value="Obtener informe" className="btn" />
            </form>
          </section>
          <section className="my-1">
            <h2>Secciones</h2>
            <strong>Crear nueva sección</strong>
            <form onSubmit={(e) => crearSeccion(e)}>
              <input
                type="text"
                name="titleSection"
                value={titleSection}
                placeholder="titulo"
                onChange={(e) => onChange(e)}
              />
              <input type="submit" value="Crear sección" className="btn" />
            </form>
            <div className="my-1">
              {sections !== null &&
                sections.map((section, i) => (
                  <div
                    key={i}
                    className="my"
                    style={{ padding: "0.5rem", backgroundColor: "white" }}
                  >
                    <strong>{section.title}</strong>
                    <div style={{ display: "flex" }}>
                      <p>{`Visibilidad: ${section.display}`}</p>
                      <p
                        style={{ cursor: "pointer", marginLeft: "10px" }}
                        onClick={() => changeVisibility(section._id)}
                      >
                        cambiar visibilidad
                      </p>
                    </div>
                    {section.locales.map((local, j) => (
                      <div
                        key={j}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <p>{local.name}</p>
                        <i
                          className="fas fa-times"
                          style={{ marginLeft: "10px" }}
                          onClick={() =>
                            deleteLocalFromSection(section._id, local._id)
                          }
                        />
                      </div>
                    ))}
                    <input
                      type="text"
                      name="nuevoLocal"
                      value={nuevoLocal}
                      onChange={(e) => onChange(e)}
                      placeholder="local id"
                    />
                    <span
                      className="btn-secondary"
                      onClick={() => addLocalToSection(section._id, nuevoLocal)}
                    >
                      Añadir local
                    </span>
                  </div>
                ))}
            </div>
          </section>
          <div className="my-1">
            <span
              className="btn-secondary"
              onClick={() => history.push("/add-servicio")}
            >
              Añadir Servicio
            </span>
          </div>
          <div>
            <span
              className="btn-secondary"
              onClick={() => history.push("/news")}
            >
              Noticias
            </span>
          </div>
        </div>
      ) : (
        <p>No tenes permiso para estar acá</p>
      )}
    </div>
  );
};

AdminPanel.propTypes = {
  profile: PropTypes.object.isRequired,
  cadeteria: PropTypes.object.isRequired,
  getEnvio: PropTypes.func.isRequired,
  getTarifa: PropTypes.func.isRequired,
  createPrecioEnvio: PropTypes.func.isRequired,
  createTarifa: PropTypes.func.isRequired,
  sendNotification: PropTypes.func.isRequired,
  get_informe_local: PropTypes.func.isRequired,
  get_informe_rider: PropTypes.func.isRequired,
  createSection: PropTypes.func.isRequired,
  getSections: PropTypes.func.isRequired,
  changeVisibility: PropTypes.func.isRequired,
  addLocalToSection: PropTypes.func.isRequired,
  deleteLocalFromSection: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  cadeteria: state.cadeteria,
  sections: state.sections,
});

export default connect(mapStateToProps, {
  getEnvio,
  getTarifa,
  createPrecioEnvio,
  createTarifa,
  sendNotification,
  get_informe_local,
  get_informe_rider,
  createSection,
  getSections,
  changeVisibility,
  addLocalToSection,
  deleteLocalFromSection,
})(AdminPanel);

import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createServicio } from "../../actions/servicios";

export const AddServicio = ({ createServicio, history }) => {
  const [formData, setFormData] = useState({
    imagen: {
      avatar: "",
      src: "",
    },
    name: "",
    category: "",
    tel: "",
    address: "",
    mail: "",
    presentacion: "",
  });

  const { imagen, name, category, tel, address, mail, presentacion } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onImageChange = (e) => {
    setFormData({
      ...formData,
      imagen: {
        avatar: e.target.files[0],
        src: URL.createObjectURL(e.target.files[0]),
      },
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const data = {
      avatar: imagen.avatar,
      name: name,
      category: category,
      tel: tel,
      address: address,
      mail: mail,
      presentacion: presentacion,
    };

    createServicio(data, history);
  };

  return (
    <div className="container">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src={imagen.src}
          alt="Logo"
          style={{ width: "150px", height: "auto" }}
        />
        <label
          htmlFor="upload-button"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {
            <span className="my btn-secondary">
              <small style={{ marginLeft: "0.5rem" }}>
                Cambiar foto de portada
              </small>
            </span>
          }
        </label>
      </div>
      <form onSubmit={(e) => onSubmit(e)}>
        <input
          type="file"
          id="upload-button"
          accept="image/*"
          style={{ display: "none" }}
          onChange={(e) => onImageChange(e)}
        />
        <input
          type="text"
          name="name"
          value={name}
          onChange={(e) => onChange(e)}
          placeholder="Nombre"
          required
        />
        <input
          type="text"
          name="category"
          value={category}
          onChange={(e) => onChange(e)}
          placeholder="Categoría"
          required
        />
        <input
          type="text"
          name="tel"
          value={tel}
          onChange={(e) => onChange(e)}
          placeholder="Tel."
          required
        />
        <input
          type="text"
          name="address"
          value={address}
          onChange={(e) => onChange(e)}
          placeholder="Dirección"
          required
        />
        <input
          type="text"
          name="mail"
          value={mail}
          onChange={(e) => onChange(e)}
          placeholder="Mail"
          required
        />
        <textarea
          type="text"
          name="presentacion"
          value={presentacion}
          onChange={(e) => onChange(e)}
          placeholder="Presentación"
          required
        />
        <input type="submit" value="Enviar" className="btn" />
      </form>
    </div>
  );
};

AddServicio.propTypes = {
  createServicio: PropTypes.func.isRequired,
};

export default connect(null, { createServicio })(AddServicio);

import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// Carousel
import ItemsCarousel from "react-items-carousel";

const CategoriesSelector = ({ categories, categsRef }) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  const handleScroll = (ref) => {
    window.scrollTo({
      top: ref.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      style={{
        display: categories.length > 0 ? "" : "none",
        backgroundColor: "white",
        width: "100%",
        marginBottom: "1rem",
        position: "sticky",
        top: "2.9rem",
        zIndex: 100,
      }}
    >
      <div style={{ padding: "0.5rem 1rem" }}>
        <ItemsCarousel
          showSlither={true}
          numberOfCards={2}
          gutter={20}
          activePosition="left"
          disableSwipe={false}
          activeItemIndex={activeItemIndex}
          requestToChangeActive={setActiveItemIndex}
          classes={{ itemWrapper: "wrapper" }}
        >
          {categories.map(
            (cat, i) =>
              cat.active && (
                <div
                  key={i}
                  onClick={() => handleScroll(categsRef.current[i])}
                  style={{ textAlign: "center" }}
                >
                  <small style={{ fontWeight: "bold", userSelect: "none" }}>
                    {cat.name}
                  </small>
                </div>
              )
          )}
        </ItemsCarousel>
      </div>
    </div>
  );
};

CategoriesSelector.propTypes = {};

export default connect(null, {})(CategoriesSelector);

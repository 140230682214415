import {
  SET_PRODUCTS,
  SET_LISTA_PRODUCTS,
  CLEAR_PRODUCTS,
  CLEAR_LISTA_PRODUCTS,
} from "../actions/types";

const initialState = {
  products: null,
  listaProducts: null,
  loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_PRODUCTS:
      return {
        ...state,
        products: payload,
        loading: false,
      };
    case SET_LISTA_PRODUCTS:
      return {
        ...state,
        listaProducts: payload,
        loading: false,
      };
    case CLEAR_PRODUCTS:
      return {
        ...state,
        products: null,
        loading: true,
      };
    case CLEAR_LISTA_PRODUCTS:
      return {
        ...state,
        listaProducts: null,
        loading: true,
      };
    default:
      return state;
  }
}

import React, { useState } from "react";

const Stars = ({ index }) => {
  //Stars
  const [starIndex, setStarIndex] = useState(-1);
  index(starIndex);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <i
        className={`${0 <= starIndex ? "fas" : "far"} fa-star fa-2x`}
        style={0 <= starIndex ? { color: "#ffff00" } : {}}
        onClick={() => setStarIndex(0)}
      />

      <i
        className={`${1 <= starIndex ? "fas" : "far"} fa-star fa-2x`}
        style={1 <= starIndex ? { color: "#ffff00" } : {}}
        onClick={() => setStarIndex(1)}
      />

      <i
        className={`${2 <= starIndex ? "fas" : "far"} fa-star fa-2x`}
        style={2 <= starIndex ? { color: "#ffff00" } : {}}
        onClick={() => setStarIndex(2)}
      />

      <i
        className={`${3 <= starIndex ? "fas" : "far"} fa-star fa-2x`}
        style={3 <= starIndex ? { color: "#ffff00" } : {}}
        onClick={() => setStarIndex(3)}
      />

      <i
        className={`${4 <= starIndex ? "fas" : "far"} fa-star fa-2x`}
        style={4 <= starIndex ? { color: "#ffff00" } : {}}
        onClick={() => setStarIndex(4)}
      />
    </div>
  );
};

export default Stars;

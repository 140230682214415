import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getProductsGlobales } from "../../actions/products";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import hamburger from "../../assets/productos-categorias/hamburguesas-01.png";
import pizzas from "../../assets/productos-categorias/pizzas-01.png";
import empanadas from "../../assets/productos-categorias/empanadas-01.png";
import sanguches from "../../assets/productos-categorias/sanguches-01.png";
import mila from "../../assets/productos-categorias/milanesas-01.png";
import panchos from "../../assets/productos-categorias/panchos-01.png";
import papas from "../../assets/productos-categorias/fritas-01.png";

const CarouselProducts = ({ getProductsGlobales, clearSignal }) => {
  const [prodSelected, setProdSelected] = useState("");

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      partialVisibilityGutter: 40,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 2,
      partialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      partialVisibilityGutter: 10,
    },
  };

  const itemSelected = {
    border: "3px solid rgb(23, 208, 112)",
    borderRadius: "8px",
    width: "145px",
    height: "auto",
  };

  const itemNotSelected = {
    border: "none",
    width: "145px",
    height: "auto",
  };

  const setItem = (item) => {
    clearSignal(true);
    clearSignal(false);
    setProdSelected(item);
    if (item === "burger") getProductsGlobales("burg");
    if (item === "pizzas") getProductsGlobales("pizza");
    if (item === "empanadas") getProductsGlobales("empanada");
    if (item === "sanguches") {
      getProductsGlobales("sanguche");
      getProductsGlobales("sandwich");
      getProductsGlobales("triple");
      getProductsGlobales("carlito");
      getProductsGlobales("tostado");
      getProductsGlobales("lomito");
    }
    if (item === "milanesas") getProductsGlobales("mila");
    if (item === "panchos") {
      getProductsGlobales("pancho");
      getProductsGlobales("hot dog");
    }
    if (item === "fritas") getProductsGlobales("fritas");
  };

  return (
    <Fragment>
      <Carousel
        responsive={responsive}
        removeArrowOnDeviceType={["mobile", "tablet"]}
        centerMode={false}
        partialVisible
        infinite={true}
        minimumTouchDrag={0}
      >
        <div onClick={() => setItem("burger")}>
          <img
            src={hamburger}
            alt="hamburguesas"
            style={prodSelected === "burger" ? itemSelected : itemNotSelected}
          />
        </div>
        <div onClick={() => setItem("pizzas")}>
          <img
            src={pizzas}
            alt="pizzas"
            style={prodSelected === "pizzas" ? itemSelected : itemNotSelected}
          />
        </div>
        <div onClick={() => setItem("empanadas")}>
          <img
            src={empanadas}
            alt="empanadas"
            style={
              prodSelected === "empanadas" ? itemSelected : itemNotSelected
            }
          />
        </div>
        <div onClick={() => setItem("sanguches")}>
          <img
            src={sanguches}
            alt="sanguches"
            style={
              prodSelected === "sanguches" ? itemSelected : itemNotSelected
            }
          />
        </div>
        <div onClick={() => setItem("milanesas")}>
          <img
            src={mila}
            alt="milanesas"
            style={
              prodSelected === "milanesas" ? itemSelected : itemNotSelected
            }
          />
        </div>
        <div onClick={() => setItem("panchos")}>
          <img
            src={panchos}
            alt="panchos"
            style={prodSelected === "panchos" ? itemSelected : itemNotSelected}
          />
        </div>
        <div onClick={() => setItem("fritas")}>
          <img
            src={papas}
            alt="papas fritas"
            style={prodSelected === "fritas" ? itemSelected : itemNotSelected}
          />
        </div>
      </Carousel>
    </Fragment>
  );
};

CarouselProducts.propTypes = {
  getProductsGlobales: PropTypes.func.isRequired,
};

export default connect(null, { getProductsGlobales })(CarouselProducts);

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { openSidePanel } from "../../actions/auth";
import icono from "../../assets/icono64.png";
import iconoLetras from "../../assets/andiamo solo palabra.png";

const Navbar = ({ openSidePanel, auth: { isAuthenticated, loading } }) => {
  const content =
    loading || !isAuthenticated ? (
      /*(
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "1.3rem",
        }}
      >
        <Link
          to="/"
          style={{
            textDecoration: "none",
            color: "#2b292d",
            marginRight: "1rem",
          }}
        >
          <strong>Iniciar Sesión</strong>
        </Link>
        <Link
          to="/register"
          style={{
            textDecoration: "none",
            color: "#2b292d",
            marginRight: "1rem",
          }}
        >
          <strong>Registrarme</strong>
        </Link>
      </div>
        
    )*/
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <img
          src={iconoLetras}
          alt="Andiamo"
          style={{ height: "40px", width: "auto" }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Link to="/">
            <i
              className="fas fa-store fa-lg"
              style={{ position: "relative", marginRight: "1.5rem" }}
            ></i>
          </Link>
          <i className="fas fa-bars fa-lg" onClick={() => openSidePanel()}></i>
        </div>
      </div>
    ) : (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <img
          src={iconoLetras}
          alt="Andiamo"
          style={{ height: "40px", width: "auto" }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Link to="/">
            <i
              className="fas fa-store fa-lg"
              style={{ position: "relative", marginRight: "1.5rem" }}
            ></i>
          </Link>
          <i className="fas fa-bars fa-lg" onClick={() => openSidePanel()}></i>
        </div>
      </div>
    );
  return <div className="navbar">{content}</div>;
};

Navbar.propTypes = {
  openSidePanel: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { openSidePanel })(Navbar);

import React, { useState } from "react";

const EditPhone = ({ history }) => {
  const [tel, setTel] = useState("");

  return (
    <div className="container">
      <div
        className="btn-secondary"
        style={{ width: "3rem", marginBottom: "1rem" }}
        onClick={() => history.goBack()}
      >
        <i className="fas fa-arrow-left"></i>
      </div>
      <div>
        <h1>Cambiar número de teléfono</h1>
      </div>
      <div className="my-2"></div>
    </div>
  );
};

export default EditPhone;

import React, { useEffect } from "react";
// Carousel-----------------------------------------
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// -------------------------------------------------
import LazyLoad from "react-lazyload";
//import { toast } from "react-toastify";

const ProductosDestacados = ({
	productosDestacados,
	minusProduct,
	plusProduct,
	setProductoConExtras,
	setExtrasDelProducto,
	setOpenModal,
	getIndex,
	responsive,
	ofertas,
	productosSeleccionados,
}) => {
	const addUnit = (product) => {
		if (product.extras.length === 0) {
			plusProduct(product.producto, product.precio, []);
		} else {
			setProductoConExtras(product);
			setExtrasDelProducto(product);
			setOpenModal(true);
		}
	};

	/*useEffect(() => {
    if (
      product.stock &&
      productosSeleccionados[getIndex(product.producto._id)]?.cantidad ===
        product.stock
    ) {
      toast.warn("No tenemos más unidades de este producto");
    }
  }, [minusProduct, addUnit]);*/

	const minusPlusButtons = (product) => (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				padding: "0.2rem",
			}}
		>
			<button
				className="btn-rounded btn-rounded-small"
				style={{
					marginRight: "10px",
				}}
				onClick={() => minusProduct(product.producto, product.precio)}
			>
				<i className="fa fa-minus fa-xs"></i>
			</button>
			{productosSeleccionados[getIndex(product.producto._id)] !== undefined
				? productosSeleccionados[getIndex(product.producto._id)].cantidad
				: 0}
			<button
				className="btn-rounded btn-rounded-small"
				disabled={
					productosSeleccionados[getIndex(product.producto._id)] !==
						undefined &&
					productosSeleccionados[getIndex(product.producto._id)].cantidad ===
						product.stock
				}
				onClick={() => addUnit(product)}
				style={{
					marginLeft: "10px",
				}}
			>
				<i className="fa fa-plus fa-xs"></i>
			</button>
		</div>
	);

	const notAvailable = (
		<div className="my" style={{ textAlign: "center" }}>
			<strong>SIN STOCK</strong>
		</div>
	);

	return (
		<>
			<div style={{ padding: "0 1rem" }}>
				<h3 className="my-1">Productos Destacados</h3>
				<div style={{ display: "flex", flexDirection: "column" }}>
					<Carousel
						responsive={responsive}
						removeArrowOnDeviceType={["mobile", "tablet"]}
						centerMode={false}
						partialVisible
						infinite={false}
						minimumTouchDrag={0}
					>
						{productosDestacados.map((product, i) => (
							<div
								key={i}
								style={{
									flex: "1",
									height: "100%",
									backgroundColor: "white",
									marginRight: "10px",
									boxShadow:
										"3px 3px 6px 0 rgba(0, 0, 0, 0.2), -3px 3px 6px 0 rgba(0, 0, 0, 0.2)",
									borderRadius: "5px",
									opacity: product.disponible ? undefined : 0.5,
								}}
							>
								<LazyLoad height={"auto"} once>
									<img
										src={product.producto.image.filename}
										style={{
											width: "100%",
											height: "100px",
											objectFit: "cover",
											borderRadius: "5px",
										}}
									/>
								</LazyLoad>
								<div
									style={{
										padding: "0.2rem",
										display: "flex",
										flexDirection: "column",
									}}
								>
									<h4>{product.producto.name}</h4>
									<p style={{ marginTop: "5px" }}>{`$ ${product.precio}`}</p>
									{ofertas !== null &&
										ofertas
											.map((item) => item.product._id)
											.indexOf(product.producto._id) !== -1 && (
											<small
												style={{
													backgroundColor: "rgb(23, 208, 112)",
													color: "white",
													padding: "0.2rem",
													borderRadius: "25px",
													margin: "0",
													marginTop: "5px",
													width: "fit-content",
												}}
											>
												Oferta
											</small>
										)}
								</div>
								{product.disponible ? minusPlusButtons(product) : notAvailable}
							</div>
						))}
					</Carousel>
				</div>
			</div>
		</>
	);
};

export default ProductosDestacados;

import React from "react";

const PrivacyPolicy = () => {
	return (
		<div className="container">
			<h1>Declaración de Política de Privacidad de Andiamo</h1>
			<p>
				En Andiamo, valoramos profundamente su privacidad y nos comprometemos a
				proteger la información personal que usted nos proporciona. Esta
				Declaración de Política de Privacidad describe cómo recopilamos, usamos
				y compartimos su información cuando utiliza nuestra aplicación móvil,
				Andiamo, y los servicios relacionados (en adelante, "Servicios"). Al
				utilizar nuestros Servicios, usted acepta la recopilación y el uso de su
				información personal según lo establecido en esta política. Le
				recomendamos que lea detenidamente esta política para entender cómo
				manejamos su información.
			</p>
			<h2>1. Información que recopilamos:</h2>
			<ul>
				<li>
					<strong>Información que usted nos proporciona:</strong> Cuando usted
					crea una cuenta en Andiamo, nos proporciona información personal como
					su nombre, dirección de correo electrónico, número de teléfono y
					dirección de entrega.
				</li>
				<li>
					<strong>Información de la transacción:</strong> Recopilamos
					información sobre las transacciones que realiza a través de nuestra
					aplicación, como los productos que compra, la cantidad pagada y la
					información de pago.
				</li>
				<li>
					<strong>Información de uso:</strong> Recopilamos información sobre
					cómo utiliza nuestra aplicación, incluidos los productos que ve, los
					tiempos de entrega seleccionados y las interacciones con la
					aplicación.
				</li>
			</ul>
			<h2>2. Cómo utilizamos su información:</h2>
			<p>
				Utilizamos la información que recopilamos para proporcionarle nuestros
				Servicios, procesar sus pedidos, comunicarnos con usted, personalizar su
				experiencia y mejorar nuestros servicios. Utilizamos su información para
				fines de marketing y publicidad, como enviarle correos electrónicos
				promocionales sobre ofertas especiales y nuevos servicios, siempre y
				cuando usted haya dado su consentimiento para recibir dichas
				comunicaciones.
			</p>
			<h2>3. Cómo compartimos su información:</h2>
			<p>
				Compartimos su información personal con terceros que prestan servicios
				en nuestro nombre, como procesadores de pagos y empresas de entrega,
				pero solo en la medida necesaria para llevar a cabo estas funciones.
				Podemos compartir información no identificable personalmente de manera
				agregada para fines de análisis y marketing.
			</p>
			<h2>4. Seguridad de la información:</h2>
			<p>
				Nos comprometemos a proteger su información personal y utilizamos
				medidas de seguridad razonables para protegerla contra accesos no
				autorizados, divulgación o destrucción.
			</p>
			<h2>5. Sus opciones:</h2>
			<p>
				Usted tiene la opción de optar por no recibir comunicaciones de
				marketing por correo electrónico en cualquier momento. Puede acceder y
				actualizar la información de su cuenta en cualquier momento a través de
				la configuración de la aplicación.
			</p>
			<h2>6. Menores de edad:</h2>
			<p>
				Nuestros Servicios no están dirigidos a menores de 18 años y no
				recopilamos intencionalmente información personal de niños menores de
				esa edad.
			</p>
			<h2>7. Cambios en esta política:</h2>
			<p>
				Nos reservamos el derecho de modificar esta Política de Privacidad en
				cualquier momento. Le notificaremos cualquier cambio significativo
				mediante una notificación en nuestra aplicación o por otros medios antes
				de que el cambio entre en vigor.
			</p>
			<h2>8. Contacto:</h2>
			<p>
				Si tiene alguna pregunta o inquietud sobre nuestra Política de
				Privacidad o el manejo de su información personal, no dude en ponerse en
				contacto con nosotros a través de andiamoempresa@gmail.com.
			</p>
			<p>
				Al utilizar nuestros Servicios, usted acepta los términos de esta
				Política de Privacidad. Si no está de acuerdo con esta política, por
				favor no utilice nuestra aplicación ni nuestros Servicios.
			</p>
		</div>
	);
};

export default PrivacyPolicy;

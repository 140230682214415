import React, { useEffect, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getMasPedidos } from "../../actions/pedidos";
import { redirectToLocal } from "../../actions/locales";
import ReactGA from "react-ga";
// Carousel
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const MasPedidos = ({
  getMasPedidos,
  redirectToLocal,
  pedidos: { masPedidos },
  history,
}) => {
  useEffect(() => {
    getMasPedidos();
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      partialVisibilityGutter: 40,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 2,
      partialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      partialVisibilityGutter: 10,
    },
  };

  const content = masPedidos !== null && masPedidos.length > 0 && (
    <div>
      <h2 className="my">Los más pedidos</h2>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Carousel
          responsive={responsive}
          removeArrowOnDeviceType={["mobile", "tablet"]}
          centerMode={false}
          partialVisible
          infinite={false}
          minimumTouchDrag={0}
        >
          {masPedidos.map((prod, i) => (
            <div
              key={i}
              style={{
                flex: "1",
                height: "100%",
                marginRight: "20px",
                borderRadius: "5px",
                backgroundColor: "white",
                boxShadow:
                  "3px 3px 6px 0 rgba(0, 0, 0, 0.2), -3px 3px 6px 0 rgba(0, 0, 0, 0.2)",
              }}
              onClick={() => {
                ReactGA.event({
                  category: "Productos",
                  action: "click en prod. mas pedidos",
                });
                redirectToLocal(prod[1].local._id, prod[1].name, history);
              }}
            >
              <img
                src={prod[1].image}
                alt="Producto"
                style={{
                  display: "block",
                  width: "100%",
                  height: "150px",
                  objectFit: "cover",
                  margin: "0",
                  padding: "0",
                  borderRadius: "5px",
                }}
              />
              <div
                style={{
                  padding: "0.5rem",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h3>{prod[1].name}</h3>
                <p>{prod[1].local.name}</p>
                <small style={{ marginTop: "1rem", color: "#c1c1c1" }}>
                  <i className="fas fa-concierge-bell" /> {prod[1].cantidad}{" "}
                  veces pedido
                </small>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );

  return <div style={{ marginTop: "3rem" }}>{content}</div>;
};

MasPedidos.propTypes = {
  pedidos: PropTypes.object.isRequired,
  getMasPedidos: PropTypes.func.isRequired,
  redirectToLocal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  pedidos: state.pedidos,
});

export default connect(mapStateToProps, { getMasPedidos, redirectToLocal })(
  MasPedidos
);

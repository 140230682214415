import React, { Fragment, useState } from "react";
import Spinner from "../layout/Spinner";
import shopPlaceholder from "../../assets/shop.png";
import ReactGA from "react-ga";
import Stars from "../layout/Stars";

// Modal
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const PedidosEntregadosList = ({ pedidos, loading, votar }) => {
  ReactGA.pageview("/pedidos-completados");

  const [verDetalle, setVerDetalle] = useState(-1);

  const mostrar = (i) => {
    setVerDetalle(i);
  };
  const ocultar = () => {
    setVerDetalle(-1);
  };

  const hayPedidos = () => {
    if (
      pedidos.filter((pedido) => {
        return (
          pedido.estado === "Entregado" ||
          pedido.estado === "Devuelto" ||
          pedido.estado === "Completado" ||
          pedido.estado === "Rechazado"
        );
      }).length !== 0
    ) {
      return true;
    }

    return false;
  };

  const getFormattedDate = (pedido) => {
    const pedidoDate = new Date(pedido.date);
    const hoursStr = pedidoDate.getHours().toString().padStart(2, "0");
    const minutesStr = pedidoDate.getMinutes().toString().padStart(2, "0");

    const strDate =
      pedidoDate.getDate() +
      "/" +
      (pedidoDate.getMonth() + 1) +
      "/" +
      pedidoDate.getFullYear() +
      ", " +
      hoursStr +
      ":" +
      minutesStr;

    return strDate;
  };

  //Modal
  const [openModal, setOpenModal] = useState(false);

  //Categorias a rankear
  const [tiempoEntregaStars, setTiempoEntregaStars] = useState(-1);
  const [estadoPedido, setEstadoPedido] = useState(-1);
  const [calidadPedido, setCalidadPedido] = useState(-1);

  //Pedido seleccionado
  const [pedidoId, setPedidoId] = useState(null);
  //Local seleccionado
  const [localId, setLocalId] = useState(null);

  //Votar
  const botonVotar = () => {
    votar(pedidoId, localId, {
      tiempoEntregaStars,
      estadoPedido,
      calidadPedido,
    });
  };

  const content =
    loading || pedidos === null ? (
      <Spinner />
    ) : !hayPedidos() ? (
      <h3>No tenes pedidos</h3>
    ) : (
      <div>
        {pedidos
          .filter((pedido) => {
            return (
              pedido.estado === "Entregado" ||
              pedido.estado === "Devuelto" ||
              pedido.estado === "Completado" ||
              pedido.estado === "Rechazado"
            );
          })
          .map((pedido, i) => (
            <div
              className="my-1"
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "white",
                borderRadius: "5px",
                padding: "1rem",
              }}
              key={i}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={
                    pedido.local.avatar.filename
                      ? pedido.local.avatar.filename
                      : shopPlaceholder
                  }
                  alt="Local"
                  className="rounded"
                  style={{ width: "50px", height: "50px" }}
                />
                <div style={{ marginLeft: "15px" }}>
                  <h3>{pedido.local.name}</h3>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <p>{getFormattedDate(pedido)}</p>
                    <strong style={{ marginLeft: "10px" }}>|</strong>
                    <p style={{ marginLeft: "10px" }}>{pedido.metodoPago}</p>
                  </div>
                </div>
              </div>
              <div>
                {verDetalle === i ? (
                  <div
                    className="my"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    {pedido.products.map((producto, index) => (
                      <div
                        key={index}
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <div style={{ display: "flex" }}>
                          <p
                            style={{ padding: "8px" }}
                          >{`${producto.cantidad} X`}</p>
                          <p style={{ padding: "8px" }}>
                            {producto.producto.name}
                          </p>
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <small
                            style={{
                              paddingLeft: "10px",
                              color: "rgb(200, 200, 200)",
                            }}
                          >
                            Extras
                          </small>
                          {producto.extras.map((extraArr, j) => (
                            <div
                              key={j}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                paddingBottom: "10px",
                              }}
                            >
                              {extraArr.map((ex, t) => (
                                <div key={t}>
                                  <p
                                    style={{
                                      float: "left",
                                      width: "80%",
                                      paddingLeft: "10px",
                                    }}
                                  >
                                    {ex.name}
                                  </p>
                                </div>
                              ))}
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                    <p
                      style={{
                        color: "rgb(200, 200, 200)",
                      }}
                      onClick={() => ocultar()}
                    >
                      Ocultar Detalle <i className="fas fa-arrow-up" />
                    </p>
                  </div>
                ) : (
                  <p
                    className="my"
                    style={{ color: "rgb(200, 200, 200)" }}
                    onClick={() => mostrar(i)}
                  >
                    Ver Detalle <i className="fas fa-arrow-down"></i>
                  </p>
                )}
              </div>
              <div
                className="my-1"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <small style={{ color: "rgb(200, 200, 200)" }}>Estado</small>
                <strong>{pedido.estado}</strong>
              </div>
              {pedido.hasOwnProperty("comentarioLocal") && (
                <div>
                  <small style={{ color: "rgb(200, 200, 200)" }}>
                    Razones de rechazo del pedido
                  </small>
                  <p>{pedido.comentarioLocal}</p>
                </div>
              )}
              <div>
                <small style={{ color: "rgb(200, 200, 200)" }}>Notas</small>
                <p>{pedido.notas}</p>
              </div>
              {pedido.delivery ? (
                <div
                  className="my"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <small
                    style={{
                      color: "rgb(200, 200, 200)",
                      marginBottom: "5px",
                    }}
                  >
                    Repartidor
                  </small>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "white",
                    }}
                  >
                    <img
                      src={pedido.delivery.avatar.filename}
                      alt="Repartidor"
                      style={{
                        width: "80px",
                        height: "80px",
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                    />
                    <strong style={{ marginLeft: "15px" }}>
                      {pedido.delivery.name}
                    </strong>
                  </div>
                </div>
              ) : null}
              <div className="my">
                {pedido.estado === "Completado" && !pedido.rankeado ? (
                  <p
                    style={{ color: "#0000EE" }}
                    onClick={() => {
                      setOpenModal(true);
                      setPedidoId(pedido._id);
                      setLocalId(pedido.local._id);
                    }}
                  >
                    ¿Cómo estuvo el pedido?
                  </p>
                ) : null}
              </div>
            </div>
          ))}
      </div>
    );

  return (
    <Fragment>
      {content}
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setPedidoId(null);
          setLocalId(null);
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h1>Contanos tu experiencia</h1>
          <p>
            Para ayudar a otros compradores calificá al local dandole de 1 a 5
            estrellas en cada categoría.
          </p>
          <div className="my-2">
            <h3>Tiempo de entrega del pedido</h3>
            <Stars index={setTiempoEntregaStars} />
          </div>
          <div>
            <h3>Calificá al local</h3>
            <Stars index={setEstadoPedido} />
          </div>
          <div className="my-2">
            <h3>El producto fue lo que esperabas</h3>
            <Stars index={setCalidadPedido} />
          </div>
          <div>
            <strong>Comunicate con nosotros</strong>
            <p>
              <i className="far fa-envelope" style={{ marginRight: "5px" }} />
              andiamoempresa@gmail.com
            </p>
          </div>
          <div
            className="my-1"
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            {tiempoEntregaStars !== -1 &&
            estadoPedido !== -1 &&
            calidadPedido !== -1 ? (
              <button
                className="btn"
                style={{ width: "100%" }}
                onClick={() => {
                  botonVotar();
                  setOpenModal(false);
                  setPedidoId(null);
                  setLocalId(null);
                }}
              >
                Votar!
              </button>
            ) : null}
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default PedidosEntregadosList;

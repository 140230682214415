import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const CreateProfileRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { profile } = useSelector((state) => state.profile);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated && profile === null ? (
          <Redirect to="/create-profile" />
        ) : (
          <Fragment>
            <Component {...props} />
          </Fragment>
        )
      }
    />
  );
};

export default CreateProfileRoute;

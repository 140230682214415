import { SET_PEDIDOS, CLEAR_PEDIDOS, SET_MAS_PEDIDOS } from "../actions/types";

const initialState = {
  pedidos: null,
  masPedidos: null,
  loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_PEDIDOS:
      return {
        ...state,
        pedidos: payload,
        loading: false,
      };
    case CLEAR_PEDIDOS:
      return {
        ...state,
        pedidos: null,
        loading: true,
      };
    case SET_MAS_PEDIDOS:
      return {
        ...state,
        masPedidos: payload,
        loading: false,
      };
    default:
      return state;
  }
}

import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getPedidosActivos } from "../../../actions/pedidos";
import { useHistory } from "react-router-dom";

const PedidosActivosModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [pedidos, setPedidos] = useState([]);
  const [index, setIndex] = useState(0);
  const [horarioLlegada, setHorarioLlegada] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const pedidos = await dispatch(getPedidosActivos());
      if (pedidos[0] && pedidos[0].horarioLlegada) {
        setHorarioLlegada(new Date(pedidos[0].horarioLlegada));
      }
      setPedidos(pedidos);
    };
    fetch();
  }, []);

  useEffect(() => {
    if (pedidos[index] && pedidos[index].horarioLlegada) {
      setHorarioLlegada(new Date(pedidos[index].horarioLlegada));
    }
  }, [index]);

  const displayOrderInfo = () => {
    const pedido = pedidos[index];
    if (pedido.estado === "En Preparación" || pedido.estado === "Preparado") {
      return `${pedido.local.name} está preparando tu pedido`;
    }
    if (pedido.estado === "En Preparación" && pedido.delivery) {
      return `El repartidor está en ${pedido.local.name} esperando tu pedido`;
    }
    if (pedido.estado === "Preparado" && pedidos.delivery) {
      return `El repartidor está en ${pedido.local.name} esperando tu pedido`;
    }
    if (pedido.estado === "Preparado" && pedido.entrega === "takeAway") {
      return `Tu pedido ${pedido.local.name} esperando tu pedido`;
    }
    if (pedido.estado === "En Viaje") {
      return "El repartidor está en viaje con tu pedido";
    }
  };

  const displayOrderDetails = () => {
    const pedido = pedidos[index];
    if (pedido.local.envio) {
      if (pedido.entrega === "takeAway") {
        if (horarioLlegada && horarioLlegada !== null) {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "0.5rem",
              }}
            >
              <i className="fa fa-clock fa-sm"></i>
              <small style={{ marginTop: "0.5rem" }}>
                {`Podes retirar tu pedido a partir de las `}
                {String(horarioLlegada.getHours()).padStart(2, "0")}:
                {String(horarioLlegada.getMinutes()).padStart(2, "0")} hs.
              </small>
            </div>
          );
        }
      } else {
        return (
          <small style={{ marginTop: "0.5rem" }}>
            Entrega a cargo del negocio
          </small>
        );
      }
    } else {
      if (horarioLlegada && horarioLlegada !== null) {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "0.5rem",
            }}
          >
            <i className="fa fa-clock fa-sm"></i>
            <small style={{ marginLeft: "0.5rem" }}>
              {pedido.entrega === "takeAway"
                ? `Podes retirar tu pedido a partir de las `
                : `Horario de llegada aproximado: `}
              {String(horarioLlegada.getHours()).padStart(2, "0")}:
              {String(horarioLlegada.getMinutes()).padStart(2, "0")} hs.
            </small>
          </div>
        );
      }
    }
  };

  if (pedidos.length === 0) {
    return null;
  }

  return (
    <Fragment>
      <h2 className="my">Pedidos en Curso</h2>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "1rem",
          backgroundColor: "#FFFDD0",
          borderRadius: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
          onClick={() => history.push(`/track-pedido/${pedidos[index]._id}`)}
        >
          <small>{pedidos[index].local.name}</small>
          <strong>{displayOrderInfo()}</strong>
          {displayOrderDetails()}
        </div>
        <div
          className="my"
          style={{ width: "100%", borderBottom: "1px solid black" }}
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <small
            style={{
              padding: "0.5rem",
              border: "1px solid black",
              borderRadius: "7px",
            }}
            onClick={() => history.push(`/track-pedido/${pedidos[index]._id}`)}
          >
            Ver Detalle
          </small>
          <div style={{ display: "flex", alignItems: "center" }}>
            <strong
              onClick={() => index > 0 && setIndex(index - 1)}
            >{`<`}</strong>
            <p>{`${index + 1}/${pedidos.length}`}</p>
            <strong
              onClick={() => index < pedidos.length - 1 && setIndex(index + 1)}
            >{`>`}</strong>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PedidosActivosModal;

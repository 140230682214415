import axios from "axios";
import { setAlert } from "./alert";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  OPEN_SIDEPANEL,
  CLOSE_SIDEPANEL,
} from "./types";
import setAuthToken from "../utils/setAuthToken";
import { clearCliente, loadClient } from "./users";
import ReactGA from "react-ga";

// Load User
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get("/api/auth");

    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Register User
export const register =
  ({ email, password }, history) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ email, password, tipo: "cliente" });

    try {
      const res = await axios.post("/api/users", body, config);
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });

      dispatch(loadUser());

      // Google Analytics
      ReactGA.event({
        category: "Usuario",
        action: "registro de usuario",
      });

      history.push("/create-profile");
    } catch (err) {
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      }

      dispatch({
        type: REGISTER_FAIL,
      });
    }
  };

// Login User
export const login = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email, password });

  try {
    const res = await axios.post("/api/auth", body, config);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    // Google Analytics
    ReactGA.event({
      category: "Usuario",
      action: "usuario logeado",
    });

    dispatch(loadUser());
    dispatch(loadClient());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

// Logout
export const logout = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
  });

  // Google Analytics
  ReactGA.event({
    category: "Usuario",
    action: "cierre de sesion",
  });

  dispatch(clearCliente());
};

// Open Sidepanel
export const openSidePanel = () => (dispatch) => {
  dispatch({
    type: OPEN_SIDEPANEL,
  });
};

// Close sidepanel
export const closeSidePanel = () => (dispatch) => {
  dispatch({
    type: CLOSE_SIDEPANEL,
  });
};

import React from "react";

const CambiarCuenta = () => {
  return (
    <div className="container">
      <h1>Cambiá a una cuenta de cliente para poder seguir usando Andiamo</h1>
    </div>
  );
};

export default CambiarCuenta;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {
  setActualAddress,
  deleteAddress,
  loadClient,
} from "../../actions/users";
import ReactGA from "react-ga";

const AddressList = ({
  setActualAddress,
  deleteAddress,
  loadClient,
  profile: { profile, loading },
  history,
}) => {
  // Google Analytics
  ReactGA.pageview("/lista-direcciones");

  useEffect(() => {
    loadClient();
  }, []);

  let [addressState, setAddress] = useState(null);
  const [displayGuardarButton, setDisplayGuardarButton] = useState(false);
  const [index, setIndex] = useState(-1);

  const performSelection = (addressObject, i) => {
    setAddress(addressObject);
    setDisplayGuardarButton(true);
    setIndex(i);
  };

  const setMyNewAddress = (addressObject) => {
    setActualAddress(addressObject, history);
  };

  const eliminarDireccion = async (address) => {
    const actualAddress = profile.actualAddress;
    if (
      actualAddress.calle === address.calle &&
      actualAddress.numero === address.numero &&
      actualAddress.piso === address.piso &&
      actualAddress.depto === address.depto
    ) {
      const i = profile.addresses
        .map((addrss) => {
          return addrss._id;
        })
        .indexOf(address._id);

      if (profile.addresses.length > 1) {
        if (i === 0) {
          setMyNewAddress(profile.addresses[1]);
        } else {
          setMyNewAddress(profile.addresses[0]);
        }
      }
    }

    if (profile.addresses.length > 1) await deleteAddress(address);
  };

  const list =
    loading || profile === null
      ? null
      : profile.addresses.map((address, i) => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              key={i}
              className="my-1"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                padding: "1.5rem",
                backgroundColor: "white",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={() => setMyNewAddress(address)}
            >
              <div>
                <h5>{`${address.calle} ${address.numero}`}</h5>
                {address.piso !== undefined && (
                  <h5>{`Piso: ${address.piso} Depto: ${address.depto}`}</h5>
                )}
              </div>
              <i
                className="fas fa-check fa-lg"
                style={
                  profile.actualAddress.calle === address.calle &&
                  profile.actualAddress.numero === address.numero
                    ? { color: "#7bbb78" }
                    : { color: "#efeff1" }
                }
              />
            </div>
            <i
              className="fas fa-times fa-lg"
              style={{ marginLeft: "0.5rem" }}
              onClick={() => eliminarDireccion(address)}
            />
          </div>
        ));

  return (
    <div
      className="container"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div
        className="btn-secondary"
        style={{ width: "3rem", marginBottom: "1rem" }}
        onClick={() => history.goBack()}
      >
        <i className="fas fa-arrow-left"></i>
      </div>
      <div>
        <h1>Mis Direcciones</h1>
        <p>
          Este es el listado de tus direcciones. Tocá sobre alguna para cambiar
          tu dirección actual
        </p>
      </div>
      <div className="my-2">{list}</div>
      <Link to="/add-address-from-map" className="btn-secondary my">
        <span>Añadir una nueva dirección</span>
      </Link>
    </div>
  );
};

AddressList.propTypes = {
  setActualAddress: PropTypes.func.isRequired,
  deleteAddress: PropTypes.func.isRequired,
  loadClient: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps, {
  setActualAddress,
  deleteAddress,
  loadClient,
})(withRouter(AddressList));

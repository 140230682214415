import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Spinner from "../layout/Spinner";
import shopPlaceholder from "../../assets/shop.png";
import ReactGA from "react-ga";

const PedidosEnCursoList = ({ pedidos, cancelarPedido, socket, loading }) => {
  ReactGA.pageview("/pedidos-en-curso");

  const [verDetalle, setVerDetalle] = useState(-1);

  const mostrar = (i) => {
    setVerDetalle(i);
  };
  const ocultar = () => {
    setVerDetalle(-1);
  };

  const hayPedidosEnCurso = () => {
    if (
      pedidos.filter((pedido) => {
        return (
          pedido.estado !== "Completado" &&
          pedido.estado !== "Entregado" &&
          pedido.estado !== "Rechazado"
        );
      }).length !== 0
    ) {
      return true;
    }

    return false;
  };

  const getFormattedDate = (pedido) => {
    const pedidoDate = new Date(pedido.date);
    const hoursStr = pedidoDate.getHours().toString().padStart(2, "0");
    const minutesStr = pedidoDate.getMinutes().toString().padStart(2, "0");

    const strDate =
      pedidoDate.getDate() +
      "/" +
      (pedidoDate.getMonth() + 1) +
      "/" +
      pedidoDate.getFullYear() +
      ", " +
      hoursStr +
      ":" +
      minutesStr;

    return strDate;
  };

  const getSubtotal = (productos) => {
    var sumatoria = 0;
    productos.forEach((producto) => {
      sumatoria += producto.cantidad * producto.precio;
      producto.extras.forEach((extraArr) => {
        extraArr.forEach((ex) => {
          sumatoria += ex.precio;
        });
      });
    });

    return sumatoria;
  };

  const cancelar = async (id) => {
    const pedidoCancelado = await cancelarPedido(id);
    /*
    if (pedidoCancelado) {
      socket.emit("pedido_cancelado");
    }
    */
  };

  const content =
    loading || pedidos === null ? (
      <Spinner />
    ) : !hayPedidosEnCurso() ? (
      <h3>No tienes pedidos en curso</h3>
    ) : (
      <div>
        {pedidos
          .filter((pedido) => {
            return (
              pedido.estado !== "Completado" &&
              pedido.estado !== "Entregado" &&
              pedido.estado !== "Rechazado"
            );
          })
          .map((pedido, i) => (
            <div
              className="my-1"
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "white",
                borderRadius: "5px",
                padding: "1rem",
              }}
              key={i}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={
                    pedido.local.avatar.filename
                      ? pedido.local.avatar.filename
                      : shopPlaceholder
                  }
                  alt="Local"
                  className="rounded"
                  style={{ width: "50px", height: "50px" }}
                />
                <div style={{ marginLeft: "15px" }}>
                  <h3>{pedido.local.name}</h3>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <p>{getFormattedDate(pedido)}</p>
                    <strong style={{ marginLeft: "10px" }}>|</strong>
                    <p style={{ marginLeft: "10px" }}>{pedido.metodoPago}</p>
                  </div>
                </div>
              </div>
              <div
                className="my-1"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <small style={{ color: "rgb(200, 200, 200)" }}>Estado</small>
                <strong>{pedido.estado}</strong>
                {pedido.estado === "En Espera" && (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <strong>El local todavía no aceptó tu pedido</strong>
                  </div>
                )}
                {pedido.estado === "En Preparación" &&
                  pedido.entrega !== "takeAway" && (
                    <strong>
                      El local aceptó tu pedido y lo esta preparando
                    </strong>
                  )}
                {pedido.estado === "En Preparación" &&
                  pedido.entrega === "takeAway" &&
                  !pedido.local.cocina && (
                    <strong>
                      El local aceptó tu pedido, ya podes ir a buscarlo
                    </strong>
                  )}
                {pedido.estado === "Preparado" &&
                  pedido.entrega === "takeAway" && (
                    <strong>Tu pedido está listo. Podes ir a buscarlo.</strong>
                  )}
                {pedido.estado === "En Viaje" && (
                  <strong>Tu pedido está en viaje</strong>
                )}
              </div>
              <Link
                to={{ pathname: `/track-pedido/${pedido._id}` }}
                className="btn-secondary"
              >
                Ver detalles del pedido
              </Link>
              {pedido.estado === "En Espera" && (
                <button
                  className="btn my"
                  style={{ backgroundColor: "#e76f51" }}
                  onClick={() => cancelar(pedido._id)}
                >
                  Cancelar este pedido
                </button>
              )}
            </div>
          ))}
      </div>
    );

  return <Fragment>{content}</Fragment>;
};

export default PedidosEnCursoList;

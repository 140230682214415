import { SET_SOCKET, CLEAR_SOCKET } from "./types";
import io from "socket.io-client";

export const setSocketState = () => async (dispatch) => {
  const socket = io(window.location.origin, {
    timeout: 30,
    connect_timeout: 30,
  });

  dispatch({
    type: SET_SOCKET,
    payload: socket,
  });
};

export const clearSocketState = () => async (dispatch) => {
  dispatch({
    type: CLEAR_SOCKET,
  });
};

import {
  GET_LOCALES,
  GET_LOCAL,
  CLEAR_LOCALES,
  CLEAR_LOCAL,
} from "../actions/types";

const initialState = {
  locales: { locales: null, category: null },
  local: null,
  loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_LOCALES:
      const localesArray = [];
      if (state.locales !== null) {
        if (state.locales.category === payload.category) {
          localesArray.push.apply(localesArray, state.locales.locales);
        }
      }
      localesArray.push.apply(localesArray, payload.locales);
      return {
        ...state,
        locales: { locales: localesArray, category: payload.category },
        loading: false,
      };
    case GET_LOCAL:
      return {
        ...state,
        local: payload,
        loading: false,
      };
    case CLEAR_LOCALES:
      return {
        ...state,
        locales: { locales: null },
        loading: true,
      };
    case CLEAR_LOCAL:
      return {
        ...state,
        local: null,
        loading: true,
      };
    default:
      return state;
  }
}

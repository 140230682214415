import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import Masonry from "react-masonry-css";
import { getLocales, clearLocales, clearLocal } from "../../actions/locales";
import { clearProducts } from "../../actions/products";
import shopPlaceholder from "../../assets/shop.png";
import logo from "../../assets/icono512.png";
import plusPagosIcon from "../../assets/plusPagos-icon.png";
import Spinner from "../layout/Spinner";
import ReactGA from "react-ga";
import WelcomeModal from "../layout/WelcomeModal";
import Cadeteria from "../cadeteria/Cadeteria";
import { estaAbierto } from "../../utils/auxFunctions";

// Carousel
import CarouselComponent from "../layout/Carousel";

// Iconos para categorias de negocios
import todos from "../../assets/negocios-categorias/todo.png";
import butchery from "../../assets/negocios-categorias/butchery.png";
import supermarket from "../../assets/negocios-categorias/carrito.png";
import bar from "../../assets/negocios-categorias/burger.png";
import heladeria from "../../assets/negocios-categorias/ice-cream.png";
import kiosko from "../../assets/negocios-categorias/candy.png";
import minimercado from "../../assets/negocios-categorias/minimarket.png";
import farmacia from "../../assets/negocios-categorias/medicine.png";
import verduleria from "../../assets/negocios-categorias/watermelon.png";
import otros from "../../assets/negocios-categorias/coffee-cup.png";
import delivery from "../../assets/icono delivery.png";

// Modal
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const debounce = (func, wait, immediate) => {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
};

let indexState;
let newCategory;

const NegociosList = ({
  getLocales,
  clearLocales,
  clearLocal,
  clearProducts,
  locales: {
    loading,
    locales: { locales },
  },
  auth: { isAuthenticated, user },
  location,
  history,
}) => {
  // Google Analytics
  ReactGA.pageview("/lista-negocios");

  useEffect(() => {
    window.scrollTo(0, 0);
    clearLocal();
  }, []);

  useEffect(() => {
    clearLocales();
    getLocales(
      location.state !== undefined ? location.state.category : "default",
      0
    );
    clearProducts();

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [index, setIndex] = useState(0);
  const [category, setCategory] = useState(
    location.state !== undefined ? location.state.category : "default"
  );

  const seleccionar = (categoria) => {
    setCategory(categoria);

    if (newCategory !== categoria) {
      setIndex(0);
      getLocales(categoria, 0);
    }
  };

  const [openModal, setOpenModal] = useState(false);
  const [localSelected, setLocalSelected] = useState(null);
  const redirectToProducts = (local) => {
    if (estaAbierto(local)) {
      history.push({
        pathname: `/lista-productos/${local._id}`,
        state: {
          estaAbierto: true,
        },
      });
    } else {
      setLocalSelected(local._id);
      setOpenModal(true);
    }
  };

  indexState = index;
  newCategory = category;
  const cargarLocales = () => {
    getLocales(newCategory, indexState * 10);
  };

  const handleScroll = debounce(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop <
      document.documentElement.scrollHeight - 100
    )
      return;

    setIndex(indexState + 1);
    cargarLocales();
  }, 300);

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
  };

  if (user !== null && (user.isLocal || user.isDelivery)) {
    return <Redirect to="/cambiar-cuenta" />;
  }

  const content =
    loading || locales === null ? (
      <Spinner />
    ) : (
      <div
        className="my-2"
        style={{ display: "flex", flexDirection: "column" }}
      >
        {/*
        <strong className="my-1">
          {category === "default" ? "Todos" : category}
        </strong>
      */}
        {locales.length === 0 ? (
          <div style={{ marginBottom: "3rem" }}>
            <h3>No hay locales en esta categoría</h3>
          </div>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {locales.map((local, i) => (
                <div key={i} onClick={() => redirectToProducts(local)}>
                  <div
                    className="my"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <img
                      src={
                        local.avatar.filename !== ""
                          ? local.avatar.filename
                          : shopPlaceholder
                      }
                      alt="Local"
                      className="rounded"
                      style={{
                        height: "90px",
                        width: "90px",
                      }}
                    />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      style={{
                        paddingLeft: "0.7rem",
                        wordBreak: "break-word",
                      }}
                    >
                      <h2>{local.name}</h2>
                      <p>{local.category}</p>
                      <span>
                        <i
                          className="fas fa-star"
                          style={{ marginRight: "5px", color: "#ffff00" }}
                        />
                        <strong>{local.rating}/5</strong>
                      </span>
                    </div>
                    <div className="my">
                      <div
                        style={{
                          paddingTop: "0.5rem",
                          borderTop: "1px solid rgb(200, 200, 200)",
                          paddingBottom: "0.5rem",
                          borderBottom: "1px solid rgb(200, 200, 200)",
                          paddingLeft: "0.7rem",
                        }}
                      >
                        {estaAbierto(local) === true ? (
                          <small>ABIERTO</small>
                        ) : (
                          <small>CERRADO</small>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingTop: "0.5rem",
                          paddingBottom: "0.5rem",
                          borderBottom: "1px solid rgb(200, 200, 200)",
                          paddingLeft: "0.7rem",
                        }}
                      >
                        {local.tarjeta && (
                          <i className="far fa-credit-card"></i>
                        )}
                        {!local.envio && (
                          <img
                            src={delivery}
                            alt="andiamo"
                            style={{
                              width: "1.7rem",
                              height: "auto",
                              marginLeft: "0.5rem",
                            }}
                          />
                        )}
                        {local.plusPagos && (
                          <img
                            src={plusPagosIcon}
                            alt="pluspagos"
                            style={{
                              width: "0.9rem",
                              height: "auto",
                              marginLeft: "0.5rem",
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <button
                      className="btn"
                      style={{ padding: "0.5rem", margin: "0.5rem" }}
                      onClick={() => redirectToProducts(local)}
                    >
                      Ver Productos
                    </button>
                  </div>
                </div>
              ))}
            </Masonry>
          </div>
        )}
      </div>
    );

  return (
    <div id="header" className="container">
      <div className="my" style={{ display: "flex", flexDirection: "column" }}>
        <CarouselComponent
          seleccionar={seleccionar}
          inicialCategory={
            location.state !== undefined ? location.state.category : "default"
          }
        />
        {/*
        <form className="my-1">
          <select
            name="category"
            value={category}
            onChange={(e) => onChange(e)}
            required
          >
            <option value="default">Todos</option>
            <option value="supermercado">Supermercado</option>
            <option value="minimercado">Minimercado</option>
            <option value="kiosko">Kiosko</option>
            <option value="carniceria">Carnicería</option>
            <option value="verduleria">Verdulería</option>
            <option value="bar">Bar / Restó</option>
            <option value="heladeria">Heladería</option>
            <option value="farmacia">Farmacia</option>
            <option value="otros">Otros</option>
          </select>
        </form>
        */}
      </div>
      {category === "cadeteria" ? <Cadeteria history={history} /> : content}
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        blockScroll={false}
        center
      >
        <h2 style={{ marginTop: "2rem", marginBottom: "1rem" }}>
          Local cerrado
        </h2>
        <p>No podes pedir ahora pero si podes ver los productos.</p>
        <div
          className="my-1"
          style={{
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <button
            className="btn-secondary"
            onClick={() => {
              setOpenModal(false);
            }}
          >
            <i className="fas fa-arrow-left" style={{ marginRight: "5px" }} />
            Volver
          </button>
          <button
            className="btn"
            onClick={() => {
              history.push({
                pathname: `/lista-productos/${localSelected}`,
                state: { estaAbierto: false },
              });
            }}
          >
            Ver Productos
          </button>
        </div>
      </Modal>
      <WelcomeModal />
    </div>
  );
};

NegociosList.propTypes = {
  getLocales: PropTypes.func.isRequired,
  clearLocales: PropTypes.func.isRequired,
  clearLocal: PropTypes.func.isRequired,
  clearProducts: PropTypes.func.isRequired,
  locales: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  locales: state.locales,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getLocales,
  clearLocales,
  clearLocal,
  clearProducts,
})(NegociosList);

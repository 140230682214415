import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { getProducts } from "../../../actions/products";
import { getLocal, clearLocal } from "../../../actions/locales";
import { getOfertas } from "../../../actions/sections";
import { getDeliveryAvailability } from "../../../actions/riders";
import Spinner from "../../layout/Spinner";
import { estaAbierto } from "../../../utils/auxFunctions";
import ReactGA from "react-ga";
import OfertasCarousel from "./OfertasCarousel";
import CategoriesSelector from "./CategoriesSelector";
import ProductosDestacados from "./ProductosDestacados";
import CategoryDisplay from "./CategoryDisplay";
import AddExtrasModal from "./AddExtrasModal";
import NegocioBanner from "../NegocioBanner";
import RegisterPrompt from "../../layout/RegisterPrompt";

const ListaProductos = ({
  getProducts,
  getLocal,
  clearLocal,
  getOfertas,
  getDeliveryAvailability,
  products: { products, loading },
  locales: { local },
  auth: { user, isAuthenticated },
  ofertas: { ofertas },
  match,
  location,
  history,
}) => {
  // Google Analytics
  if (local !== null) {
    ReactGA.pageview(`/lista-productos/${local.name}`);
  }

  const [productosSeleccionados, setProductosSeleccionados] = useState([]);
  const [productosDestacados, setProductosDestacados] = useState([]);
  const [displayActionButton, setDisplayActionButton] = useState(false);
  const [productoConExtras, setProductoConExtras] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [extrasDeProducto, setExtrasDeProducto] = useState([]);
  const [extrasSeleccionados, addExtraSeleccionado] = useState([]);
  const [arrayProduct, setArrayProducts] = useState([]);
  const [prod, setProd] = useState("");
  const [deliveryAvailable, setDeliveryAvailable] = useState();

  // Ref para las categorias
  const categRef = useRef([]);
  // Ref para los productos oferta
  const prodOfertaRef = useRef([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getProducts(match.params.id);
    getLocal(match.params.id);
    getOfertas(match.params.id);

    return () => {
      clearLocal();
      setArrayProducts([]);
    };
  }, []);

  useEffect(() => {
    setDisplayActionButton(false);

    var index = 0;

    while (index < productosSeleccionados.length) {
      if (productosSeleccionados[index].cantidad > 0) {
        setDisplayActionButton(true);
      }
      index++;
    }
  }, [productosSeleccionados]);

  useEffect(() => {
    const getData = async () => {
      const available = await getDeliveryAvailability();
      setDeliveryAvailable(available.available);
    };
    if (isAuthenticated) {
      getData();
    }
  }, [isAuthenticated]);

  const [catRefFlag, setCatRefFlag] = useState(false);
  if (!catRefFlag && local !== null) {
    categRef.current = categRef.current.slice(0, local.categories);
    setCatRefFlag(true);
  }

  const getIndex = (id) => {
    const i = productosSeleccionados
      .map((producto) => {
        return producto.producto._id;
      })
      .indexOf(id);

    return i;
  };

  const plusProduct = (prod, price, extras) => {
    const i = getIndex(prod._id);
    if (i === -1) {
      const newArray = productosSeleccionados.concat({
        producto: prod,
        price: price,
        cantidad: 1,
        extras: [extras],
      });
      setProductosSeleccionados(newArray);
    } else {
      if (productosSeleccionados[i].cantidad < 100) {
        productosSeleccionados[i].cantidad++;
        productosSeleccionados[i].extras.push(extras);
        const newArray = [...productosSeleccionados];
        setProductosSeleccionados(newArray);
      }
    }
  };

  const minusProduct = (prod, price) => {
    const i = getIndex(prod._id);
    if (i === -1) {
      const newArray = productosSeleccionados.concat({
        producto: prod,
        price: price,
        cantidad: 0,
      });
      setProductosSeleccionados(newArray);
    } else {
      if (productosSeleccionados[i].cantidad > 0) {
        productosSeleccionados[i].cantidad--;
        productosSeleccionados[i].extras.pop();
        const newArray = [...productosSeleccionados];
        setProductosSeleccionados(newArray);
      }
    }
  };

  const setExtrasDelProducto = (product) => {
    const extrasCurados = [];
    const variaciones = local.variationsAvailable;

    for (var i = 0; i < variaciones.length; i++) {
      const index2 = product.extras
        .map((extra) => {
          return extra.variationId;
        })
        .indexOf(variaciones[i]._id);

      if (index2 !== -1 && variaciones[i].disponible) {
        extrasCurados.push({
          name: variaciones[i].name,
          precio: variaciones[i].precio,
          options: variaciones[i].options,
          limite: product.extras[index2].limite,
        });
      }
    }

    setExtrasDeProducto(extrasCurados);
  };

  useEffect(() => {
    if (products !== null) {
      setArrayProducts(products);
      const prodDest = products.filter((prod) => prod.vendidos >= 10);
      setProductosDestacados(prodDest);

      if (
        location.state !== undefined &&
        location.state.product !== undefined &&
        products !== null
      ) {
        setProd(location.state.product);

        const prodString = location.state.product.toLowerCase();
        products = products.filter(function (obj) {
          return obj.producto.name.includes(prodString);
        });
        setArrayProducts(products);
      }
    }
  }, [products]);

  const [closeRegisterPrompt, setCloseRegisterPrompt] = useState(false);
  const [redirect, setRedirect] = useState(false);
  if (redirect) {
    return <Redirect to="/register" />;
  }

  if (user !== null && (user.isLocal || user.isDelivery)) {
    return <Redirect to="/cambiar-cuenta" />;
  }

  const breakpointColumnsObj = {
    default: 6,
    1100: 3,
    500: 2,
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      partialVisibilityGutter: 40,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 2,
      partialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      partialVisibilityGutter: 20,
    },
  };

  const content =
    loading || products === null || local === null ? (
      <Spinner />
    ) : (
      <div
        style={{
          height: "14rem",
          backgroundImage:
            local.avatar.filename !== "" && `url("${local.avatar.filename}")`,
          backgroundColor: "#17d070",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          paddingTop: "5rem",
        }}
      >
        <div>
          <div
            className="btn-secondary"
            style={{
              width: "3rem",
              margin: "1rem",
              marginBottom: "1rem",
              backgroundColor: "white",
              border: "none",
            }}
            onClick={() => history.push("/")}
          >
            <i className="fas fa-arrow-left"></i>
          </div>
          <NegocioBanner local={local} />
          {deliveryAvailable === false && (
            <div
              style={{
                margin: "1rem",
                padding: "0.5rem",
                borderRadius: "10px",
                backgroundColor: "#d6cfc7",
              }}
            >
              <strong>
                Sin delivery momentaneamente. Podes pedir para llevar (Retiras
                tu pedido personalmente en el local)
              </strong>
            </div>
          )}
          <CategoriesSelector
            categories={local.categories}
            categsRef={categRef}
          />
          <div style={{ padding: "0 1rem" }}>
            <OfertasCarousel local={local._id} ofertasRef={prodOfertaRef} />
          </div>
          {!local.exibition && (
            <ProductosDestacados
              productosDestacados={productosDestacados}
              setProductoConExtras={setProductoConExtras}
              setExtrasDelProducto={setExtrasDelProducto}
              setOpenModal={setOpenModal}
              getIndex={getIndex}
              responsive={responsive}
              ofertas={ofertas}
              plusProduct={plusProduct}
              minusProduct={minusProduct}
              productosSeleccionados={productosSeleccionados}
              setProductosSeleccionados={setProductosSeleccionados}
            />
          )}
          <div>
            <div style={{ marginBottom: "5.5rem" }}>
              {local.categories
                .filter((categoria) => categoria.active)
                .map((categoria, idx) => (
                  <CategoryDisplay
                    idx={idx}
                    categRef={categRef}
                    categoria={categoria}
                    breakpointColumnsObj={breakpointColumnsObj}
                    arrayProduct={arrayProduct}
                    local={local}
                    productosSeleccionados={productosSeleccionados}
                    setProductosSeleccionados={setProductosSeleccionados}
                    getIndex={getIndex}
                    setProductoConExtras={setProductoConExtras}
                    setExtrasDelProducto={setExtrasDelProducto}
                    setOpenModal={setOpenModal}
                    ofertas={ofertas}
                    prodOfertaRef={prodOfertaRef}
                    minusProduct={minusProduct}
                    plusProduct={plusProduct}
                  />
                ))}
            </div>
            {displayActionButton && estaAbierto(local) && (
              <div
                className="btn-rounded"
                style={{
                  position: "fixed",
                  right: "0",
                  bottom: "0",
                  height: "65px",
                  width: "65px",
                  marginRight: "1.5rem",
                  marginBottom: "1rem",
                  backgroundColor: "#17d070",
                }}
              >
                <Link
                  to={{
                    pathname: "/resumen",
                    products: { productosSeleccionados },
                    local: { local },
                  }}
                >
                  <i
                    className="fas fa-shopping-cart fa-2x"
                    style={{ color: "white" }}
                  ></i>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    );

  return (
    <div>
      {!isAuthenticated && !closeRegisterPrompt && (
        <RegisterPrompt close={setCloseRegisterPrompt} redirect={setRedirect} />
      )}
      <div>
        {content}
        <AddExtrasModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          extrasSeleccionados={extrasSeleccionados}
          addExtraSeleccionado={addExtraSeleccionado}
          productoConExtras={productoConExtras}
          extrasDeProducto={extrasDeProducto}
          plusProduct={plusProduct}
        />
      </div>
    </div>
  );
};

ListaProductos.propTypes = {
  products: PropTypes.object.isRequired,
  locales: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  ofertas: PropTypes.object.isRequired,
  getProducts: PropTypes.func.isRequired,
  getLocal: PropTypes.func.isRequired,
  clearLocal: PropTypes.func.isRequired,
  getOfertas: PropTypes.func.isRequired,
  getDeliveryAvailability: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  products: state.products,
  locales: state.locales,
  auth: state.auth,
  ofertas: state.ofertas,
});

export default connect(mapStateToProps, {
  getProducts,
  getLocal,
  clearLocal,
  getOfertas,
  getDeliveryAvailability,
})(ListaProductos);

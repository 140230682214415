import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import pedidoRealizado from "../../assets/clip-delivery-1.png";

const PedidoCadeteExitoso = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      className="landing"
      style={{
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
      }}
    >
      <img src={pedidoRealizado} style={{ width: "20rem", height: "auto" }} />
      <div style={{ width: "90%", textAlign: "center" }}>
        <h1>Tu pedido de cadetería se realizó correctamente &#x2764;</h1>
        <p>En un momento uno de nuestros riders lo tomará</p>
      </div>
      <Link to="/" className="btn my-2">
        Volver a la pantalla principal
      </Link>
    </div>
  );
};

export default PedidoCadeteExitoso;

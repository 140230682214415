import React, { Fragment, useState } from "react";
// Modal
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

import logo from "../../assets/icono512.png";

const WelcomeModal = () => {
  const [welcomeModal, openWelcomeModal] = useState(false);

  if (
    !document.cookie.split("; ").find((row) => row.startsWith("modalOpened"))
  ) {
    openWelcomeModal(true);
    document.cookie = "modalOpened=true; expires=Fri, 31 Dec 9999 23:59:59 GMT";
  }

  return (
    <Fragment>
      <Modal
        open={welcomeModal}
        onClose={() => openWelcomeModal(false)}
        blockScroll={false}
        showCloseIcon={false}
        center
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <img
            src={logo}
            alt="Logo"
            style={{
              display: "block",
              width: "100px",
              height: "auto",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />

          <div
            className="my-2"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <h2>¡Bienvenido a Andiamo!</h2>
            <strong style={{ paddingTop: "0.5rem" }}>
              Compra desde tu celular, en tu negocio favorito y recibilo sin
              moverte de tu casa
            </strong>
          </div>
          <button className="btn" onClick={() => openWelcomeModal(false)}>
            A comprar!
          </button>
        </div>
      </Modal>
    </Fragment>
  );
};

export default WelcomeModal;

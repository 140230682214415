import React from "react";
import LazyLoad from "react-lazyload";
import Masonry from "react-masonry-css";
import ProductCard from "./ProductCard";

const CategoryDisplay = ({
  idx,
  categRef,
  categoria,
  breakpointColumnsObj,
  arrayProduct,
  local,
  productosSeleccionados,
  setProductosSeleccionados,
  getIndex,
  setProductoConExtras,
  setExtrasDelProducto,
  setOpenModal,
  ofertas,
  prodOfertaRef,
  minusProduct,
  plusProduct,
}) => {
  return (
    <>
      <div
        key={idx}
        ref={(el) => (categRef.current[idx] = el)}
        className="my-1"
      >
        <div
          className="my"
          style={{
            width: "100%",
            padding: "0.5rem 1rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: `linear-gradient(to right, rgba(23, 208, 112, 0) 0%,
                         rgba(23, 208, 112, 1) 2%,
                         rgba(23, 208, 112, 1) 10%,
                         rgba(23, 208, 112, 1) 25%, 
                         rgba(23, 208, 112, 1) 50%, 
                         rgba(23, 208, 112, 1) 70%, 
                         rgba(23, 208, 112, 1) 98%,
                         rgba(23, 208, 112, 0) 100%)`,
            color: "white",
          }}
        >
          <h3>{categoria.name}</h3>
        </div>
        <div style={{ padding: "1rem" }}>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {arrayProduct
              .filter(
                (product) =>
                  !product.hasOwnProperty("category") ||
                  product.category.name === categoria.name
              )
              .map((product, i) => (
                <ProductCard
                  i={i}
                  product={product}
                  local={local}
                  getIndex={getIndex}
                  setProductoConExtras={setProductoConExtras}
                  setExtrasDelProducto={setExtrasDelProducto}
                  setOpenModal={setOpenModal}
                  ofertas={ofertas}
                  prodOfertaRef={prodOfertaRef}
                  productosSeleccionados={productosSeleccionados}
                  setProductosSeleccionados={setProductosSeleccionados}
                  minusProduct={minusProduct}
                  plusProduct={plusProduct}
                />
              ))}
          </Masonry>
        </div>
      </div>
    </>
  );
};

export default CategoryDisplay;

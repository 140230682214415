import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { register } from "../../actions/auth";
import ReactGA from "react-ga";

const RegisterClient = ({
  register,
  auth: { isAuthenticated, user },
  profile: { profile },
  history,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    register({ email, password }, history);
  };

  if (isAuthenticated && user !== null) {
    if (user.isCliente === true && profile !== null) {
      return <Redirect to="/" />;
    } else if (user.isCliente === true && profile === null) {
      return <Redirect to="/create-profile" />;
    }
    if (user.isLocal || user.isDelivery) {
      return <Redirect to="/cambiar-cuenta" />;
    }
  }

  ReactGA.pageview("/register");

  return (
    <div className="landing register-bg">
      <div className="col-2 container">
        <div>
          <h1>Registrarme</h1>
          <h4 className="my">
            Vamos a empezar con el proceso para registrar tu cuenta. <br />
            Completá con tu mail y una contraseña.
          </h4>
        </div>
        <form onSubmit={(e) => onSubmit(e)}>
          <div className="input-icons">
            <i className="fas fa-user icon"></i>
            <input
              type="email"
              name="email"
              onChange={(e) => onChange(e)}
              placeholder="*Email"
              required
              className="input-field"
              style={{ borderBottom: "1px solid #0c120a", paddingLeft: "3rem" }}
            />
            <i className="fas fa-lock icon"></i>
            <input
              type="password"
              name="password"
              onChange={(e) => onChange(e)}
              placeholder="*Contraseña"
              required
              className="input-field"
              style={{ borderBottom: "1px solid #0c120a", paddingLeft: "3rem" }}
            />
          </div>
          <small>Tu contraseña debe contener al menos 6 caracteres</small>
          <div
            className="my-1"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "0.5rem",
            }}
          >
            <p>
              Ya tenes una cuenta?{" "}
              <Link to="/login" style={{ color: "#82b5a5", cursor: "pointer" }}>
                Iniciar Sesión
              </Link>
            </p>
            <input className="btn" type="submit" value="Continuar" />
          </div>
        </form>
      </div>
    </div>
  );
};

RegisterClient.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, { register })(RegisterClient);

import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addAddress } from "../../actions/users";
import ReactGA from "react-ga";

const AddAddress = ({ addAddress, history }) => {
  // Google Analytics
  ReactGA.pageview("/añadir-direccion");

  const [formData, setFormData] = useState({
    calle: "",
    numero: "",
    piso: "",
    depto: "",
  });

  const { calle, numero, piso, depto } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    addAddress({ calle, numero, piso, depto }, history);
  };
  return (
    <div className="container">
      <div
        className="btn-secondary"
        style={{ width: "3rem", marginBottom: "1rem" }}
        onClick={() => history.goBack()}
      >
        <i className="fas fa-arrow-left"></i>
      </div>

      <h1>Añadir una Dirección</h1>

      <div className="my-3">
        <small>* Campos obligatorios</small>
        <form onSubmit={(e) => onSubmit(e)}>
          <input
            type="text"
            name="calle"
            value={calle}
            onChange={(e) => onChange(e)}
            placeholder="*Calle"
            required
          />
          <input
            type="text"
            name="numero"
            value={numero}
            onChange={(e) => onChange(e)}
            placeholder="*Número"
            required
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <input
              type="text"
              name="piso"
              value={piso}
              onChange={(e) => onChange(e)}
              placeholder="Piso"
            />
            <input
              type="text"
              name="depto"
              value={depto}
              onChange={(e) => onChange(e)}
              placeholder="Depto"
            />
          </div>

          <input
            type="submit"
            value="Añadir"
            className="btn my"
            style={{ float: "right" }}
          />
        </form>
      </div>
    </div>
  );
};

AddAddress.propTypes = {
  addAddress: PropTypes.func.isRequired,
};

export default connect(null, { addAddress })(withRouter(AddAddress));

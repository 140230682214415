import { GET_SERVICIOS, CLEAR_SERVICIOS } from "./types";
import axios from "axios";
import { setAlert } from "./alert";

export const createServicio = (formData, history) => async (dispatch) => {
  try {
    // build form data
    const form = Object.keys(formData).reduce((f, k) => {
      f.append(k, formData[k]);
      return f;
    }, new FormData());

    await axios.post("/api/servicio/", form);

    dispatch(setAlert("Servicio añadido con exito", "success"));
    history.push("/");
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

export const getServicios = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/servicio/");

    dispatch({
      type: GET_SERVICIOS,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

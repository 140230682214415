import { GET_SECTIONS, CLEAR_SECTIONS } from "../actions/types";

const initialState = {
  loading: true,
  sections: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SECTIONS:
      return {
        ...state,
        loading: false,
        sections: payload,
      };
    case CLEAR_SECTIONS:
      return {
        ...state,
        loading: false,
        sections: payload,
      };
    default:
      return state;
  }
}

import axios from "axios";
import { SET_PEDIDOS, CLEAR_PEDIDOS, SET_MAS_PEDIDOS } from "./types";
import ReactGA from "react-ga";
import { setAlert } from "./alert";

export const createPedido =
  (id, products, configObject, paymentId, history) => async (dispatch) => {
    try {
      const body = {
        productos: products,
        metodoPago: configObject.metodoPago,
        notas: configObject.notas,
        entrega: configObject.entrega,
        horario: configObject.horario,
        address: configObject.address,
        paymentId: paymentId,
      };

      await axios.post(`/api/pedidos/crearPedido/${id}`, body);

      // Google Analytics
      ReactGA.event({
        category: "Pedido",
        action: "pedido realizado",
      });
      if (configObject.metodoPago === "Efectivo") {
        ReactGA.event({
          category: "Pedido",
          action: "pago en efectivo",
        });
      } else if (configObject.metodoPago === "Tarjeta") {
        ReactGA.event({
          category: "Pedido",
          action: "pago con tarjeta",
        });
      } else {
        ReactGA.event({
          category: "Pedido",
          action: "pago con pluspagos",
        });
      }

      history.push({ pathname: "/pedido-exitoso", shouldRedirect: false });
    } catch (error) {
      window.alert(
        "Algo salio mal! No se pudo realizar tu pedido. Intentá de nuevo en unos minutos"
      );
      console.log(error);
    }
  };

export const getPedidos = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/pedidos/");
    dispatch({
      type: SET_PEDIDOS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getSinglePedido = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/pedidos/single-pedido/${id}`);

    return res.data;
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

export const getEnvio = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/delivery/getEnvio");

    return res.data[0].precio;
  } catch (error) {
    console.log(error);
  }
};

export const createPrecioEnvio = (tarifa) => async (dispatch) => {
  try {
    const body = {
      precio: tarifa,
    };
    await axios.post("/api/delivery/createEnvio", body);

    dispatch(getEnvio());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

export const votar = (pedido, local, idx) => async (dispatch) => {
  try {
    const body = {
      pedidoId: pedido,
      localId: local,
      idxStar: idx,
    };

    const res = await axios.post("/api/pedidos/votar", body);

    dispatch({
      type: CLEAR_PEDIDOS,
    });
    dispatch(getPedidos());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

export const cancelarPedido = (id) => async (dispatch) => {
  try {
    if (
      window.confirm(
        "Si cancelas tu pedido el local ya no va a prepararlo. ¿Queres continuar?"
      )
    ) {
      const res = await axios.get(`/api/pedidos/single-pedido/${id}`);
      const pedido = res.data;
      if (pedido.estado !== "En Espera") {
        window.alert(
          "Este pedido ya no se puede cancelar porque fue aceptado por el local"
        );
        dispatch(getPedidos());
        return;
      }

      const estado = "Rechazado";
      const body = {
        estado: estado,
      };
      await axios.post(`/api/pedidos/editEstado/${id}`, body);
      window.alert("Pedido Cancelado");
      dispatch(getPedidos());

      ReactGA.event({
        category: "Pedido",
        action: "pedido cancelado",
      });

      return true;
    }
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

    return false;
  }
};

export const getMasPedidos = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/pedidos/mas-pedidos");

    dispatch({
      type: SET_MAS_PEDIDOS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getPedidosActivos = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/pedidos/pedidos_activos_by_cliente");
    return res.data;
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

export const getRidersPedidosActivos = (riderId) => async (dispatch) => {
  try {
    const res = await axios.get("/api/delivery/cantidad-pedidos-activos", {
      params: {
        riderId: riderId,
      },
    });

    return res.data;
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

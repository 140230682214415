import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getActive } from "../../actions/news";

const NewsComponent = ({ getActive, news: { newsActive } }) => {
  useEffect(() => {
    getActive();
  }, []);

  const [open, setOpen] = useState(true);

  const content = newsActive !== null && open && (
    <div
      className="my-3"
      style={{
        width: "100%",
        height: "auto",
        padding: "1rem",
        backgroundColor: "rgba(70, 255, 159)",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ width: "100%" }}>
        <i
          className="fas fa-times fa-sm"
          style={{ float: "right" }}
          onClick={() => setOpen(!open)}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <div
          style={{
            marginRight: "0.5rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h2>{newsActive.header}</h2>
          <h5 style={{ marginTop: "0.5rem" }}>{newsActive.text}</h5>
          {newsActive.link && (
            <a href={newsActive.link} target="_blank">
              <span className="btn-secondary">
                <strong>Entrá acá</strong>
              </span>
            </a>
          )}
        </div>
        <img
          src={newsActive.image.filename}
          alt="avatar"
          style={{ width: "100px", height: "100px" }}
        />
      </div>
    </div>
  );

  return <Fragment>{content}</Fragment>;
};

NewsComponent.propTypes = {
  getActive: PropTypes.func.isRequired,
  news: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  news: state.news,
});

export default connect(mapStateToProps, { getActive })(NewsComponent);

import React from "react";
import { Link } from "react-router-dom";

const EditProfile = ({ history }) => {
  return (
    <div
      className="container"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div
        className="btn-secondary"
        style={{ width: "3rem", marginBottom: "1rem" }}
        onClick={() => history.goBack()}
      >
        <i className="fas fa-arrow-left"></i>
      </div>
      <div>
        <h1>Mi Perfil</h1>
      </div>
      <div className="my-1">
        <div className="my">
          <strong>Contraseñas</strong>
          <Link to="/edit-password" className="btn-secondary">
            Cambiar contraseña
          </Link>
        </div>
        {/*<div>
          <strong>Teléfono</strong>
          <Link to="/edit-phone" className="btn-secondary">
            Cambiar nro. de teléfono
          </Link>
  </div>*/}
      </div>
    </div>
  );
};

export default EditProfile;

import React from "react";

const RegisterPrompt = ({ close, redirect }) => {
  return (
    <div className="register-prompt">
      <div>
        <h1>Registrate para hacer tu pedido</h1>
        <small>
          Al estar registrado podes hacer usos de todas las funciones de Andiamo
          ¡Es rápido y fácil!
        </small>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          <button className="btn-secondary" onClick={() => close(true)}>
            Cerrar
          </button>
          <button
            className="btn"
            style={{ padding: "0.5rem" }}
            onClick={() => redirect(true)}
          >
            Registrarme
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegisterPrompt;

import { combineReducers } from "redux";
import alert from "./alert";
import auth from "./auth";
import locales from "./locales";
import profile from "./profile";
import products from "./products";
import pedidos from "./pedidos";
import cadeteria from "./cadeteria";
import sections from "./sections";
import servicios from "./servicios";
import ofertas from "./ofertas";
import news from "./news";
import socketReducer from "./socketReducer";
import installReducer from "./install";

export default combineReducers({
  alert,
  auth,
  locales,
  profile,
  products,
  pedidos,
  cadeteria,
  sections,
  servicios,
  ofertas,
  news,
  socketReducer,
  installReducer,
});

import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";

// Carousel
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// Iconos para categorias de negocios
import todos from "../../assets/negocios-categorias/todo.png";
import butchery from "../../assets/negocios-categorias/butchery.png";
import supermarket from "../../assets/negocios-categorias/carrito.png";
import bar from "../../assets/negocios-categorias/burger.png";
import heladeria from "../../assets/negocios-categorias/ice-cream.png";
import kiosko from "../../assets/negocios-categorias/candy.png";
import minimercado from "../../assets/negocios-categorias/minimarket.png";
import farmacia from "../../assets/negocios-categorias/medicine.png";
import verduleria from "../../assets/negocios-categorias/watermelon.png";
import otros from "../../assets/negocios-categorias/regalo.png";
import indumentaria from "../../assets/negocios-categorias/camiseta.png";
import panaderia from "../../assets/negocios-categorias/pan-de-molde.png";
import distribuidora from "../../assets/negocios-categorias/caja-de-cerveza.png";
import veterinaria from "../../assets/negocios-categorias/golden-retriever.png";
import ferreteria from "../../assets/negocios-categorias/tornillo.png";
import tv from "../../assets/negocios-categorias/tv.png";
import emprendimientos from "../../assets/negocios-categorias/bombilla.png";
import cadeteria from "../../assets/negocios-categorias/scooter.png";

const CarouselComponent = ({ seleccionar, inicialCategory }) => {
  const [category, setCategory] = useState(
    inicialCategory === undefined || inicialCategory === "default"
      ? "Todos"
      : inicialCategory
  );

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      partialVisibilityGutter: 40,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 2,
      partialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      partialVisibilityGutter: 12,
    },
  };

  const itemStyleSelected = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    backgroundColor: "#17d070",
    objectFit: "cover",
    padding: "0.8rem",
  };

  const itemStyleNonSelected = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    backgroundColor: "white",
    objectFit: "cover",
    padding: "0.8rem",
  };

  const imgStyle = {
    objectFit: "cover",
    width: "2.5rem",
    height: "auto",
  };

  const strongFontSize = {
    marginTop: "0.3rem",
    fontSize: "0.875em",
    fontWeight: "bold",
  };

  return (
    <Fragment>
      <Carousel
        responsive={responsive}
        removeArrowOnDeviceType={["mobile", "tablet"]}
        centerMode={false}
        partialVisible
        infinite={true}
        minimumTouchDrag={0}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          onClick={() => {
            setCategory("Todos");
            seleccionar("default");
          }}
        >
          <div
            style={
              category === "Todos" ? itemStyleSelected : itemStyleNonSelected
            }
          >
            <img src={todos} alt="Todos" style={imgStyle} />
          </div>
          <p style={strongFontSize}>Todos</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          onClick={() => {
            setCategory("bar");
            seleccionar("bar");
          }}
        >
          <div
            style={
              category === "bar" ? itemStyleSelected : itemStyleNonSelected
            }
          >
            <img src={bar} alt="Bar" style={imgStyle} />
          </div>
          <p style={strongFontSize}>Bar / Restó</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          onClick={() => {
            setCategory("heladeria");
            seleccionar("heladeria");
          }}
        >
          <div
            style={
              category === "heladeria"
                ? itemStyleSelected
                : itemStyleNonSelected
            }
          >
            <img src={heladeria} alt="Heladeria" style={imgStyle} />
          </div>
          <p style={strongFontSize}>Heladeria</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          onClick={() => {
            setCategory("panaderia");
            seleccionar("panaderia");
          }}
        >
          <div
            style={
              category === "panaderia"
                ? itemStyleSelected
                : itemStyleNonSelected
            }
          >
            <img src={panaderia} alt="Panaderia" style={imgStyle} />
          </div>
          <p style={strongFontSize}>Panadería</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          onClick={() => {
            setCategory("supermercado");
            seleccionar("supermercado");
          }}
        >
          <div
            style={
              category === "supermercado"
                ? itemStyleSelected
                : itemStyleNonSelected
            }
          >
            <img src={supermarket} alt="Supermercado" style={imgStyle} />
          </div>
          <p style={strongFontSize}>Supermercado</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          onClick={() => {
            setCategory("carniceria");
            seleccionar("carniceria");
          }}
        >
          <div
            style={
              category === "carniceria"
                ? itemStyleSelected
                : itemStyleNonSelected
            }
          >
            <img src={butchery} alt="Carniceria" style={imgStyle} />
          </div>
          <p style={strongFontSize}>Carniceria</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          onClick={() => {
            setCategory("verduleria");
            seleccionar("verduleria");
          }}
        >
          <div
            style={
              category === "verduleria"
                ? itemStyleSelected
                : itemStyleNonSelected
            }
          >
            <img src={verduleria} alt="Verduleria" style={imgStyle} />
          </div>
          <p style={strongFontSize}>Verduleria</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          onClick={() => {
            setCategory("kiosko");
            seleccionar("kiosko");
          }}
        >
          <div
            style={
              category === "kiosko" ? itemStyleSelected : itemStyleNonSelected
            }
          >
            <img src={kiosko} alt="Kiosko" style={imgStyle} />
          </div>
          <p style={strongFontSize}>Kiosko</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          onClick={() => {
            setCategory("distribuidora");
            seleccionar("distribuidora");
          }}
        >
          <div
            style={
              category === "distribuidora"
                ? itemStyleSelected
                : itemStyleNonSelected
            }
          >
            <img src={distribuidora} alt="Distribuidora" style={imgStyle} />
          </div>
          <p style={strongFontSize}>Distribuidora</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          onClick={() => {
            setCategory("farmacia");
            seleccionar("farmacia");
          }}
        >
          <div
            style={
              category === "farmacia" ? itemStyleSelected : itemStyleNonSelected
            }
          >
            <img src={farmacia} alt="Farmacia" style={imgStyle} />
          </div>
          <p style={strongFontSize}>Farmacia</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          onClick={() => {
            setCategory("indumentaria");
            seleccionar("indumentaria");
          }}
        >
          <div
            style={
              category === "indumentaria"
                ? itemStyleSelected
                : itemStyleNonSelected
            }
          >
            <img src={indumentaria} alt="Indumentaria" style={imgStyle} />
          </div>
          <p style={strongFontSize}>Indumentaria</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          onClick={() => {
            setCategory("hogar");
            seleccionar("hogar");
          }}
        >
          <div
            style={
              category === "hogar" ? itemStyleSelected : itemStyleNonSelected
            }
          >
            <img src={tv} alt="Art. Hogar" style={imgStyle} />
          </div>
          <p style={strongFontSize}>Art. Hogar</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          onClick={() => {
            setCategory("veterinaria");
            seleccionar("veterinaria");
          }}
        >
          <div
            style={
              category === "veterinaria"
                ? itemStyleSelected
                : itemStyleNonSelected
            }
          >
            <img src={veterinaria} alt="Veterinaria" style={imgStyle} />
          </div>
          <p style={strongFontSize}>Veterinaria</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          onClick={() => {
            setCategory("ferreteria");
            seleccionar("ferreteria");
          }}
        >
          <div
            style={
              category === "ferreteria"
                ? itemStyleSelected
                : itemStyleNonSelected
            }
          >
            <img src={ferreteria} alt="Ferreteria" style={imgStyle} />
          </div>
          <p style={strongFontSize}>Ferretería</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          onClick={() => {
            setCategory("emprendimientos");
            seleccionar("emprendimientos");
          }}
        >
          <div
            style={
              category === "emprendimientos"
                ? itemStyleSelected
                : itemStyleNonSelected
            }
          >
            <img src={emprendimientos} alt="Emprendimientos" style={imgStyle} />
          </div>
          <p style={strongFontSize}>Emprendimientos</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          onClick={() => {
            setCategory("otros");
            seleccionar("otros");
          }}
        >
          <div
            style={
              category === "otros" ? itemStyleSelected : itemStyleNonSelected
            }
          >
            <img src={otros} alt="Otros" style={imgStyle} />
          </div>
          <p style={strongFontSize}>Otros</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          onClick={() => {
            setCategory("cadeteria");
            seleccionar("cadeteria");
          }}
        >
          <div
            style={
              category === "cadeteria"
                ? itemStyleSelected
                : itemStyleNonSelected
            }
          >
            <img src={cadeteria} alt="Cadeteria" style={imgStyle} />
          </div>
          <p style={strongFontSize}>Cadetería</p>
        </div>
      </Carousel>
    </Fragment>
  );
};

CarouselComponent.propTypes = {};

export default CarouselComponent;

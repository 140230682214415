import { GET_SERVICIOS, CLEAR_SERVICIOS } from "../actions/types";

const initialState = {
  loading: true,
  servicios: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SERVICIOS:
      return {
        ...state,
        loading: false,
        servicios: payload,
      };
    case CLEAR_SERVICIOS:
      return {
        ...state,
        loading: true,
        servicios: null,
      };
    default:
      return state;
  }
}
